import { createContext, useState, useMemo, useContext, useEffect } from "react";
import { getAuth, signOut } from "firebase/auth";
import { useCookies } from "react-cookie";
import i18n from "../config/configI18n";
import moment from "moment";
import * as Sentry from "@sentry/react";

import UserData from "../models/UserData";

interface Auth {
  user: UserData | null;
  setUser: (user: UserData | null) => void;
  saveRefreshToken: (refreshToken: string) => void;
  logout: () => void;
}
const AuthContext = createContext<Auth>({} as Auth);

interface Props {
  children: React.ReactNode;
  userData: UserData | null;
}
export const AuthProvider = ({ children, userData }: Props) => {
  const [cookies, setCookie, removeCookie] = useCookies();

  const [user, setUser] = useState<UserData | null>(userData);

  const removeAllCookies = () => {
    const cookieNames = Object.keys(cookies);
    cookieNames.forEach((cookieName) => {
      removeCookie(cookieName, { path: "/" });
    });
  };

  // Update user language
  useEffect(() => {
    try {
      const userLanguage = user?.person?.language;
      if (userLanguage) {
        i18n.changeLanguage(userLanguage); // Strings
        moment.locale(userLanguage); // Dates
        document.documentElement.lang = userLanguage; // HTML lang attribute
        document.title = i18n.t("words.advisorPanel"); // Set tab title
      }
    } catch (error) {
      Sentry.captureException(error);
    }
  }, [user]);

  const logout = async () => {
    const auth = getAuth();
    await signOut(auth);
    setUser(null);
    removeAllCookies();

    // Remove user from Sentry
    Sentry.setUser(null);
  };

  const saveRefreshToken = (refreshToken: string) => {
    setCookie("refreshToken", refreshToken, { path: "/" });
  };

  const value = useMemo(
    () => ({ user, setUser, logout, saveRefreshToken }),
    [user, saveRefreshToken, setUser, logout]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = (): Auth => {
  return useContext<Auth>(AuthContext);
};
