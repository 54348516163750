export enum FBPersonRole {
  NONE = "NONE",
  READER = "READER",
  WRITER = "WRITER",
  ADMIN = "ADMIN",
}

export enum AuthProviders {
  EMAIL = "EMAIL",
  GOOGLE = "GOOGLE",
  APPLE = "APPLE",
  MICROSOFT = "MICROSOFT",
}

export enum FormMode {
  CREATE = "CREATE",
  EDIT = "EDIT",
  DELETE = "DELETE",
}

export enum FBEAdvisorChatMessageType {
  TEXT = "TEXT",
  IMAGE = "IMAGE",
  VIDEO = "VIDEO",
  AUDIO = "AUDIO",
  DOCUMENT = "DOCUMENT",
  INTERACTIVE = "INTERACTIVE",
  ACCEPTANCE = "ACCEPTANCE",
  RESTARTCHAT = "RESTARTCHAT",
}

export enum FBEAdvisorChatMessageSource {
  SYSTEM = "SYSTEM",
  BRIOAGRO = "BRIOAGRO",
  ADVISOR = "ADVISOR",
  CLIENT = "CLIENT",
}

export enum PhytoRecipeStatus {
  DRAFT = "DRAFT",
  PRESCRIBED = "PRESCRIBED",
  ACCEPTED = "ACCEPTED",
  REFUSED = "REFUSED",
  APPLIED = "APPLIED",
}

export enum PhytoRecipeType {
  CROP = "CROP",
  POSTHARVEST = "POSTHARVEST",
  FACILITY = "FACILITY",
}

export enum PlantProblemType {
  PLAGUE = "PLAGUE",
  DISEASE = "DISEASE",
  WEED = "WEED",
  REGULATORS = "REGULATORS",
}

export enum FBENotificationType {
  INFO = "INFO",
  WARNING = "WARNING",
  ALERT = "ALERT",
}

export enum EntityType {
  PHYSICAL = "PHYSICAL",
  LEGAL = "LEGAL",
}

export enum EntitySex {
  MALE = "MALE",
  FEMALE = "FEMALE",
  INTERSEX = "OTHER",
}

export enum PhytosanitaryTrainingLevel {
  BASIC = "BASIC",
  SKILLED = "SKILLED",
  FUMIGATOR = "FUMIGATOR",
  PILOT = "PILOT",
}

export enum FBESiexOperationType {
  INSERT = "INSERT",
  UPDATE = "UPDATE",
  DELETE = "DELETE",
}

export enum FBESiexOperationResult {
  PENDING = "PENDING",
  OK = "OK",
  ERROR = "ERROR",
}

export enum FBESiexRequestStatus {
  ADVISOR_PENDING = "ADVISOR_PENDING",
  PENDING = "PENDING",
  SENT_PENDING = "SENT_PENDING",
  OK = "OK",
  PARTIALLY_OK = "PARTIALLY_OK",
  ERROR = "ERROR",
}

export enum FBEntitiesTypes {
  ANALYSYS = "FBAnalysis",
  CROP_IRRIGATION = "FBCropIrrigation",
  FERTILIZATION = "FBFertilization",
  CROP_PHYTO_TREATMENT = "FBCropPhytosanitaryTreatment",
  POSTHARVEST_PHYTO_TREATMENT = "FBPostHarvestPhytosanitaryTreatment",
  FACILITY_PHYTO_TREATMENT = "FBFacilityPhytosanitaryTreatment",
  TREATED_SEED = "FBTreatedSeed",
}
