import { useState, useEffect } from "react";
import i18n from "../../config/configI18n";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Autocomplete,
  FormGroup,
  FormControl,
} from "@mui/material";

import CueAccountWithRole from "../../models/account/CueAccountWithRole";

interface DialogChooseCueAccountProps {
  open: boolean;
  options: CueAccountWithRole[];
  onClose: () => void;
  onConfirm: (cueAccountWithRole: CueAccountWithRole) => void;
  selected: CueAccountWithRole | null;
}
const DialogChooseCueAccount = (props: DialogChooseCueAccountProps) => {
  const { open, onClose, onConfirm, options, selected } = props;

  const [newSelected, setNewSelected] = useState<CueAccountWithRole | null>(
    selected
  );

  useEffect(() => {
    setNewSelected(selected);
  }, [selected]);

  const handleChangeSelected = (
    event: React.SyntheticEvent,
    newValue: CueAccountWithRole | null
  ) => {
    if (newValue) setNewSelected(newValue);
  };

  const handleConfirm = () => {
    if (newSelected) {
      onConfirm(newSelected);
      onClose();
    }
  };

  const handleClose = () => {
    setNewSelected(selected);
    onClose();
  };

  return (
    <Dialog open={open} className="dialog" onClose={handleClose}>
      <DialogTitle className="title">
        {i18n.t("words.chooseAnAccount")}
      </DialogTitle>
      <DialogContent className="content">
        <FormGroup className="form-group">
          <FormControl variant="outlined" className="form-control">
            <Autocomplete
              id="advisor-navbar"
              className="account-select"
              options={options}
              getOptionLabel={(option) => option.cueAccount?.name || ""}
              isOptionEqualToValue={(option, value) =>
                option.cueAccount?.id === value.cueAccount?.id
              }
              noOptionsText={i18n.t("formErrors.notFoundResults")}
              value={newSelected}
              onChange={handleChangeSelected}
              renderOption={(props, option) => (
                <li {...props} key={option.cueAccount?.id}>
                  {option.cueAccount?.name}
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  label={i18n.t("words.account")}
                  placeholder={i18n.t("searchAccount")}
                />
              )}
            />
          </FormControl>
        </FormGroup>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          {i18n.t("words.cancel")}
        </Button>
        <Button onClick={handleConfirm} color="primary">
          {i18n.t("words.confirm")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogChooseCueAccount;
