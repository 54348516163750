import { DoneAll, Check, Schedule } from "@mui/icons-material";

import AdvisorChatMessage from "../../models/advisor/AdvisorChatMessage";
import { FBEAdvisorChatMessageSource } from "../../constants/enums";

interface Props {
  message?: AdvisorChatMessage;
  margin?: "left" | "right";
}
const MessageStatusIcon = (props: Props) => {
  const { message, margin = "right" } = props;

  if (
    !message ||
    (message.source &&
      [
        FBEAdvisorChatMessageSource.CLIENT,
        FBEAdvisorChatMessageSource.SYSTEM,
      ].includes(message.source))
  )
    return null;

  if (message?.waReadOnTimestamp)
    return (
      <DoneAll fontSize="small" className={`msg-status-icon-read-${margin}`} />
    );
  if (message?.waDeliveredOnTimestamp)
    return <DoneAll fontSize="small" className={`msg-status-icon-${margin}`} />;
  if (message?.waSentOnTimestamp)
    return <Check fontSize="small" className={`msg-status-icon-${margin}`} />;
  return (
    <Schedule
      fontSize="small"
      className={`msg-status-icon-not-sent-${margin}`}
    />
  );
};

export default MessageStatusIcon;
