import { useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import i18n from "../config/configI18n";
import * as Yup from "yup";

import {
  FormGroup,
  FormControl,
  Button,
  FormControlLabel,
  Checkbox,
  Link,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

import AlertSnackbar from "../components/elements/AlertSnackbar";
import TestBanner from "../components/banners/TestBanner";
import FormikTextField from "../components/formik/FormikTextField";

import { useAuth } from "../hooks/useAuth";
import { useSession } from "../hooks/useSession";
import useCrud from "../hooks/useCrud";

import CueAccount from "../models/account/CueAccount";

import { PUBLIC_ROUTES } from "../routes/routeNames";
import { SnackbarInterface } from "../constants/interfaces";

const OnBoardingScreen = () => {
  const cueAccountValidatorSchema = Yup.object().shape({
    name: Yup.string().required(i18n.t("formErrors.requiredField")),
  });

  const { user, logout } = useAuth();
  const { advisorCueAccounts } = useSession();
  const navigate = useNavigate();

  const [snackbarMsg, setSnackbarMsg] = useState<SnackbarInterface | null>(
    null
  );
  const [policiesAccepted, setPoliciesAccepted] = useState<boolean>(false);

  const handleSubmit = (values: CueAccount) => {
    if (policiesAccepted) advisorAccountCreateMutation.mutate(values);
    else
      setSnackbarMsg({
        severity: "warning",
        message: i18n.t("formErrors.policiesError"),
      } as SnackbarInterface);
  };

  const formik = useFormik({
    initialValues: new CueAccount(),
    validationSchema: cueAccountValidatorSchema,
    onSubmit: handleSubmit,
  });

  const advisorAccountCreateMutation = useCrud<CueAccount>({
    key: "postAdvisorAccount",
    values: formik.values,
    onSuccess: () => {
      navigate(0);
    },
    onError: setSnackbarMsg,
  });

  const handleChangePoliciesCheckbox = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPoliciesAccepted(event.target.checked);
  };

  const handleLogout = () => {
    logout();
  };

  if (user?.person && advisorCueAccounts.length > 0) {
    return <Navigate to={PUBLIC_ROUTES.HOME} replace />;
  }

  return (
    <div className="onBoarding">
      <AlertSnackbar
        open={!!snackbarMsg}
        snackbarMsg={snackbarMsg}
        onClose={() => setSnackbarMsg(null)}
      />
      <TestBanner />
      <div className="container">
        <div className="header">
          <div className="logo" />
        </div>
        <div className="content">
          <span>{i18n.t("register.span1")}</span>
          <span>{i18n.t("register.span2")}</span>
          <div className="form-section">
            <h3>{i18n.t("register.title")}</h3>
            <FormGroup className="form-group">
              <FormControl variant="outlined" className="form-control">
                <FormikTextField
                  formik={formik}
                  name="name"
                  label={i18n.t("register.name")}
                  required
                />
              </FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={policiesAccepted}
                    onChange={handleChangePoliciesCheckbox}
                  />
                }
                label={
                  <Link
                    sx={{
                      color: (theme) =>
                        `${theme.palette.primary.light} !important`,
                    }}
                    href={`${process.env.REACT_APP_ADVISOR_WEB_URL}${PUBLIC_ROUTES.POLICIES}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    underline="none"
                  >
                    {i18n.t("register.policiesLabel")}
                  </Link>
                }
              />
            </FormGroup>
            <LoadingButton
              variant="contained"
              fullWidth
              sx={{ mt: 4 }}
              size="large"
              loading={advisorAccountCreateMutation.isLoading}
              onClick={() => formik.submitForm()}
            >
              {i18n.t("register.registerBtn")}
            </LoadingButton>
          </div>
          <Button
            sx={{ mt: 4 }}
            color="inherit"
            variant="outlined"
            onClick={handleLogout}
          >
            {i18n.t("register.switchAccountBtn")}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default OnBoardingScreen;
