import VadeProductComposition from "../../vademecum/VadeProductComposition";
import VadeProductOtherName from "../../vademecum/VadeProductOtherName";
import VadeProductUse from "../../vademecum/VadeProductUse";

class FBRegisteredPhytosanitaryProduct {
  id?: number;
  productId?: number;
  registerId?: string;
  name?: string;
  formulation?: string;
  expirationDate?: string;
  cancellationDate?: string;
  saleDeadlineDate?: string;
  registrationDate?: string;
  conditions?: string;
  registerStatus?: string;
  owner?: string;
  manufacturer?: string;
  factory?: string;
  composition?: VadeProductComposition[];
  otherNames?: VadeProductOtherName[];
  uses?: VadeProductUse[];
  enabledDate?: string;
  disabledDate?: string;

  constructor(obj?: any) {
    this.id = obj?.id;
    this.productId = obj?.productId;
    this.registerId = obj?.registerId;
    this.name = obj?.name;
    this.formulation = obj?.formulation;
    this.expirationDate = obj?.expirationDate;
    this.cancellationDate = obj?.cancellationDate;
    this.saleDeadlineDate = obj?.saleDeadlineDate;
    this.registrationDate = obj?.registrationDate;
    this.conditions = obj?.conditions;
    this.registerStatus = obj?.registerStatus;
    this.owner = obj?.owner;
    this.manufacturer = obj?.manufacturer;
    this.factory = obj?.factory;
    this.composition = obj?.composition?.map(
      (item: any) => new VadeProductComposition(item)
    );
    this.otherNames = obj?.otherNames?.map(
      (item: any) => new VadeProductOtherName(item)
    );
    this.uses = obj?.uses?.map((item: any) => new VadeProductUse(item));
    this.enabledDate = obj?.enabledDate;
    this.disabledDate = obj?.disabledDate;
  }

  mapBaseToApiModel(): any {
    const obj: any = { ...this };
    return obj;
  }
}

export default FBRegisteredPhytosanitaryProduct;
