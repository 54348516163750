import FBVadeSubstance from "../_api/vademecum/FBVadeSubstance";

class VadeSubstance extends FBVadeSubstance {
  constructor(obj?: any) {
    super(obj);
  }

  mapToApiModel(): any {
    const obj: any = super.mapBaseToApiModel();
    return obj;
  }
}

export default VadeSubstance;
