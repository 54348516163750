import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { FormikProps, FormikTouched, useFormik } from "formik";
import i18n from "../config/configI18n";
import * as Yup from "yup";

import { FormControl, FormGroup } from "@mui/material";
import { Badge } from "@mui/icons-material";

import ScreenContentLayout from "../components/ScreenContentLayout";
import LoadingWithDelay from "../components/elements/LoadingWithDelay";
import TestBanner from "../components/banners/TestBanner";
import AlertSnackbar from "../components/elements/AlertSnackbar";
import FormAlertDialog from "../components/dialogs/FormAlertDialog";
import { BrioCard, TableBrioCard } from "../components/BrioCard";
import RightDrawer from "../components/RightDrawer";
import FormikTextField from "../components/formik/FormikTextField";
import FormikSelect from "../components/formik/FormikSelect";
import ErrorScreen from "./ErrorScreen";

import { useAuth } from "../hooks/useAuth";
import { useSession } from "../hooks/useSession";
import useFetch from "../hooks/useFetch";
import useCrud from "../hooks/useCrud";

import AgroBusinessAccountPerson from "../models/account/AgroBusinessAccountPerson";

import {
  Column,
  PersonPermissionRoleInterface,
  SnackbarInterface,
} from "../constants/interfaces";
import { FBPersonRole, FormMode } from "../constants/enums";
import { EMPTY_TABLE_ICON_SIZE } from "../constants/constants";
import { PERSON_PERMISSION_ROLES_LIST } from "../providers/YupProvider";

const StaffScreen = () => {
  const StaffPermissionValidatorSchema = Yup.object().shape({
    person: Yup.object().shape({
      email: Yup.string().email().required(),
    }),
    personRole: Yup.object({
      role: Yup.string().required(),
    }).required(),
  });

  const staffPermissionsColumns: Column[] = [
    { id: "person.email", label: i18n.t("staff.column.email"), numeric: false },
    {
      id: "personRole.name",
      label: i18n.t("staff.column.personRole"),
      numeric: false,
    },
  ];

  const location = useLocation();
  const navigate = useNavigate();
  const { user } = useAuth();
  const { selectedCueAccount } = useSession();

  const [isOpenDrawer, setIsOpenDrawer] = useState<boolean>(false);
  const [snackbarMsg, setSnackbarMsg] = useState<SnackbarInterface | null>(
    null
  );
  const [isOpenBackAlertDialog, setIsOpenBackAlertDialog] =
    useState<boolean>(false);
  const [isOpenConfirmDeleteAlertDialog, setIsOpenConfirmDeleteAlertDialog] =
    useState<boolean>(false);
  const [formMode, setFormMode] = useState<FormMode | undefined>(undefined);
  const [selectedRowIds, setSelectedRowIds] = useState<number[]>([]);
  const [ownerAccount, setOwnerAccount] = useState<
    AgroBusinessAccountPerson | undefined
  >(undefined);

  const { data: cueAccountPermissions, isFetching } = useFetch<
    AgroBusinessAccountPerson[]
  >({
    queryKey: ["cueAccountPermissions", selectedCueAccount?.cueAccount?.id],
    enabled: !!selectedCueAccount,
  });

  useEffect(() => {
    setOwnerAccount(
      cueAccountPermissions?.find(
        (abap) => abap?.person?.email === user?.person?.email
      )
    );
  }, [cueAccountPermissions, user]);

  const handleSubmit = (values: AgroBusinessAccountPerson) => {
    switch (formMode) {
      case FormMode.CREATE:
      case FormMode.EDIT:
        cueAccountPermissionEditMutation.mutate(values);
        break;
      default:
        break;
    }
  };

  const formik = useFormik({
    initialValues: new AgroBusinessAccountPerson(),
    validationSchema: StaffPermissionValidatorSchema,
    onSubmit: handleSubmit,
  });

  const drawerTitle =
    formik.status === FormMode.CREATE
      ? i18n.t("staff.drawerTitleCreate")
      : i18n.t("staff.drawerTitleEdit");
  const drawerBtnText =
    formik.status === FormMode.CREATE
      ? i18n.t("words.create")
      : i18n.t("words.update");

  // Open drawer of files form if url contains respective path
  useEffect(() => {
    setIsOpenDrawer(location.pathname.includes("/edit"));
  }, [location.pathname]);

  // Handle browser back button
  useEffect(() => {
    const handleBackButton = (event: any) => {
      event.preventDefault();
      if (isOpenDrawer && formik.dirty && formik.status === FormMode.CREATE) {
        openBackAlertDialog();
        navigate(`${location.pathname}`);
      } else setSelectedRowIds([]);
    };

    window.addEventListener("popstate", handleBackButton);

    return () => {
      window.removeEventListener("popstate", handleBackButton);
    };
  }, [isOpenDrawer, formik.values]);

  const clearForm = () => {
    setFormMode(FormMode.CREATE);
    formik.resetForm();
    formik.setErrors({});
    formik.setStatus(FormMode.CREATE);
  };
  const openDrawer = (formMode: FormMode) => {
    if (formMode === FormMode.CREATE) clearForm();
    setFormMode(formMode);
    navigate(`${location.pathname}/edit`);
  };
  const closeDrawer = () => {
    setSelectedRowIds([]);
    setIsOpenDrawer(false);
    navigate(-1);
  };
  const openBackAlertDialog = () => setIsOpenBackAlertDialog(true);
  const cancelBackAlertDialog = () => setIsOpenBackAlertDialog(false);
  const openConfirmDeleteAlertDialog = () =>
    setIsOpenConfirmDeleteAlertDialog(true);
  const cancelConfirmDeleteAlertDialog = () =>
    setIsOpenConfirmDeleteAlertDialog(false);

  const closeDialogAndUnselectedRows = () => {
    closeDrawer();
    cancelConfirmDeleteAlertDialog();
  };

  const manageCrudError = (snackBarError: SnackbarInterface) => {
    if (snackBarError?.hasDocError) closeDialogAndUnselectedRows();
    setSnackbarMsg(snackBarError);
  };

  const cueAccountPermissionEditMutation = useCrud<AgroBusinessAccountPerson>({
    key: "putCueAccountPermission",
    values: formik.values,
    onSuccess: () => {
      setSnackbarMsg({
        severity: "success",
        message: i18n.t("apiResponses.updateSuccess"),
      });
      closeDialogAndUnselectedRows();
    },
    onError: manageCrudError,
  });

  const cueAccountPermissionDeleteMutation = useCrud({
    key: "deleteCueAccountPermissions",
    values: cueAccountPermissions?.filter((abap: AgroBusinessAccountPerson) =>
      selectedRowIds.includes(abap?.idx || 0)
    ),
    onSuccess: () => {
      setSnackbarMsg({
        severity: "success",
        message: i18n.t("apiResponses.deleteSuccess"),
      });
      setSelectedRowIds([]);
      cancelConfirmDeleteAlertDialog();
    },
    onError: (error: SnackbarInterface) => {
      setSnackbarMsg(error);
      cancelConfirmDeleteAlertDialog();
    },
  });

  const handleClickCloseDrawer = () => {
    formik.dirty && formik.status === FormMode.CREATE
      ? openBackAlertDialog()
      : closeDrawer();
  };

  const handleClickAdd = () => {
    formik.setStatus(FormMode.CREATE);
    setFormMode(FormMode.CREATE);
    openDrawer(FormMode.CREATE);
  };

  const handleClickSave = async () => {
    const errors = await formik.validateForm();
    if (Object.keys(errors).length > 0) {
      setSnackbarMsg({
        severity: "warning",
        message: i18n.t("formErrors.requiredFields"),
      });
      // Mark all fields as touched to show errors
      formik.setTouched(errors as FormikTouched<any>);
    } else formik.submitForm();
  };

  const handleClickEdit = (id: number) => {
    setSelectedRowIds([id]);
    formik.setStatus(FormMode.EDIT);
    setFormMode(FormMode.EDIT);
    openDrawer(FormMode.EDIT);
  };

  const handleClickDelete = (ids: number[]) => {
    if (ids.length > 0) {
      formik.setStatus(FormMode.DELETE);
      setFormMode(FormMode.DELETE);
      openConfirmDeleteAlertDialog();
    }
  };

  const handleOnFormError = (snackBarError: SnackbarInterface) => {
    setSnackbarMsg(snackBarError);
  };

  const handleConfirmBackAlertDialog = () => {
    cancelBackAlertDialog();
    closeDrawer();
  };

  const handleConfirmDeleteAlertDialog = () => {
    cueAccountPermissionDeleteMutation.mutate(selectedRowIds);
  };

  if (selectedCueAccount && selectedCueAccount?.role !== FBPersonRole.ADMIN)
    return <ErrorScreen errorStatus={401} />;

  return (
    <ScreenContentLayout>
      <LoadingWithDelay isVisible={isFetching} />
      <TestBanner />
      <AlertSnackbar
        open={!!snackbarMsg}
        snackbarMsg={snackbarMsg}
        onClose={() => setSnackbarMsg(null)}
      />
      <FormAlertDialog
        id="backAlertDialog"
        title={i18n.t("staff.backAlertTitle")}
        contentText={i18n.t("staff.backAlertDescription")}
        open={isOpenBackAlertDialog}
        formAction={formMode}
        onCancel={cancelBackAlertDialog}
        onConfirm={handleConfirmBackAlertDialog}
      />
      <FormAlertDialog
        id="confirmDeleteAlertDialog"
        title={i18n.t("staff.confirmDeleteAlertDialogTitle")}
        contentText={i18n.t("staff.confirmDeleteAlertDialogDescription")}
        open={isOpenConfirmDeleteAlertDialog}
        formAction={formMode}
        isLoading={cueAccountPermissionDeleteMutation.isLoading}
        onCancel={cancelConfirmDeleteAlertDialog}
        onConfirm={handleConfirmDeleteAlertDialog}
      />
      <TableBrioCard
        idx="idx"
        title={i18n.t("staff.tableTitle")}
        colums={staffPermissionsColumns}
        rows={cueAccountPermissions || []}
        selectedRows={selectedRowIds}
        emptyTableIcon={<Badge sx={{ fontSize: EMPTY_TABLE_ICON_SIZE }} />}
        emptyTableTitle={i18n.t("staff.emptyTableTitle")}
        emptyTableSubtitle={i18n.t("staff.emptyTableSubtitle")}
        emptyTableBtnText={i18n.t("staff.emptyTableBtnText")}
        isLoading={isFetching}
        addBtnVariant="contained"
        disableRows={ownerAccount?.idx ? [ownerAccount.idx] : []}
        onChangeSelectedRows={setSelectedRowIds}
        onClickAdd={handleClickAdd}
        onClickEdit={handleClickEdit}
        onClickDelete={handleClickDelete}
      />
      <RightDrawer
        title={drawerTitle}
        titleBtn={drawerBtnText}
        isOpen={isOpenDrawer}
        isLoading={cueAccountPermissionEditMutation.isLoading}
        onClose={handleClickCloseDrawer}
        onConfirm={handleClickSave}
      >
        <StaffForm
          formik={formik}
          selectedEditRow={
            formMode === FormMode.EDIT && selectedRowIds.length === 1
              ? cueAccountPermissions?.find(
                  (abap) => abap.idx === selectedRowIds[0]
                )
              : undefined
          }
          onError={handleOnFormError}
        />
      </RightDrawer>
    </ScreenContentLayout>
  );
};

export default StaffScreen;

interface StaffFormProps {
  formik: FormikProps<AgroBusinessAccountPerson>;
  selectedEditRow?: AgroBusinessAccountPerson;
  onError?: (snackBarError: SnackbarInterface) => void;
}

const StaffForm = (props: StaffFormProps) => {
  const { formik, selectedEditRow } = props;

  useEffect(() => {
    if (selectedEditRow) formik.setValues(selectedEditRow);
  }, [selectedEditRow]);

  return (
    <>
      <StaffDataForm {...props} />
    </>
  );
};

const StaffDataForm = (props: StaffFormProps) => {
  const { formik } = props;

  const getPermissionHelperText = (
    permission?: PersonPermissionRoleInterface
  ) => {
    switch (permission?.role) {
      case FBPersonRole.ADMIN:
        return i18n.t("staff.adminPermissionHelperText");
      case FBPersonRole.WRITER:
        return i18n.t("staff.writerPermissionHelperText");
      case FBPersonRole.READER:
        return i18n.t("staff.readerPermissionHelperText");
      case FBPersonRole.NONE:
        return i18n.t("staff.nonePermissionHelperText");
      default:
        return i18n.t("staff.defaultPermissionHelperText");
    }
  };

  return (
    <BrioCard
      title={i18n.t("staff.dataFormTitle")}
      disableAccordion
      defaultExpanded
    >
      <FormGroup className="form-group">
        <FormControl variant="outlined" className="form-control">
          <FormikTextField
            formik={formik}
            id="email"
            name="person.email"
            label="Email"
            type="email"
            required
          />
        </FormControl>
        <FormControl variant="outlined" className="form-control">
          <FormikSelect
            formik={formik}
            name="personRole"
            label={i18n.t("staff.personRoleLabel")}
            helperText={getPermissionHelperText(formik.values.personRole)}
            required
            options={PERSON_PERMISSION_ROLES_LIST}
            optionLabelFieldName="name"
          />
        </FormControl>
      </FormGroup>
    </BrioCard>
  );
};
