import FBAgroBusinessAccountPerson from "../_api/account/FBAgroBusinessAccountPerson";
import AgroBusinessAccount from "./AgroBusinessAccount";

import { PersonPermissionRoleInterface } from "../../constants/interfaces";
import { PERSON_PERMISSION_ROLES_LIST } from "../../providers/YupProvider";

class AgroBusinessAccountPerson extends FBAgroBusinessAccountPerson {
  idx?: number;
  personRole?: PersonPermissionRoleInterface;
  selectedAgroBusinessAccount?: AgroBusinessAccount;

  constructor(obj?: any) {
    super(obj);
    this.idx = obj?.person?.id;
    this.personRole = PERSON_PERMISSION_ROLES_LIST.find(
      (personRole) => personRole.role === obj?.role
    );
  }

  mapToApiModel(): any {
    const obj: any = super.mapBaseToApiModel();
    obj.role = this.personRole?.role;

    // Delete not api model properties
    //TODO: Check if necessary
    delete obj.idx;
    delete obj.personRole;
    delete obj.selectedAgroBusinessAccount;
    return obj;
  }
}

export default AgroBusinessAccountPerson;
