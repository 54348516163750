import moment from "moment";
import i18n from "../../config/configI18n";

import { PhytoRecipeStatus } from "../../constants/enums";
import FBPhytoRecipeStatusHistory from "../_api/phytoRecipe/FBPhytoRecipeStatusHistory";

interface RecipeStatus {
  label: string;
  color: string;
}

const getRecipeStatus = (status: PhytoRecipeStatus): RecipeStatus => {
  switch (status) {
    case PhytoRecipeStatus.DRAFT:
      return { label: i18n.t("recipes.draftStatusLabel"), color: "black" };
    case PhytoRecipeStatus.PRESCRIBED:
      return {
        label: i18n.t("recipes.prescribedRecipeStatusLabel"),
        color: "black",
      };
    case PhytoRecipeStatus.ACCEPTED:
      return {
        label: i18n.t("recipes.acceptedRecipeStatusLabel"),
        color: "#3b6a00",
      };
    case PhytoRecipeStatus.REFUSED:
      return {
        label: i18n.t("recipes.rejectedRecipeStatusLabel"),
        color: "#c62828",
      };
    case PhytoRecipeStatus.APPLIED:
      return {
        label: i18n.t("recipes.appliedRecipeStatusCreate"),
        color: "#0288d1",
      };
    default:
      return { label: i18n.t("recipes.draftStatusLabel"), color: "black" };
  }
};

class PhytoRecipeStatusHistory extends FBPhytoRecipeStatusHistory {
  startDateFormatted?: string;
  recipeStatus?: RecipeStatus;

  constructor(obj?: any) {
    super(obj);
    if (obj?.startTimestamp)
      this.startDateFormatted = moment(obj.startTimestamp * 1000).format(
        "DD MMMM YYYY HH:mm"
      );
    if (obj?.status) this.recipeStatus = getRecipeStatus(obj.status);
  }

  mapToApiModel(): any {
    const obj: any = super.mapBaseToApiModel();

    delete obj.startDateFormatted;
    delete obj.recipeStatus;
    return obj;
  }
}

export default PhytoRecipeStatusHistory;
