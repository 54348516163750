import { PlantProblemType } from "../../../constants/enums";

class FBPlantProblem {
  id?: number;
  type?: PlantProblemType;
  name?: string;
  code?: string;
  description?: string;
  scientificName?: string;
  eppoCode?: string;
  hierarchicalCode?: string;
  enabledDate?: string;
  disabledDate?: string;

  constructor(obj?: any) {
    this.id = obj?.id;
    this.type = obj?.type;
    this.name = obj?.name;
    this.code = obj?.code;
    this.description = obj?.description;
    this.scientificName = obj?.scientificName;
    this.eppoCode = obj?.eppoCode;
    this.hierarchicalCode = obj?.hierarchicalCode;
    this.enabledDate = obj?.enabledDate;
    this.disabledDate = obj?.disabledDate;
  }

  mapBaseToApiModel(): any {
    const obj: any = { ...this };
    return obj;
  }
}

export default FBPlantProblem;
