import Person from "../../Person";
import Entity from "../../agrobusiness/Entity";

class FBAgroBusinessPhytosanitaryInvolvedEntity {
  id?: number;
  entity?: Entity; //*
  phytosanitaryAdvisor?: boolean;
  phytosanitaryApplicator?: boolean;
  phytosanitaryRegisterId?: string;
  trainingLevel?: string;
  fertilizationAdvisor?: boolean;
  fertilizationApplicator?: boolean;
  fertilizationRegisterId?: string;
  advisorUser?: Person;
  enabledDate?: string;
  disabledDate?: string;

  constructor(obj?: any) {
    this.id = obj?.id;
    this.entity = new Entity(obj?.entity);
    this.phytosanitaryAdvisor = obj?.phytosanitaryAdvisor;
    this.phytosanitaryApplicator = obj?.phytosanitaryApplicator;
    this.phytosanitaryRegisterId = obj?.phytosanitaryRegisterId;
    this.trainingLevel = obj?.trainingLevel;
    this.fertilizationAdvisor = obj?.fertilizationAdvisor;
    this.fertilizationApplicator = obj?.fertilizationApplicator;
    this.fertilizationRegisterId = obj?.fertilizationRegisterId;
    if (obj?.advisorUser?.id) this.advisorUser = new Person(obj?.advisorUser);
    this.enabledDate = obj?.enabledDate;
    this.disabledDate = obj?.disabledDate;
  }

  mapBaseToApiModel(): any {
    const obj: any = { ...this };
    obj.entity = this.entity?.mapToApiModel();
    if (!this.phytosanitaryAdvisor) obj.advisorUser = null;
    return obj;
  }
}

export default FBAgroBusinessPhytosanitaryInvolvedEntity;
