import moment from "moment";
import i18n from "../../config/configI18n";

import FBPhytoRecipe from "../_api/phytoRecipe/FBPhytoRecipe";
import { PhytoRecipeStatus, PlantProblemType } from "../../constants/enums";
import { PhytoRecipeTypeInterface } from "../../constants/interfaces";
import PlantProblem from "../catalogue/PlantProblem";
import { PHYTO_RECIPE_TYPES } from "../../providers/YupProvider";
import PlantWeed from "../catalogue/PlantWeed";
import PlantPlague from "../catalogue/PlantPlague";
import PlantDisease from "../catalogue/PlantDisease";
import PlantRegulatorsAndOthers from "../catalogue/PlantRegulatorsAndOthers";
import Crop from "../crop/Crop";

interface RecipeStatus {
  label: string;
  color: string;
}

export const MEASUREMENT_UNIT_CODES = [
  "4",
  "5",
  "6",
  "11",
  "12",
  "14",
  "15",
  "16",
  "17",
  "18",
  "19",
];

const getRecipeStatus = (status: PhytoRecipeStatus): RecipeStatus => {
  switch (status) {
    case PhytoRecipeStatus.DRAFT:
      return { label: i18n.t("recipes.draftStatusLabel"), color: "black" };
    case PhytoRecipeStatus.PRESCRIBED:
      return {
        label: i18n.t("recipes.prescribedRecipeStatusLabel"),
        color: "black",
      };
    case PhytoRecipeStatus.ACCEPTED:
      return {
        label: i18n.t("recipes.acceptedRecipeStatusLabel"),
        color: "#3b6a00",
      };
    case PhytoRecipeStatus.REFUSED:
      return {
        label: i18n.t("recipes.rejectedRecipeStatusLabel"),
        color: "#c62828",
      };
    case PhytoRecipeStatus.APPLIED:
      return {
        label: i18n.t("recipes.appliedRecipeStatusCreate"),
        color: "#0288d1",
      };
    default:
      return { label: i18n.t("recipes.draftStatusLabel"), color: "black" };
  }
};

const getRecipePeriodPercentage = (
  start: moment.Moment,
  end: moment.Moment
) => {
  const nowMoment = moment();
  const recipePeriod = end.diff(start, "days");
  const elapsedPeriod = nowMoment.diff(start, "days");
  if (nowMoment.isBetween(start, end, "days", "[]"))
    return recipePeriod === 0
      ? 0
      : Math.round((elapsedPeriod / recipePeriod) * 100);
  if (nowMoment.isBefore(start, "days")) return -1;
  if (nowMoment.isAfter(end, "days")) return 101;
  return 0;
};

const getRecipePeriodStatus = (percentage: number) => {
  if (percentage < 0)
    return {
      label: i18n.t("recipes.recipeForm.recipePeriodNotstartedLabel"),
      color: "#c62828",
    };
  else if (percentage <= 100)
    return {
      label: i18n.t("recipes.recipeForm.recipePeriodOpenLabel"),
      color: "#5cb106",
    };
  else
    return {
      label: i18n.t("recipes.recipeForm.recipePeriodClosedLabel"),
      color: "#c62828",
    };
};

class PhytoRecipe extends FBPhytoRecipe {
  recipeType?: PhytoRecipeTypeInterface;
  dateFormatted?: string;
  dateFormattedLong?: string;
  startRecipeDateFormattedLong?: string;
  endRecipeDateFormattedLong?: string;
  recipeStatus?: RecipeStatus;
  recipePeriodPercentage?: number;
  recipePeriodStatus?: RecipeStatus;
  plantProblems?: PlantProblem[];

  constructor(obj?: any) {
    super(obj);
    this.recipeType =
      PHYTO_RECIPE_TYPES.find((type) => type.code === obj?.type) ||
      PHYTO_RECIPE_TYPES[0];
    if (this.date) {
      this.dateFormatted = moment(this.date, "YYYY-MM-DD").format("DD MMMM");
      this.dateFormattedLong = moment(this.date, "YYYY-MM-DD").format(
        "DD MMMM YYYY"
      );
    }
    const startMoment = moment(this.startRecipeDate, "YYYY-MM-DD");
    const endMoment = moment(this.endRecipeDate, "YYYY-MM-DD");
    if (this.startRecipeDate)
      this.startRecipeDateFormattedLong = startMoment.format("DD MMMM YYYY");
    if (this.endRecipeDate)
      this.endRecipeDateFormattedLong = endMoment.format("DD MMMM YYYY");
    if (this.status) this.recipeStatus = getRecipeStatus(this.status);
    if (this.startRecipeDate && this.endRecipeDate) {
      this.recipePeriodPercentage = getRecipePeriodPercentage(
        startMoment,
        endMoment
      );
      this.recipePeriodStatus = getRecipePeriodStatus(
        this.recipePeriodPercentage
      );
    }
    this.plantProblems = this.getPlantProblems(
      this.weeds,
      this.plagues,
      this.diseases,
      this.regulators
    );
  }

  mapToApiModel(): any {
    const obj: any = super.mapBaseToApiModel();
    obj.type = this.recipeType?.code;
    [obj.weeds, obj.plagues, obj.diseases, obj.regulators] =
      this.setPlantProblems(this.plantProblems || []);

    delete obj.recipeType;
    delete obj.dateFormatted;
    delete obj.dateFormattedLong;
    delete obj.startRecipeDateFormattedLong;
    delete obj.endRecipeDateFormattedLong;
    delete obj.recipeStatus;
    delete obj.recipePeriodPercentage;
    delete obj.recipePeriodStatus;
    delete obj.plantProblems;
    return obj;
  }

  getPlantProblems = (
    weeds?: PlantWeed[],
    plagues?: PlantPlague[],
    diseases?: PlantDisease[],
    regulators?: PlantRegulatorsAndOthers[]
  ): PlantProblem[] => {
    const plantProblems: PlantProblem[] = [];
    if (weeds && weeds.length > 0) {
      weeds.forEach((weed) => {
        const plantProblem = new PlantProblem({
          ...weed,
          type: PlantProblemType.WEED,
        });
        if (plantProblem) plantProblems.push(plantProblem);
      });
    }
    if (plagues && plagues.length > 0) {
      plagues.forEach((plague) => {
        const plantProblem = new PlantProblem({
          ...plague,
          type: PlantProblemType.PLAGUE,
        });
        if (plantProblem) plantProblems.push(plantProblem);
      });
    }
    if (diseases && diseases.length > 0) {
      diseases.forEach((disease) => {
        const plantProblem = new PlantProblem({
          ...disease,
          type: PlantProblemType.DISEASE,
        });
        if (plantProblem) plantProblems.push(plantProblem);
      });
    }
    if (regulators && regulators.length > 0) {
      regulators.forEach((regulator) => {
        const plantProblem = new PlantProblem({
          ...regulator,
          type: PlantProblemType.REGULATORS,
        });
        if (plantProblem) plantProblems.push(plantProblem);
      });
    }
    return plantProblems;
  };

  setPlantProblems(
    plantProblems: PlantProblem[]
  ): [
    weeds: PlantWeed[],
    plagues: PlantPlague[],
    diseases: PlantDisease[],
    regulators: PlantRegulatorsAndOthers[]
  ] {
    const weeds: PlantWeed[] = [];
    const plagues: PlantPlague[] = [];
    const diseases: PlantDisease[] = [];
    const regulators: PlantRegulatorsAndOthers[] = [];

    plantProblems.forEach((plantProblem) => {
      switch (plantProblem.type) {
        case PlantProblemType.WEED:
          const plantWeed = new PlantWeed({ ...plantProblem });
          if (plantWeed) weeds.push(plantWeed);
          break;
        case PlantProblemType.PLAGUE:
          const plantPlague = new PlantPlague({ ...plantProblem });
          if (plantPlague) plagues.push(plantPlague);
          break;
        case PlantProblemType.DISEASE:
          const plantDisease = new PlantDisease({
            ...plantProblem,
          });
          if (plantDisease) diseases.push(plantDisease);
          break;
        case PlantProblemType.REGULATORS:
          const plantRegulator = new PlantRegulatorsAndOthers({
            ...plantProblem,
          });
          if (plantRegulator) regulators.push(plantRegulator);
          break;

        default:
          break;
      }
    });

    return [weeds, plagues, diseases, regulators];
  }

  setCrop(crop: Crop): void {
    this.crop = crop;
  }
}

export default PhytoRecipe;
