import { PlantProblemTypeInterface } from "../../constants/interfaces";
import { PLANT_PROBLEM_TYPES } from "../../providers/YupProvider";
import FBPlantProblem from "../_api/catalogue/FBPlantProblem";

class PlantProblem extends FBPlantProblem {
  problemType?: PlantProblemTypeInterface;

  constructor(obj?: any) {
    super(obj);
    this.problemType = PLANT_PROBLEM_TYPES.find(
      (type) => type.code === obj?.type
    );
  }

  mapToApiModel(): any {
    const obj: any = super.mapBaseToApiModel();
    obj.type = this.problemType?.code;

    delete obj.problemType;
    return obj;
  }
}

export default PlantProblem;
