import { useState, useMemo } from "react";
import { useQueryClient } from "@tanstack/react-query";
import i18n from "../config/configI18n";

import { Autocomplete, TextField } from "@mui/material";
import { Search, SyncProblem, Sync } from "@mui/icons-material";

import ScreenContentLayout from "../components/ScreenContentLayout";
import { TableBrioCard } from "../components/BrioCard";
import DialogConfirmSiexSync from "../components/dialogs/DialogConfirmSiexSync";
import TestBanner from "../components/banners/TestBanner";
import AlertSnackbar from "../components/elements/AlertSnackbar";

import { useSession } from "../hooks/useSession";
import useFetch from "../hooks/useFetch";
import useCrud from "../hooks/useCrud";

import AgroBusinessAccount from "../models/account/AgroBusinessAccount";

import { Column, SnackbarInterface } from "../constants/interfaces";
import { EMPTY_TABLE_ICON_SIZE } from "../constants/constants";
import { FARMER_SIEX_ROUTE } from "../routes/routeNames";

const SiexPendingScreen = () => {
  const { selectedCueAccount, siexPendingRegisters } = useSession();
  const queryClient = useQueryClient();

  const [selectedABAccount, setSelectedABAccount] =
    useState<AgroBusinessAccount | null>(null);
  const [isConfirmSyncDialogOpen, setIsConfirmSyncDialogOpen] =
    useState<boolean>(false);
  const [snackbarMsg, setSnackbarMsg] = useState<SnackbarInterface | null>(
    null
  );
  const [selectedRowIds, setSelectedRowIds] = useState<number[]>([]);

  const { data: agroBusinessAccounts, isFetching: isFetchingABAs } = useFetch<
    AgroBusinessAccount[]
  >({
    queryKey: ["agroBusinessAccounts", selectedCueAccount?.cueAccount?.id],
    enabled: !!selectedCueAccount,
  });

  const filteredSiexPendingRegs = useMemo(() => {
    if (selectedABAccount === null)
      return siexPendingRegisters.map((pendingRegister) => ({
        ...pendingRegister,
        agrobusinessAccountName: agroBusinessAccounts?.find(
          (aba) =>
            aba.agroBusiness?.id === pendingRegister.request?.agroBusiness?.id
        )?.name,
      }));
    return siexPendingRegisters
      .filter(
        (pendingRegister) =>
          pendingRegister.request?.agroBusiness?.id ===
          selectedABAccount.agroBusiness?.id
      )
      .map((pendingRegister) => ({
        ...pendingRegister,
        agrobusinessAccountName: selectedABAccount.name,
      }));
  }, [selectedABAccount, siexPendingRegisters, agroBusinessAccounts]);

  const emptyTableTitle = useMemo(
    () =>
      selectedABAccount === null
        ? i18n.t("siexPendingRegs.emptyTableTitle")
        : selectedABAccount?.agroBusiness?.siexEntity
        ? i18n.t("siexPendingRegs.emptyTableTitleByExploitation")
        : i18n.t("siexPendingRegs.emptyTableTitleNoSiexExploitation"),
    [selectedABAccount]
  );

  const executeSiexSyncMutation = useCrud({
    key: "executeSiexSync",
    values: siexPendingRegisters.filter((s) =>
      selectedRowIds.includes(s.id || 0)
    ),
    onSuccess: () => {
      queryClient.invalidateQueries(["siexPendingRegisters"]);
      setSelectedRowIds([]);
      closeConfirmSyncDialog();
      setSnackbarMsg({
        severity: "success",
        message: i18n.t("apiResponses.executeSiexSyncSuccess"),
      });
    },
    onError: setSnackbarMsg,
  });

  const siexPendingColumns: Column[] = [
    {
      id: "section.label",
      label: i18n.t("siexPendingRegs.column.sectionName"),
      numeric: false,
    },
    {
      id: "request.creationDateFormatted",
      label: i18n.t("siexPendingRegs.column.dateFormatted"),
      numeric: false,
    },
    {
      id: "agrobusinessAccountName",
      label: i18n.t("siexPendingRegs.column.agrobusinessAccountName"),
      numeric: false,
    },
  ];

  const openConfirmSyncDialog = () => setIsConfirmSyncDialogOpen(true);
  const closeConfirmSyncDialog = () => setIsConfirmSyncDialogOpen(false);

  const handleChangeSelectedAgroBusinessAccount = (
    event: React.SyntheticEvent,
    newValue: AgroBusinessAccount | null
  ) => {
    setSelectedABAccount(newValue);
    queryClient.invalidateQueries(["siexPendingRegisters"]);
  };

  const onClick = (id: number) => {
    const pendingRegister = siexPendingRegisters.find((s) => s.id === id);
    const agroBusinessId = pendingRegister?.request?.agroBusiness?.id;
    if (pendingRegister && agroBusinessId) {
      const url = `${process.env.REACT_APP_CUSTOMER_WEB_URL}/${FARMER_SIEX_ROUTE}/${agroBusinessId}/${pendingRegister.entityClassId}/${pendingRegister.entityClassName}`;
      window.open(url, "_blank", "noopener,noreferrer");
    }
  };

  const handleClickSyncPendings = () => {
    executeSiexSyncMutation.mutate({});
  };

  const TableFiltersMemo = useMemo(() => {
    return (
      <div className="content-table-filters">
        <Autocomplete
          id="agroBusinessAccounts-filter"
          options={agroBusinessAccounts || []}
          getOptionLabel={(option) => option?.name || ""}
          noOptionsText={i18n.t("formErrors.notFoundResults")}
          loading={isFetchingABAs}
          value={selectedABAccount}
          onChange={handleChangeSelectedAgroBusinessAccount}
          renderInput={(params) => (
            <TextField
              {...params}
              size="small"
              sx={{ minWidth: 250, maxWidth: 300 }}
              label={i18n.t("siexPendingRegs.agrobusinessAccountFilterLabel")}
              placeholder={i18n.t(
                "siexPendingRegs.agrobusinessAccountFilterPlaceholder"
              )}
              InputProps={{ ...params.InputProps, startAdornment: <Search /> }}
            />
          )}
        />
      </div>
    );
  }, [selectedABAccount, isFetchingABAs]);

  return (
    <ScreenContentLayout>
      <TestBanner />
      <AlertSnackbar
        open={!!snackbarMsg}
        snackbarMsg={snackbarMsg}
        onClose={() => setSnackbarMsg(null)}
      />
      <TableBrioCard
        title={i18n.t("siexPendingRegs.tableTitle")}
        headerText={i18n.t("siexPendingRegs.headerText_v2")}
        colums={siexPendingColumns}
        rows={filteredSiexPendingRegs}
        selectedRows={selectedRowIds}
        filtersChildren={TableFiltersMemo}
        defaultOrderFieldName="request.creationTimestamp"
        addBtnVariant="contained"
        hideBtn
        auxBtn={selectedRowIds.length > 0}
        auxBtnText={i18n.t("siexPendingRegs.actionBtnText")}
        auxBtnIcon={<Sync style={{ color: "white" }} />}
        disableDelete
        emptyTableIcon={
          <SyncProblem sx={{ fontSize: EMPTY_TABLE_ICON_SIZE }} />
        }
        emptyTableTitle={emptyTableTitle}
        isLoading={executeSiexSyncMutation.isLoading}
        onChangeSelectedRows={setSelectedRowIds}
        onClickEdit={onClick}
        onClickAux={openConfirmSyncDialog}
      />
      <DialogConfirmSiexSync
        isOpen={isConfirmSyncDialogOpen}
        isLoading={executeSiexSyncMutation.isLoading}
        onConfirm={handleClickSyncPendings}
        onCancel={closeConfirmSyncDialog}
      />
    </ScreenContentLayout>
  );
};

export default SiexPendingScreen;
