import packageJson from "../../package.json";
import i18n from "../config/configI18n";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="container">
        <div className="content">
          <span>{i18n.t("components.footer.description")}</span>
          <span className="version">v{packageJson.version}</span>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
