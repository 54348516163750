import { PhytosanitaryTrainingLevelInterface } from "../../constants/interfaces";
import { PHYTOSANITARY_TRAINING_LEVELS } from "../../providers/YupProvider";
import FBAgroBusinessPhytosanitaryInvolvedEntity from "../_api/agrobusiness/FBAgroBusinessPhytosanitaryInvolvedEntity";
import Document from "../files/Document";

class AgroBusinessPhytosanitaryInvolvedEntity extends FBAgroBusinessPhytosanitaryInvolvedEntity {
  trainingLevelType?: PhytosanitaryTrainingLevelInterface;
  isAdvisorUser?: boolean;
  documents?: Document[];
  lastSavedDocuments?: Document[];

  constructor(obj?: any) {
    super(obj);
    if (obj?.trainingLevel)
      this.trainingLevelType = PHYTOSANITARY_TRAINING_LEVELS.find(
        (trainingLevelType) => trainingLevelType.code === obj.trainingLevel
      );
    this.isAdvisorUser = !!obj?.advisorUser?.id;
    this.documents = obj?.documents?.map((doc: any) => new Document(doc));
    this.lastSavedDocuments = this.documents;
  }

  mapToApiModel(): any {
    const obj: any = super.mapBaseToApiModel();
    obj.trainingLevel = this.trainingLevelType?.code;

    // Remove custom variables
    delete obj.trainingLevelType;
    delete obj.isAdvisorUser;
    delete obj.documents;
    delete obj.lastSavedDocuments;
    return obj;
  }
}

export default AgroBusinessPhytosanitaryInvolvedEntity;
