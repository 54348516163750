import FBAdvisorChat from "../_api/advisor/FBAdvisorChat";

class AdvisorChat extends FBAdvisorChat {
  constructor(obj?: any) {
    super(obj);
  }

  mapToApiModel(): any {
    const obj: any = super.mapBaseToApiModel();
    return obj;
  }
}

export default AdvisorChat;
