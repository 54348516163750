import {
  FBEAdvisorChatMessageSource,
  FBEAdvisorChatMessageType,
} from "../../../constants/enums";
import Person from "../../Person";
import Document from "../../files/Document";

class FBAdvisorChatMessage {
  id?: number;
  source?: FBEAdvisorChatMessageSource;
  type?: FBEAdvisorChatMessageType;
  messageTimestamp?: number;
  messageRead?: boolean;
  messageText?: string;
  waMessageId?: string;
  waRelatedMessageId?: string;
  waProfileName?: string;
  waSentOnTimestamp?: number;
  waDeliveredOnTimestamp?: number;
  waReadOnTimestamp?: number;
  bucketLink?: string;
  audioUrl?: string;
  document?: Document;
  advisorSender?: Person;
  pendingToSend?: boolean;

  constructor(obj?: any) {
    this.id = obj?.id;
    this.source = obj?.source;
    this.type = obj?.type;
    this.messageTimestamp = obj?.messageTimestamp;
    this.messageRead = obj?.messageRead;
    this.messageText = obj?.messageText;
    this.waMessageId = obj?.waMessageId;
    this.waRelatedMessageId = obj?.waRelatedMessageId;
    this.waProfileName = obj?.waProfileName;
    this.waSentOnTimestamp = obj?.waSentOnTimestamp;
    this.waDeliveredOnTimestamp = obj?.waDeliveredOnTimestamp;
    this.waReadOnTimestamp = obj?.waReadOnTimestamp;
    this.bucketLink = obj?.bucketLink;
    this.audioUrl = obj?.audioUrl;
    this.document = new Document(obj?.document);
    this.advisorSender = new Person(obj?.advisorSender);
    this.pendingToSend = obj?.pendingToSend;
  }

  mapBaseToApiModel(): any {
    const obj: any = { ...this };
    return obj;
  }
}

export default FBAdvisorChatMessage;
