class FBEntity {
  id?: number;
  entityId?: string;
  foreignId?: boolean;
  type?: string;
  name?: string; //*
  firstSurname?: string;
  secondSurname?: string;
  sex?: string;
  birthDate?: string;
  enabledDate?: string;
  disabledDate?: string;

  constructor(obj?: any) {
    this.id = obj?.id;
    this.entityId = obj?.entityId;
    this.foreignId = obj?.foreignId;
    this.type = obj?.type;
    this.name = obj?.name;
    this.firstSurname = obj?.firstSurname;
    this.secondSurname = obj?.secondSurname;
    this.sex = obj?.sex;
    this.birthDate = obj?.birthDate;
    this.enabledDate = obj?.enabledDate;
    this.disabledDate = obj?.disabledDate;
  }

  mapBaseToApiModel(): any {
    const obj: any = { ...this };
    return obj;
  }
}

export default FBEntity;
