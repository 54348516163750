import moment from "moment";
import { useQuery } from "@tanstack/react-query";
import { getAuth } from "firebase/auth";
import i18n from "../config/configI18n";
import * as Sentry from "@sentry/react";
import { Cookies } from "react-cookie";

import { useAuth } from "./useAuth";
import { useSession } from "./useSession";

import CueAccountWithRole from "../models/account/CueAccountWithRole";
import AgroBusinessAccount from "../models/account/AgroBusinessAccount";
import RegisterRegion from "../models/RegisterRegion";
import LegalContext from "../models/LegalContext";
import AgroBusinessAccountPerson from "../models/account/AgroBusinessAccountPerson";
import AdvisorNote from "../models/advisor/AdvisorNote";
import VadeAgent from "../models/vademecum/VadeAgent";
import VadeSubstance from "../models/vademecum/VadeSubstance";
import VadeCrop from "../models/vademecum/VadeCrop";
import RegisteredPhytosanitaryProduct from "../models/vademecum/RegisteredPhytosanitaryProduct";
import PhytoRecipe from "../models/phytoRecipe/PhytoRecipe";
import Crop from "../models/crop/Crop";
import PlantProblem from "../models/catalogue/PlantProblem";
import MeasurementUnit from "../models/catalogue/MeasurementUnit";
import PhytosanitaryActionJustificationType from "../models/catalogue/PhytosanitaryActionJustificationType";
import PhytosanitaryProduct from "../models/phyto/PhytosanitaryProduct";
import Notification from "../models/notification/Notification";
import RegisteredFertilizerProduct from "../models/vademecumFertiliz/RegisteredFertilizerProduct";
import VadeFertilizerProductType from "../models/vademecumFertiliz/VadeFertilizerProductType";
import VadeFertilizerManufacturer from "../models/vademecumFertiliz/VadeFertilizerManufacturer";
import SiexRequestItem from "../models/siex/SiexRequestItem";

import { getResponseData } from "../helpers/utils";

import {
  SnackbarInterface,
  DocumentSearch,
  ErrorCause,
  AdvisorNoteChipFilter,
  SearchFieldInterface,
  SearchVadePhytoProductInterface,
  SearchPhytoRecipeProductsInterface,
  SearchPhytoRecipePlantProblemsInterface,
  SearchVadeFertilizProductInterface,
} from "../constants/interfaces";
import {
  EXPIRED_TOKEN_ERROR,
  EXPIRED_TOKEN_REFETCH_TIMEOUT,
  INVALID_TOKEN_ERROR,
} from "../constants/constants";
import { refreshTokens } from "../routes/AuthLayout";

import { FBESiexRequestStatus } from "../constants/enums";

type T = any;

interface Props<T> {
  queryKey: unknown[];
  enabled?: boolean;
  selected?: T | any;
  params?: DocumentSearch;
  refetchOnWindowFocus?: boolean;
  refetchInterval?: number;
  filter?: (data: T) => T;
  onSuccess?: (data: T) => void;
  onError?: (snackBarError: SnackbarInterface) => void;
}

function useFetch<T>(props: Props<T>) {
  const {
    queryKey,
    enabled,
    refetchOnWindowFocus,
    refetchInterval,
    selected,
    params,
    filter,
    onSuccess,
    onError,
  } = props;

  const { user, setUser, logout } = useAuth();
  const { selectedCueAccount } = useSession();

  const handleSuccess = (data: T) => {
    onSuccess && onSuccess(data);
  };

  const handleError = async (error: any) => {
    const errorCause = (error as Error)?.cause as ErrorCause;
    const errorClassName = errorCause?.data?.exceptionClassName;
    // Refresh the idToken
    if (
      errorCause?.status === 401 &&
      (errorClassName === INVALID_TOKEN_ERROR ||
        errorClassName === EXPIRED_TOKEN_ERROR)
    ) {
      try {
        const cookies = new Cookies();
        const refreshToken = cookies.get("refreshToken");
        let idToken: string | undefined = undefined;
        if (refreshToken) {
          const tokens = await refreshTokens(refreshToken);
          idToken = tokens?.accessToken;
          const newRefreshToken = tokens?.refreshToken;
          cookies.set("refreshToken", newRefreshToken, { path: "/" });
        } else {
          const auth = getAuth();
          idToken = await auth.currentUser?.getIdToken();
        }

        // Retry after timeout (for security)
        if (idToken) {
          setUser({ ...user, idToken });
          setTimeout(() => {
            query.refetch();
          }, EXPIRED_TOKEN_REFETCH_TIMEOUT);
        } else {
          logout();
          Sentry.captureException("Token expires but should not expire never.");
        }
      } catch (error) {
        logout();
        Sentry.captureException(error);
      }
    } else {
      onError && onError({ severity: "error", message: error?.toString() });
      // Send unexpected error to Sentry
      Sentry.withScope((scope) => {
        scope.setExtra("status", errorCause?.status);
        scope.setExtra("data", errorCause?.data);
        Sentry.captureException(error);
      });
    }
  };

  // If invalid token (error 401), not retry, refresh token and relogin
  const handleRetry = (failureCount: number, error: any) => {
    const errorCause = (error as Error)?.cause as ErrorCause;
    const responseStatus = errorCause?.status;
    // If server responds, not retry
    if (responseStatus) return false; // Generates the error, not retry again
    return true;
  };

  const headers: HeadersInit = (window as any).Cypress
    ? {
        email: process.env.REACT_APP_API_TEST_EMAIL || "",
        "x-api-key": process.env.REACT_APP_API_KEY || "",
      }
    : { Authorization: `Bearer ${user?.idToken}` };

  const fetchProps: FetchProps = {
    selectedCueAccount,
    selected,
    headers,
    params,
  };

  const fnKey = queryKey[0]?.toString() || "";
  const query = useQuery<T>({
    queryKey,
    queryFn: () => fetcher(fnKey, fetchProps),
    select: filter,
    enabled,
    refetchOnWindowFocus,
    refetchInterval,
    retry: handleRetry,
    onSuccess: handleSuccess,
    onError: handleError,
  });

  return {
    data: query.data,
    isFetching: query.isFetching,
    refresh: query.refetch,
  };
}

export default useFetch;

interface FetchProps {
  selectedCueAccount?: CueAccountWithRole | null;
  selected?: T;
  headers?: HeadersInit;
  params?: DocumentSearch;
}

const fetcher = (key: string, props: FetchProps): Promise<T> => {
  switch (key) {
    case "agroBusinessAccounts":
      return fetchAgroBusinessAccounts(props);
    case "agroBusinessAccount":
      return fetchAgroBusinessAccount(props);
    case "legalContexts":
      return fetchLegalContexts(props);
    case "registerRegions":
      return fetchRegisterRegions(props);
    case "agroBusinessAccountPermissions":
      return fetchAgroBusinessAccountPermissions(props);
    case "cueAccountPermissions":
      return fetchCueAccountPermissions(props);
    case "advisorNotes":
      return fetchAdvisorNotes(props);
    case "advisorNote":
      return fetchAdvisorNote(props);
    case "vadePhytoProducts":
      return fetchVadePhytoProducts(props);
    case "vadeAgents":
      return fetchVadeAgents(props);
    case "vadeSubstances":
      return fetchVadeSubstances(props);
    case "vadeCrops":
      return fetchVadeCrops(props);
    case "vadeFertilizProducts":
      return fetchVadeFertilizerProducts(props);
    case "vadeFertilizerProductTypes":
      return fetchVadeFertilizProductTypes(props);
    case "vadeFertilizerManufacturers":
      return fetchVadeFertilizManufacturers(props);
    case "registeredPhytoProductById":
      return fetchRegisteredPhytoProductById(props);
    case "registeredFertilizProductById":
      return fetchRegisteredFertilizProductById(props);
    case "crops":
      return fetchCrops(props);
    case "crop":
      return fetchCrop(props);
    case "phytoRecipes":
      return fetchPhytoRecipes(props);
    case "phytoRecipeById":
      return fetchPhytoRecipeById(props);
    case "phytoRecipeProducts":
      return fetchPhytoRecipeProducts(props);
    case "plantProblems":
      return fetchPhytoRecipePlantProblems(props);
    case "plantProblemsCatalogue":
      return fetchPhytoRecipePlantProblemsByName(props);
    case "measurementUnits":
      return fetchMeasurementUnits(props);
    case "phytosanitaryActionJustificationTypes":
      return fetchPhytoActionJustificationTypes(props);
    case "phytosanitaryProducts":
      return fetchPhytosanitaryProducts(props);
    case "phytosanitaryProduct":
      return fetchPhytosanitaryProduct(props);
    case "notifications":
      return fetchUnreadNotifications(props);
    case "siexPendingRegisters":
      return fetchSiexPendingRegisters(props);
    case "siexErrorRegisters":
      return fetchSiexErrorRegisters(props);

    default:
      return Promise.reject("Function not found");
  }
};

/**
 * AGROBUSINESS endpoints
 */
const fetchAgroBusinessAccounts = async (props: FetchProps): Promise<T> => {
  const { headers, selectedCueAccount } = props;
  const cueAccountId = selectedCueAccount?.cueAccount?.id;
  if (cueAccountId)
    return fetch(
      `${process.env.REACT_APP_API_URL}/cueAccount/${cueAccountId}/agroBusinessAccount/list`,
      {
        method: "GET",
        headers,
      }
    ).then(onAgroBussinessAccountsResponse);
  else return Promise.reject(i18n.t("apiResponses.unexpectedError"));
};

const onAgroBussinessAccountsResponse = async (
  response: Response
): Promise<T> => {
  const data = await getResponseData(response);
  if (response.status === 200 && data) {
    // Sort by recently transferred and then by register region name
    return data
      .map((d: any) => new AgroBusinessAccount(d))
      .sort((a: AgroBusinessAccount, b: AgroBusinessAccount) => {
        if (a.recentlyTransferred !== b.recentlyTransferred) {
          return a.recentlyTransferred ? -1 : 1;
        }
        return 0;
      });
  } else {
    throw new Error(i18n.t("apiResponses.fetchAgroBusinessAccountsError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
  }
};

const fetchAgroBusinessAccount = async (props: FetchProps): Promise<T> => {
  const { headers, selected } = props;
  const selectedAgroBusinessAccount = selected as AgroBusinessAccount;
  const agroBusinessAccountId = selectedAgroBusinessAccount?.id;
  if (agroBusinessAccountId)
    return fetch(
      `${process.env.REACT_APP_API_URL}/agroBusinessAccount/${agroBusinessAccountId}`,
      {
        method: "GET",
        headers,
      }
    ).then(onAgroBusinessAccountResponse);
  else return Promise.reject(i18n.t("apiResponses.unexpectedError"));
};

const onAgroBusinessAccountResponse = async (
  response: Response
): Promise<T> => {
  const data = await getResponseData(response);
  if (response.status === 200 && data) {
    return new AgroBusinessAccount(data);
  } else {
    throw new Error(i18n.t("apiResponses.fetchAgroBusinessAccountError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
  }
};

const fetchAgroBusinessAccountPermissions = async (
  props: FetchProps
): Promise<T> => {
  const { headers, selected } = props;
  const agroBusinessAccountId = selected?.id;
  if (agroBusinessAccountId)
    return fetch(
      `${process.env.REACT_APP_API_URL}/agroBusinessAccount/${agroBusinessAccountId}/permissions`,
      {
        method: "GET",
        headers,
      }
    ).then(onAgroBusinessAccountPermissionsSuccess);
  else return Promise.reject(i18n.t("apiResponses.unexpectedError"));
};

const onAgroBusinessAccountPermissionsSuccess = async (
  response: Response
): Promise<T> => {
  const data = await getResponseData(response);
  if (response.status === 200 && data) {
    return data.map((d: any) => new AgroBusinessAccountPerson(d));
  } else {
    throw new Error(
      i18n.t("apiResponses.fetchAgroBusinessAccountPermissionsError"),
      {
        cause: { status: response.status, data } as ErrorCause,
      }
    );
  }
};

const fetchCueAccountPermissions = async (props: FetchProps): Promise<T> => {
  const { headers, selectedCueAccount } = props;
  const cueAccountId = selectedCueAccount?.cueAccount?.id;
  if (cueAccountId)
    return fetch(
      `${process.env.REACT_APP_API_URL}/cueAccount/${cueAccountId}/permissions`,
      {
        method: "GET",
        headers,
      }
    ).then(onCueAccountPermissionsSuccess);
  else return Promise.reject(i18n.t("apiResponses.unexpectedError"));
};

const onCueAccountPermissionsSuccess = async (
  response: Response
): Promise<T> => {
  const data = await getResponseData(response);
  if (response.status === 200 && data) {
    return data.map((d: any) => new AgroBusinessAccountPerson(d));
  } else {
    throw new Error(i18n.t("apiResponses.fetchCueAccountPermissionsError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
  }
};

/**
 * ADVISOR NOTES endpoints
 */
const fetchAdvisorNotes = async (props: FetchProps): Promise<T> => {
  const { headers, selectedCueAccount, selected } = props;
  const cueAccountId = selectedCueAccount?.cueAccount?.id;
  const noteFilters = selected as AdvisorNoteChipFilter[];
  const agroBusinessAccountId =
    noteFilters?.find(
      (noteFilter) => noteFilter.filterName === "agroBusinessAccountId"
    )?.value || "";
  const fromTimestampValue = noteFilters?.find(
    (noteFilter) => noteFilter.filterName === "fromTimestamp"
  )?.value;
  const fromTimestamp = fromTimestampValue ? fromTimestampValue / 1000 : "";
  const toTimestampValue = noteFilters?.find(
    (noteFilter) => noteFilter.filterName === "toTimestamp"
  )?.value;
  const toTimestamp = toTimestampValue ? toTimestampValue / 1000 : "";
  if (cueAccountId)
    return fetch(
      `${process.env.REACT_APP_API_URL}/cueAccount/${cueAccountId}/advisorNote/list?agroBusinessAccountId=${agroBusinessAccountId}&fromTimestamp=${fromTimestamp}&toTimestamp=${toTimestamp}`,
      {
        method: "GET",
        headers,
      }
    ).then(onAdvisorNotesResponse);
  else return Promise.reject(i18n.t("apiResponses.unexpectedError"));
};

const onAdvisorNotesResponse = async (response: Response): Promise<T> => {
  const data = await getResponseData(response);
  if (response.status === 200 && data) {
    return data.map((d: any) => new AdvisorNote(d));
  } else {
    throw new Error(i18n.t("apiResponses.fetchAdvisorNotes"), {
      cause: { status: response.status, data } as ErrorCause,
    });
  }
};

const fetchAdvisorNote = async (props: FetchProps): Promise<T> => {
  const { headers, selectedCueAccount, selected } = props;
  const cueAccountId = selectedCueAccount?.cueAccount?.id;
  const advisorNote = selected as AdvisorNote;
  const advisorNoteId = advisorNote?.id;
  if (cueAccountId && advisorNoteId)
    return fetch(
      `${process.env.REACT_APP_API_URL}/cueAccount/${cueAccountId}/advisorNote/${advisorNoteId}`,
      {
        method: "GET",
        headers,
      }
    ).then(onAdvisorNoteResponse);
  else return Promise.reject(i18n.t("apiResponses.unexpectedError"));
};

const onAdvisorNoteResponse = async (response: Response): Promise<T> => {
  const data = await getResponseData(response);
  if (response.status === 200 && data) {
    return new AdvisorNote(data);
  } else {
    throw new Error(i18n.t("apiResponses.fetchAdvisorNote"), {
      cause: { status: response.status, data } as ErrorCause,
    });
  }
};

/**
 * VADEMECUM endpoints
 */
const fetchVadePhytoProducts = async (props: FetchProps): Promise<T> => {
  const { headers, selectedCueAccount, selected } = props;
  const cueAccountId = selectedCueAccount?.cueAccount?.id;
  const filters = selected as SearchVadePhytoProductInterface;
  const contextId = filters.productSearch.context?.id || 1;
  const nameContains = filters.productSearch.searchWord;
  const ownerContains = filters.ownerSearch.searchWord;
  const formulationContains = filters.formulationSearch.searchWord;
  const agentId = filters.agentSearch.selected?.id || "";
  const substanceId = filters.substanceSearch.selected?.id || "";
  const cropId = filters.cropSearch.selected?.id || "";

  if (cueAccountId)
    return fetch(
      `${process.env.REACT_APP_API_URL}/vademecumPhyto/product/list?contextId=${contextId}&nameContains=${nameContains}&ownerContains=${ownerContains}&formulationContains=${formulationContains}&agentId=${agentId}&substanceId=${substanceId}&cropId=${cropId}`,
      {
        method: "GET",
        headers,
      }
    ).then(onVadePhytoProductsResponse);
  else return Promise.reject(i18n.t("apiResponses.unexpectedError"));
};

const onVadePhytoProductsResponse = async (response: Response): Promise<T> => {
  const data = await getResponseData(response);
  if (response.status === 200 && data) {
    return data.map((d: any) => new RegisteredPhytosanitaryProduct(d));
  } else {
    throw new Error(i18n.t("apiResponses.fetchVadePhytoProductsError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
  }
};

const fetchVadeAgents = async (props: FetchProps): Promise<T> => {
  const { headers, selectedCueAccount, selected } = props;
  const cueAccountId = selectedCueAccount?.cueAccount?.id;
  const agentSearch = selected as SearchFieldInterface<T>;
  const contextId = agentSearch.context?.id;
  const nameContains = agentSearch.searchWord;

  if (cueAccountId)
    return fetch(
      `${process.env.REACT_APP_API_URL}/vademecumPhyto/agent/list?contextId=${contextId}&nameContains=${nameContains} `,
      {
        method: "GET",
        headers,
      }
    ).then(onVadeAgentsResponse);
  else return Promise.reject(i18n.t("apiResponses.unexpectedError"));
};

const onVadeAgentsResponse = async (response: Response): Promise<T> => {
  const data = await getResponseData(response);
  if (response.status === 200 && data) {
    return data.map((d: any) => new VadeAgent(d));
  } else {
    throw new Error(i18n.t("apiResponses.fetchVadeAgentsError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
  }
};

const fetchVadeSubstances = async (props: FetchProps): Promise<T> => {
  const { headers, selectedCueAccount, selected } = props;
  const cueAccountId = selectedCueAccount?.cueAccount?.id;
  const substanceSearch = selected as SearchFieldInterface<T>;
  const contextId = substanceSearch.context?.id;
  const nameContains = substanceSearch.searchWord;

  if (cueAccountId)
    return fetch(
      `${process.env.REACT_APP_API_URL}/vademecumPhyto/substance/list?contextId=${contextId}&nameContains=${nameContains} `,
      {
        method: "GET",
        headers,
      }
    ).then(onVadeSubstancesResponse);
  else return Promise.reject(i18n.t("apiResponses.unexpectedError"));
};

const onVadeSubstancesResponse = async (response: Response): Promise<T> => {
  const data = await getResponseData(response);
  if (response.status === 200 && data) {
    return data.map((d: any) => new VadeSubstance(d));
  } else {
    throw new Error(i18n.t("apiResponses.fetchVadeSubstancesError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
  }
};

const fetchVadeCrops = async (props: FetchProps): Promise<T> => {
  const { headers, selectedCueAccount, selected } = props;
  const cueAccountId = selectedCueAccount?.cueAccount?.id;
  const cropSearch = selected as SearchFieldInterface<T>;
  const contextId = cropSearch.context?.id;
  const nameContains = cropSearch.searchWord;

  if (cueAccountId)
    return fetch(
      `${process.env.REACT_APP_API_URL}/vademecumPhyto/crop/list?contextId=${contextId}&nameContains=${nameContains} `,
      {
        method: "GET",
        headers,
      }
    ).then(onVadeCropsResponse);
  else return Promise.reject(i18n.t("apiResponses.unexpectedError"));
};

const onVadeCropsResponse = async (response: Response): Promise<T> => {
  const data = await getResponseData(response);
  if (response.status === 200 && data) {
    return data.map((d: any) => new VadeCrop(d));
  } else {
    throw new Error(i18n.t("apiResponses.fetchVadeCropsError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
  }
};

const fetchVadeFertilizerProducts = async (props: FetchProps): Promise<T> => {
  const { headers, selected } = props;
  const filters = selected as SearchVadeFertilizProductInterface;
  const contextId = filters.productSearch.context?.id || 1;
  const nameContains = filters.productSearch.searchWord;
  const typeContains = filters.typeSearch.searchWord;
  const manufacturerContains = filters.manufacturerSearch.searchWord;

  if (contextId)
    return fetch(
      `${process.env.REACT_APP_API_URL}/vademecumFertiliz/product/list?contextId=${contextId}&nameContains=${nameContains}&typeContains=${typeContains}&manufacturerContains=${manufacturerContains}`,
      {
        method: "GET",
        headers,
      }
    ).then(onVadeFertilizerProductsResponse);
  else return Promise.reject(i18n.t("apiResponses.unexpectedError"));
};

const onVadeFertilizerProductsResponse = async (
  response: Response
): Promise<T> => {
  const data = await getResponseData(response);
  if (response.status === 200 && data) {
    return data.map((d: any) => new RegisteredFertilizerProduct(d));
  } else {
    throw new Error(i18n.t("apiResponses.fetchVadeFertilizerProductsError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
  }
};

const fetchVadeFertilizProductTypes = async (props: FetchProps): Promise<T> => {
  const { headers, selected } = props;
  const typeSearch = selected as SearchFieldInterface<T>;
  const contextId = typeSearch.context?.id;
  const nameContains = typeSearch.searchWord;

  if (contextId)
    return fetch(
      `${process.env.REACT_APP_API_URL}/vademecumFertiliz/productType/list?contextId=${contextId}&nameContains=${nameContains} `,
      {
        method: "GET",
        headers,
      }
    ).then(onVadeFertilizProductTypesResponse);
  else return Promise.reject(i18n.t("apiResponses.unexpectedError"));
};

const onVadeFertilizProductTypesResponse = async (
  response: Response
): Promise<T> => {
  const data = await getResponseData(response);
  if (response.status === 200 && data) {
    return data.map((d: any) => new VadeFertilizerProductType(d));
  } else {
    throw new Error(i18n.t("apiResponses.fetchVadeFertilizProductTypesError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
  }
};

const fetchVadeFertilizManufacturers = async (
  props: FetchProps
): Promise<T> => {
  const { headers, selected } = props;
  const manufacturerSearch = selected as SearchFieldInterface<T>;
  const contextId = manufacturerSearch?.context?.id;
  const nameContains = manufacturerSearch.searchWord;

  if (contextId)
    return fetch(
      `${process.env.REACT_APP_API_URL}/vademecumFertiliz/manufacturer/list?contextId=${contextId}&nameContains=${nameContains} `,
      {
        method: "GET",
        headers,
      }
    ).then(onVadeFertilizManufacturersResponse);
  else return Promise.reject(i18n.t("apiResponses.unexpectedError"));
};

const onVadeFertilizManufacturersResponse = async (
  response: Response
): Promise<T> => {
  const data = await getResponseData(response);
  if (response.status === 200 && data) {
    return data.map((d: any) => new VadeFertilizerManufacturer(d));
  } else {
    throw new Error(
      i18n.t("apiResponses.fetchVadeFertilizManufacturersError"),
      {
        cause: { status: response.status, data } as ErrorCause,
      }
    );
  }
};

const fetchRegisteredPhytoProductById = async (
  props: FetchProps
): Promise<T> => {
  const { headers, selected } = props;
  const registeredPhytoProduct = selected as RegisteredPhytosanitaryProduct;
  const registeredPhytoProductId = registeredPhytoProduct?.id;

  if (registeredPhytoProductId)
    return fetch(
      `${process.env.REACT_APP_API_URL}/vademecumPhyto/product/${registeredPhytoProductId}`,
      {
        method: "GET",
        headers,
      }
    ).then(onRegisteredPhytoProductByIdResponse);
  else return Promise.reject(i18n.t("apiResponses.unexpectedError"));
};

const onRegisteredPhytoProductByIdResponse = async (
  response: Response
): Promise<T> => {
  const data = await getResponseData(response);
  if (response.status === 200 && data) {
    return new RegisteredPhytosanitaryProduct(data);
  } else {
    throw new Error(i18n.t("apiResponses.fetchRegisteredProductError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
  }
};

const fetchRegisteredFertilizProductById = async (
  props: FetchProps
): Promise<T> => {
  const { headers, selected } = props;
  const registeredFertilizProduct = selected as RegisteredFertilizerProduct;
  const registeredFertilizProductId = registeredFertilizProduct?.id;

  if (registeredFertilizProductId)
    return fetch(
      `${process.env.REACT_APP_API_URL}/vademecumFertiliz/product/${registeredFertilizProductId}`,
      {
        method: "GET",
        headers,
      }
    ).then(onRegisteredFertilizProductByIdResponse);
  else return Promise.reject(i18n.t("apiResponses.unexpectedError"));
};

const onRegisteredFertilizProductByIdResponse = async (
  response: Response
): Promise<T> => {
  const data = await getResponseData(response);
  if (response.status === 200 && data) {
    return new RegisteredFertilizerProduct(data);
  } else {
    throw new Error(i18n.t("apiResponses.fetchRegisteredProductError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
  }
};

/**
 * CROP Endpoints
 */
const fetchCrops = async (props: FetchProps): Promise<T> => {
  const { headers, selected } = props;
  const agroBusinessId = selected?.agroBusinessId;

  if (agroBusinessId) {
    return fetch(
      `${process.env.REACT_APP_API_URL}/agroBusiness/${agroBusinessId}/crop/list`,
      {
        method: "GET",
        headers,
      }
    ).then(onCropsSuccess);
  } else return Promise.reject(i18n.t("routeErrors.unexpectedError"));
};

const onCropsSuccess = async (response: Response): Promise<T> => {
  const data = await getResponseData(response);
  if (response.status === 200 && data) {
    return data.map((d: any) => new Crop(d));
  } else {
    throw new Error(i18n.t("apiResponses.fetchCropsError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
  }
};

const fetchCrop = async (props: FetchProps): Promise<T> => {
  const { headers, selected } = props;
  const agroBusinessId = selected?.agroBusinessId;
  const crop = selected?.payload as Crop;
  const cropId = crop?.id;

  if (agroBusinessId && cropId)
    return fetch(
      `${process.env.REACT_APP_API_URL}/agroBusiness/${agroBusinessId}/crop/${cropId}`,
      {
        method: "GET",
        headers,
      }
    ).then(onCropResponse);
  else return Promise.reject(i18n.t("routeErrors.unexpectedError"));
};

const onCropResponse = async (response: Response): Promise<T> => {
  const data = await getResponseData(response);
  if (response.status === 200 && data) {
    return new Crop(data);
  } else {
    throw new Error(i18n.t("apiResponses.fetchCropError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
  }
};

/**
 * CATALOGUE endpoints
 */
const fetchLegalContexts = async (props: FetchProps): Promise<T> => {
  const { headers } = props;
  return fetch(`${process.env.REACT_APP_API_URL}/catalogue/legalContext/list`, {
    method: "GET",
    headers,
  }).then(onLegalContextsResponse);
};

const onLegalContextsResponse = async (response: Response): Promise<T> => {
  const data = await getResponseData(response);
  if (response.status === 200 && data) {
    return data.map((d: any) => new LegalContext(d));
  } else
    throw new Error(i18n.t("apiResponses.fetchLegalContextsError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
};

const fetchRegisterRegions = async (props: FetchProps): Promise<T> => {
  const { headers, selected } = props;
  const legalContext = selected as LegalContext;
  const contextId = legalContext?.id;
  if (contextId)
    return fetch(
      `${process.env.REACT_APP_API_URL}/catalogue/registerRegion/list?contextId=${contextId}`,
      {
        method: "GET",
        headers,
      }
    ).then(onRegisterRegionsResponse);
  else return Promise.reject(i18n.t("apiResponses.unexpectedError"));
};

const onRegisterRegionsResponse = async (response: Response): Promise<T> => {
  const data = await getResponseData(response);
  if (response.status === 200 && data) {
    return data.map((d: any) => new RegisterRegion(d));
  } else
    throw new Error(i18n.t("apiResponses.fetchRegisterRegions"), {
      cause: { status: response.status, data } as ErrorCause,
    });
};

/**
 * PHYTO RECIPES
 */

const fetchPhytoRecipes = async (props: FetchProps): Promise<T> => {
  const { headers, selected } = props;
  const agroBusinessAccount = selected as AgroBusinessAccount;
  const agroBusinessId = agroBusinessAccount?.agroBusiness?.id;
  if (agroBusinessId)
    return fetch(
      `${process.env.REACT_APP_API_URL}/agroBusiness/${agroBusinessId}/phytoRecipe/list`,
      {
        method: "GET",
        headers,
      }
    ).then(onPhytoRecipesSuccess);
  else return Promise.reject(i18n.t("routeErrors.unexpectedError"));
};

const onPhytoRecipesSuccess = async (response: Response): Promise<T> => {
  const data = await getResponseData(response);
  if (response.status === 200 && data) {
    const recipes = data.map((d: any) => new PhytoRecipe(d));
    // Sort by date, desc
    return recipes.sort(
      (a: PhytoRecipe, b: PhytoRecipe) =>
        moment(b?.date).valueOf() - moment(a?.date).valueOf()
    );
  } else {
    throw new Error(i18n.t("apiResponses.fetchPhytoRecipeListError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
  }
};

const fetchPhytoRecipeById = async (props: FetchProps): Promise<T> => {
  const { headers, selected } = props;
  const agroBusinessId = selected?.agroBusinessId;
  const phytoRecipe = selected?.payload as PhytoRecipe;
  const phytoRecipeId = phytoRecipe?.id;

  if (agroBusinessId && phytoRecipeId)
    return fetch(
      `${process.env.REACT_APP_API_URL}/agroBusiness/${agroBusinessId}/phytoRecipe/${phytoRecipeId}`,
      {
        method: "GET",
        headers,
      }
    ).then(onPhytoRecipeByIdResponse);
  else return Promise.reject(i18n.t("apiResponses.unexpectedError"));
};

const onPhytoRecipeByIdResponse = async (response: Response): Promise<T> => {
  const data = await getResponseData(response);
  if (response.status === 200 && data) {
    return new PhytoRecipe(data);
  } else {
    throw new Error(i18n.t("apiResponses.fetchPhytoRecipeByIdError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
  }
};

const fetchPhytoRecipeProducts = async (props: FetchProps): Promise<T> => {
  const { headers, selected } = props;
  const filters = selected as SearchPhytoRecipeProductsInterface;
  const { contextId, nameContains, siexProductId, plantProblemIds } = filters;
  const plantProblemIdList = plantProblemIds?.join(",");

  if (contextId)
    return fetch(
      `${
        process.env.REACT_APP_API_URL
      }/vademecumPhyto/product/list?contextId=${contextId}&nameContains=${nameContains}&siexProductId=${
        siexProductId || ""
      }&plantProblemIdList=${plantProblemIdList}`,
      {
        method: "GET",
        headers,
      }
    ).then(onPhytoRecipeProductsResponse);
  else return Promise.reject(i18n.t("apiResponses.unexpectedError"));
};

const onPhytoRecipeProductsResponse = async (
  response: Response
): Promise<T> => {
  const data = await getResponseData(response);
  if (response.status === 200 && data) {
    return data.map((d: any) => new RegisteredPhytosanitaryProduct(d));
  } else {
    throw new Error(i18n.t("apiResponses.fetchVadePhytoProductsError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
  }
};

const fetchPhytoRecipePlantProblems = async (props: FetchProps): Promise<T> => {
  const { headers, selected } = props;
  const filters = selected as SearchPhytoRecipePlantProblemsInterface;
  const { contextId, nameContains, siexProductId } = filters;

  if (contextId)
    return fetch(
      `${
        process.env.REACT_APP_API_URL
      }/vademecumPhyto/plantProblem/list?contextId=${contextId}&nameContains=${
        nameContains || ""
      }&siexProductId=${siexProductId || ""}`,
      {
        method: "GET",
        headers,
      }
    ).then(onPhytoRecipePlantProblemsResponse);
  else return Promise.reject(i18n.t("apiResponses.unexpectedError"));
};

const onPhytoRecipePlantProblemsResponse = async (
  response: Response
): Promise<T> => {
  const data = await getResponseData(response);
  if (response.status === 200 && data) {
    return data.map((d: any) => new PlantProblem(d));
  } else {
    throw new Error(i18n.t("apiResponses.fetchVadePhytoPlantProblemsError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
  }
};

const fetchPhytoRecipePlantProblemsByName = async (
  props: FetchProps
): Promise<T> => {
  const { headers, selected } = props;
  const filters = selected as SearchPhytoRecipePlantProblemsInterface;
  const { contextId, nameContains } = filters;
  const nameLike = `%25${nameContains}%25`;

  if (contextId)
    return fetch(
      `${process.env.REACT_APP_API_URL}/catalogue/plantProblem/list?contextId=${contextId}&nameLike=${nameLike}`,
      {
        method: "GET",
        headers,
      }
    ).then(onPhytoRecipePlantProblemsByNameResponse);
  else return Promise.reject(i18n.t("apiResponses.unexpectedError"));
};

const onPhytoRecipePlantProblemsByNameResponse = async (
  response: Response
): Promise<T> => {
  const data = await getResponseData(response);
  if (response.status === 200 && data) {
    return data.map((d: any) => new PlantProblem(d));
  } else {
    throw new Error(i18n.t("apiResponses.fetchVadePhytoPlantProblemsError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
  }
};

const fetchMeasurementUnits = async (props: FetchProps): Promise<T> => {
  const { headers, selected } = props;
  const agroBusinessAccount = selected as AgroBusinessAccount;
  const contextId = agroBusinessAccount.context?.id;
  if (contextId)
    return fetch(
      `${process.env.REACT_APP_API_URL}/catalogue/measurementUnitType/list?contextId=${contextId}`,
      {
        method: "GET",
        headers,
      }
    ).then(onMeasurementUnitsResponse);
  else return Promise.reject(i18n.t("routeErrors.unexpectedError"));
};

const onMeasurementUnitsResponse = async (response: Response): Promise<T> => {
  const data = await getResponseData(response);
  if (response.status === 200 && data) {
    return data.map((d: any) => new MeasurementUnit(d));
  } else
    throw new Error(i18n.t("apiResponses.fetchMeasurementUnitsError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
};

const fetchPhytoActionJustificationTypes = async (
  props: FetchProps
): Promise<T> => {
  const { headers, selected } = props;
  const agroBusinessAccount = selected as AgroBusinessAccount;
  const contextId = agroBusinessAccount.context?.id;
  if (contextId)
    return fetch(
      `${process.env.REACT_APP_API_URL}/catalogue/phytosanitaryActionJustificationType/list?contextId=${contextId}`,
      {
        method: "GET",
        headers,
      }
    ).then(onPhytoActionJustificationTypesResponse);
  else return Promise.reject(i18n.t("routeErrors.unexpectedError"));
};

const onPhytoActionJustificationTypesResponse = async (
  response: Response
): Promise<T> => {
  const data = await getResponseData(response);
  if (response.status === 200 && data) {
    return data.map((d: any) => new PhytosanitaryActionJustificationType(d));
  } else
    throw new Error(
      i18n.t("apiResponses.fetchPhytoActionJustificationTypesError"),
      {
        cause: { status: response.status, data } as ErrorCause,
      }
    );
};

const fetchPhytosanitaryProducts = async (props: FetchProps): Promise<T> => {
  const { headers, selected } = props;
  const agroBusinessId = selected.agroBusinessId;

  if (agroBusinessId)
    return fetch(
      `${process.env.REACT_APP_API_URL}/agroBusiness/${agroBusinessId}/phytosanitaryProduct/list`,
      {
        method: "GET",
        headers,
      }
    ).then(onPhytosanitaryProductsResponse);
  else return Promise.reject(i18n.t("routeErrors.unexpectedError"));
};

const onPhytosanitaryProductsResponse = async (
  response: Response
): Promise<T> => {
  const data = await getResponseData(response);
  if (response.status === 200 && data) {
    return data.map((d: any) => new PhytosanitaryProduct(d));
  } else
    throw new Error(i18n.t("apiResponses.fetchPhytosanitaryProductsError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
};

const fetchPhytosanitaryProduct = async (props: FetchProps): Promise<T> => {
  const { headers, selected } = props;
  const agroBusinessId = selected?.agroBusinessId;
  const selectedPhytosanitaryProduct =
    selected?.payload as PhytosanitaryProduct;
  const productId = selectedPhytosanitaryProduct?.id;

  if (agroBusinessId)
    return fetch(
      `${process.env.REACT_APP_API_URL}/agroBusiness/${agroBusinessId}/phytosanitaryProduct/${productId}`,
      {
        method: "GET",
        headers,
      }
    ).then(onPhytosanitaryProductResponse);
  else return Promise.reject(i18n.t("routeErrors.unexpectedError"));
};

const onPhytosanitaryProductResponse = async (
  response: Response
): Promise<T> => {
  const data = await getResponseData(response);
  if (response.status === 200 && data) {
    return new PhytosanitaryProduct(data);
  } else
    throw new Error(i18n.t("apiResponses.fetchPhytosanitaryProductError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
};

const fetchUnreadNotifications = async (props: FetchProps): Promise<T> => {
  const { headers, selectedCueAccount } = props;
  const cueAccountId = selectedCueAccount?.cueAccount?.id;

  return fetch(
    `${process.env.REACT_APP_API_URL}/cueAccount/${cueAccountId}/notification/list?includeFutures=true&includeExpired=true&includeRead=false`,
    {
      method: "GET",
      headers,
    }
  ).then(onUnreadNotificationsSuccess);
};

const onUnreadNotificationsSuccess = async (response: Response): Promise<T> => {
  const data = await getResponseData(response);
  if (response.status === 200 && data) {
    return data.map((d: any) => new Notification(d));
  } else {
    throw new Error(i18n.t("apiResponses.fetchNotificationsError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
  }
};

const fetchSiexPendingRegisters = async (props: FetchProps): Promise<T> => {
  const { headers, selectedCueAccount } = props;
  const cueAccountId = selectedCueAccount?.cueAccount?.id;

  if (cueAccountId)
    return fetch(
      `${process.env.REACT_APP_API_URL}/cueAccount/${cueAccountId}/siex/requestItem/list?operationResult=PENDING`,
      {
        method: "GET",
        headers,
      }
    ).then(onSiexPendingRegistersResponse);
  else return Promise.resolve([]);
};

const onSiexPendingRegistersResponse = async (
  response: Response
): Promise<T> => {
  const data = await getResponseData(response);
  if (response.status === 200 && data) {
    return data
      .map((d: any) => new SiexRequestItem(d))
      ?.filter(
        (item: SiexRequestItem) =>
          item.request?.status === FBESiexRequestStatus.ADVISOR_PENDING
      );
  } else {
    throw new Error(i18n.t("apiResponses.fetchSiexPendingRegistersError"), {
      cause: { status: response.status } as ErrorCause,
    });
  }
};

const fetchSiexErrorRegisters = async (props: FetchProps): Promise<T> => {
  const { headers, selectedCueAccount } = props;
  const cueAccountId = selectedCueAccount?.cueAccount?.id;

  if (cueAccountId)
    return fetch(
      `${process.env.REACT_APP_API_URL}/cueAccount/${cueAccountId}/siex/requestItem/list?operationResult=ERROR`,
      {
        method: "GET",
        headers,
      }
    ).then(onSiexErrorRegistersResponse);
  else return Promise.resolve([]);
};

const onSiexErrorRegistersResponse = async (response: Response): Promise<T> => {
  const data = await getResponseData(response);
  if (response.status === 200 && data) {
    return data.map((d: any) => new SiexRequestItem(d));
  } else {
    throw new Error(i18n.t("apiResponses.fetchSiexErrorRegistersError"), {
      cause: { status: response.status } as ErrorCause,
    });
  }
};
