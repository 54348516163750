import {
  useMutation,
  useQueryClient,
  QueryClient,
} from "@tanstack/react-query";
import { getAuth } from "firebase/auth";
import i18n from "../config/configI18n";
import * as Sentry from "@sentry/react";
import { Cookies } from "react-cookie";

import { useAuth } from "./useAuth";
import { useSession } from "./useSession";

import { getResponseData } from "../helpers/utils";

import CueAccountWithRole from "../models/account/CueAccountWithRole";
import AgroBusinessAccount from "../models/account/AgroBusinessAccount";
import AgroBusinessAccountPerson from "../models/account/AgroBusinessAccountPerson";
import AdvisorNote from "../models/advisor/AdvisorNote";
import Person from "../models/Person";
import CueAccount from "../models/account/CueAccount";
import LocalAuthSendCodeRequest from "../models/auth/LocalAuthSendCodeRequest";
import PhytoRecipe from "../models/phytoRecipe/PhytoRecipe";
import Notification from "../models/notification/Notification";

import {
  ChangePhytoRecipeStatusInterface,
  ErrorCause,
  SendLinkCodeInterface,
  SnackbarInterface,
  TokensInterface,
} from "../constants/interfaces";
import {
  ENTITY_NOT_FOUND_ERROR,
  ENTITY_NOT_FOUND_EXCEPTION_ERROR,
  EXPIRED_TOKEN_ERROR,
  EXPIRED_TOKEN_REFETCH_TIMEOUT,
  ILEGAL_DATA_OPERATION_ERROR,
  INVALID_TOKEN_ERROR,
  UPLOAD_FILE_ERROR,
} from "../constants/constants";
import { refreshTokens } from "../routes/AuthLayout";
import SiexRequestItem from "../models/siex/SiexRequestItem";

type T = any;
interface Props<T> {
  key: string;
  values?: T;
  onSuccess?: (data: T) => void;
  onError?: (snackBarError: SnackbarInterface) => void;
}
function useCrud<T>(props: Props<T>) {
  const { values, onSuccess, onError } = props;

  const { user, setUser, logout } = useAuth();
  const { selectedCueAccount } = useSession();
  const queryQlient = useQueryClient();

  const handleSuccess = (data: T) => {
    onSuccess && onSuccess(data);
  };

  const handleError = async (error: Error) => {
    const errorCause = error?.cause as ErrorCause;
    const errorClassName = errorCause?.data?.exceptionClassName;
    // Refresh token if invalid token
    if (
      errorCause?.status === 401 &&
      (errorClassName === INVALID_TOKEN_ERROR ||
        errorClassName === EXPIRED_TOKEN_ERROR)
    ) {
      try {
        const cookies = new Cookies();
        const refreshToken = cookies.get("refreshToken");
        let idToken: string | undefined = undefined;
        if (refreshToken) {
          const tokens = await refreshTokens(refreshToken);
          idToken = tokens?.accessToken;
          const newRefreshToken = tokens?.refreshToken;
          cookies.set("refreshToken", newRefreshToken, { path: "/" });
        } else {
          const auth = getAuth();
          idToken = await auth.currentUser?.getIdToken();
        }

        // Retry after timeout (for security)
        if (idToken) {
          setUser({ ...user, idToken });
          setTimeout(() => {
            mutation.mutate(values as any);
          }, EXPIRED_TOKEN_REFETCH_TIMEOUT);
        } else {
          logout();
          Sentry.captureException("Token expires but should not expire never.");
        }
      } catch (error) {
        logout();
        Sentry.captureException(error);
      }
    } else if (
      errorClassName === ILEGAL_DATA_OPERATION_ERROR ||
      errorClassName === ENTITY_NOT_FOUND_EXCEPTION_ERROR
    ) {
      onError &&
        onError({
          severity: "error",
          message: i18n.t("apiResponses.ilegalDataOperationError"),
        });
    } else if (errorClassName === ENTITY_NOT_FOUND_ERROR) {
      onError &&
        onError({
          severity: "error",
          message: i18n.t("apiResponses.userNotRegisteredByWhatsappError"),
        });
    } else {
      onError &&
        onError({
          severity: "error",
          message: error?.toString(),
          hasDocError: errorCause?.status === UPLOAD_FILE_ERROR,
        });
      // Send unexpected error to Sentry
      Sentry.withScope((scope) => {
        scope.setExtra("status", errorCause?.status);
        scope.setExtra("data", errorCause?.data);
        Sentry.captureException(error);
      });
    }
  };

  // If invalid token (error 401), not retry, refresh token and relogin
  const handleRetry = (failureCount: number, error: any) => {
    const errorCause = (error as Error)?.cause as ErrorCause;
    const responseStatus = errorCause?.status;
    // If server responds, not retry
    if (responseStatus) return false; // Generates the error, not retry again
    return true;
  };

  const headers: HeadersInit = (window as any).Cypress
    ? {
        email: process.env.REACT_APP_API_TEST_EMAIL || "",
        "x-api-key": process.env.REACT_APP_API_KEY || "",
        "Content-Type": "application/json",
      }
    : {
        Authorization: `Bearer ${user?.idToken}`,
        "Content-Type": "application/json",
      };

  const crudProps: CrudProps = {
    queryClient: queryQlient,
    selectedCueAccount,
    headers,
    values,
  };

  const mutation = useMutation({
    mutationFn: (extraData?: Object) => cruder(props.key, crudProps, extraData),
    retry: handleRetry,
    onSuccess: handleSuccess,
    onError: handleError,
  });

  return mutation;
}

export default useCrud;

interface CrudProps {
  queryClient: QueryClient;
  selectedCueAccount?: CueAccountWithRole | null;
  headers?: HeadersInit;
  values?: T;
}
interface CrudResponseProps {
  response: Response;
  crudProps: CrudProps;
}

const cruder = (
  key: string,
  props: CrudProps,
  extraData?: Object
): Promise<T> => {
  switch (key) {
    case "sendAuthCodeByEmail":
      return sendAuthCodeByEmail({
        ...props,
        values: extraData || props.values,
      });
    case "sendAuthCodeByWhatsapp":
      return sendAuthCodeByWhatsapp({
        ...props,
        values: extraData || props.values,
      });
    case "postAuthWithEmailCode":
      return postAuthWithEmailCode({
        ...props,
        values: extraData || props.values,
      });
    case "postAuthWithWhatsappCode":
      return postAuthWithWhatsappCode({
        ...props,
        values: extraData || props.values,
      });
    case "sendLinkCode":
      return sendLinkCode({
        ...props,
        values: extraData || props.values,
      });
    case "attachEmailPhone":
      return attachEmailPhone({
        ...props,
        values: extraData || props.values,
      });
    case "postAdvisorAccount":
      return postAdvisorAccount(props);
    case "postAgroBusinessAccount":
      return postAgroBusinessAccount(props);
    case "putAgroBusinessAccount":
      return putAgroBusinessAccount(props);
    case "deleteAgroBusinessAccounts":
      return deleteAgroBusinessAccounts(props);
    case "putAgroBusinessAccountPermission":
      return putAgroBusinessAccountPermission(props);
    case "deleteAgroBusinessAccountPermissions":
      return deleteAgroBusinessAccountPermissions(props);
    case "resetRecentlyTransferedFlagForCueAccountId":
      return resetRecentlyTransferedFlagForCueAccountId(props);
    case "putCueAccountPermission":
      return putCueAccountPermission(props);
    case "deleteCueAccountPermissions":
      return deleteCueAccountPermissions(props);
    case "postAdvisorNote":
      return postAdvisorNote(props);
    case "putAdvisorNote":
      return putAdvisorNote(props);
    case "deleteAdvisorNotes":
      return deleteAdvisorNotes(props);
    case "putPersonLanguage":
      return putPersonLanguage({ ...props, values: extraData });
    case "changePhytoRecipeStatus":
      return changePhytoRecipeStatus({ ...props, values: extraData });
    case "postPhytoRecipe":
      return postPhytoRecipe({ ...props, values: extraData });
    case "putPhytoRecipe":
      return putPhytoRecipe({ ...props, values: extraData });
    case "deletePhytoRecipe":
      return deletePhytoRecipe({ ...props, values: extraData });
    case "markNotificationAsRead":
      return markNotificationAsRead({ ...props, values: extraData });
    case "executeSiexSync":
      return executeSiexSync(props);
    default:
      return Promise.reject("Function not found");
  }
};

/**
 * AUTH CRUD endpoints
 */
const sendAuthCodeByEmail = async (props: CrudProps): Promise<void> => {
  const { headers, values } = props;
  const localAuthSendCodeReq = values as LocalAuthSendCodeRequest;
  localAuthSendCodeReq.language = i18n.language; // Set browser language
  return fetch(`${process.env.REACT_APP_API_URL}/auth/sendAuthCodeByEmail`, {
    method: "POST",
    headers,
    body: JSON.stringify(localAuthSendCodeReq),
  }).then(onSendAuthCodeByEmailResponse);
};

const onSendAuthCodeByEmailResponse = async (
  response: Response
): Promise<void> => {
  if (response.status !== 200) {
    throw new Error(i18n.t("apiResponses.sendAuthCodeByEmailResponseError"), {
      cause: { status: response.status, data: {} } as ErrorCause,
    });
  }
};

const sendAuthCodeByWhatsapp = async (props: CrudProps): Promise<void> => {
  const { values } = props;
  const localAuthSendCodeReq = values as LocalAuthSendCodeRequest;
  localAuthSendCodeReq.language = i18n.language; // Set browser language
  const phone = localAuthSendCodeReq.phone?.replace(/[^0-9]/g, "") || "";
  localAuthSendCodeReq.phone = phone;
  return fetch(`${process.env.REACT_APP_API_URL}/auth/sendAuthCodeByWhatsapp`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(localAuthSendCodeReq),
  }).then(onSendAuthCodeByWhatsappResponse);
};

const onSendAuthCodeByWhatsappResponse = async (
  response: Response
): Promise<void> => {
  const data = await getResponseData(response);
  if (response.status !== 200) {
    throw new Error(
      i18n.t("apiResponses.sendAuthCodeByWhatsappResponseError"),
      {
        cause: { status: response.status, data } as ErrorCause,
      }
    );
  }
};

const postAuthWithEmailCode = async (
  props: CrudProps
): Promise<TokensInterface | undefined> => {
  const { headers, values } = props;
  const localAuthSendCodeReq = values as LocalAuthSendCodeRequest;
  localAuthSendCodeReq.language = i18n.language; // Set browser language
  localAuthSendCodeReq.phone = "";
  return fetch(`${process.env.REACT_APP_API_URL}/auth/get`, {
    method: "POST",
    headers,
    body: JSON.stringify(localAuthSendCodeReq.mapToApiModel()),
  }).then(onPostAuthWithEmailCodeResponse);
};

const onPostAuthWithEmailCodeResponse = async (
  response: Response
): Promise<TokensInterface | undefined> => {
  const data = await getResponseData(response);
  if (response.status === 200) {
    return data as TokensInterface;
  } else {
    throw new Error(i18n.t("apiResponses.postAuthWithEmailCodeResponseError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
  }
};

const postAuthWithWhatsappCode = async (
  props: CrudProps
): Promise<TokensInterface | undefined> => {
  const { headers, values } = props;
  const localAuthSendCodeReq = values as LocalAuthSendCodeRequest;
  localAuthSendCodeReq.language = i18n.language; // Set browser language
  localAuthSendCodeReq.email = "";
  const phone = localAuthSendCodeReq.phone?.replace(/[^0-9]/g, "") || "";
  localAuthSendCodeReq.phone = phone;
  return fetch(`${process.env.REACT_APP_API_URL}/auth/get`, {
    method: "POST",
    headers,
    body: JSON.stringify(localAuthSendCodeReq.mapToApiModel()),
  }).then(onPostAuthWithWhatsappCodeResponse);
};

const onPostAuthWithWhatsappCodeResponse = async (
  response: Response
): Promise<TokensInterface | undefined> => {
  const data = await getResponseData(response);
  if (response.status === 200) {
    return data as TokensInterface;
  } else {
    throw new Error(
      i18n.t("apiResponses.postAuthWithWhatsappCodeResponseError"),
      {
        cause: { status: response.status, data } as ErrorCause,
      }
    );
  }
};

const sendLinkCode = async (
  props: CrudProps
): Promise<SendLinkCodeInterface | undefined> => {
  const { headers, values } = props;
  const sendLinkCodeValues = values as SendLinkCodeInterface;
  sendLinkCodeValues.language = i18n.language; // Set language
  const phone = sendLinkCodeValues.phone?.replace(/[^0-9]/g, "") || "";
  sendLinkCodeValues.phone = phone;
  return fetch(`${process.env.REACT_APP_API_URL}/person/sendLinkCode`, {
    method: "POST",
    headers,
    body: JSON.stringify(sendLinkCodeValues),
  }).then(onSendLinkCodeResponse);
};

const onSendLinkCodeResponse = async (
  response: Response
): Promise<SendLinkCodeInterface | undefined> => {
  const data = await getResponseData(response);
  if (response.status === 200) {
    return data as SendLinkCodeInterface;
  } else {
    throw new Error(i18n.t("apiResponses.sendLinkCodeResponseError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
  }
};

const attachEmailPhone = async (props: CrudProps): Promise<void> => {
  const { headers, values } = props;
  return fetch(`${process.env.REACT_APP_API_URL}/person/attachEmailPhone`, {
    method: "POST",
    headers,
    body: JSON.stringify({
      token: values?.token || "",
      code: values?.code || "",
    }),
  }).then(onAttachEmailPhoneResponse);
};

const onAttachEmailPhoneResponse = async (
  response: Response
): Promise<void> => {
  const data = await getResponseData(response);
  if (response.status !== 200) {
    if (data.message?.includes("user with this whatsapp"))
      throw new Error(
        i18n.t("apiResponses.attachEmailPhoneCodeOtherUserPhoneError"),
        {
          cause: { status: response.status, data: {} } as ErrorCause,
        }
      );
    if (data.message?.includes("user with this email"))
      throw new Error(
        i18n.t("apiResponses.attachEmailPhoneCodeOtherUserEmailError"),
        {
          cause: { status: response.status, data: {} } as ErrorCause,
        }
      );
    if (data.message?.includes("Invalid attach code"))
      throw new Error(
        i18n.t("apiResponses.attachEmailPhoneCodeInvalidCodeError"),
        {
          cause: { status: response.status, data: {} } as ErrorCause,
        }
      );

    throw new Error(i18n.t("apiResponses.attachEmailPhoneCodeError"), {
      cause: { status: response.status, data: {} } as ErrorCause,
    });
  }
};

/**
 * AGROBUSINESS CRUDs
 */

const postAdvisorAccount = async (
  props: CrudProps
): Promise<CueAccount | undefined> => {
  const { headers, values } = props;
  const cueAcccount = values as CueAccount;
  const browserLanguage = i18n.language;
  return fetch(
    `${process.env.REACT_APP_API_URL}/cueAccount/createAdvisor?language=${browserLanguage}`,
    {
      method: "POST",
      headers,
      body: JSON.stringify(cueAcccount.mapToApiModel()),
    }
  ).then(onPostAdvisorAccountResponse);
};

const onPostAdvisorAccountResponse = async (
  response: Response
): Promise<CueAccount | undefined> => {
  const data = await getResponseData(response);
  if (response.status === 200) {
    return new CueAccount(data);
  } else {
    throw new Error(i18n.t("apiResponses.cueAccountAlreadyExitsError"), {
      cause: { status: response.status } as ErrorCause,
    });
  }
};

const postAgroBusinessAccount = async (
  props: CrudProps
): Promise<AgroBusinessAccount | undefined> => {
  const { headers, values, selectedCueAccount } = props;
  const aba = values as AgroBusinessAccount;
  const cueAccountId = selectedCueAccount?.cueAccount?.id;
  return fetch(
    `${process.env.REACT_APP_API_URL}/cueAccount/${cueAccountId}/agroBusinessAccount`,
    {
      method: "POST",
      headers,
      body: JSON.stringify(aba.mapToApiModel()),
    }
  ).then((response: Response) =>
    onPostAgroBusinessAccountResponse({ response, crudProps: props })
  );
};

const onPostAgroBusinessAccountResponse = async (
  props: CrudResponseProps
): Promise<AgroBusinessAccount | undefined> => {
  const { response, crudProps } = props;
  const { queryClient } = crudProps;
  const data = await getResponseData(response);
  if (response.status === 200) {
    const newABA = new AgroBusinessAccount(data);
    // Update list
    queryClient.refetchQueries({
      queryKey: ["agroBusinessAccounts"],
      type: "active",
    });
    return newABA;
  } else {
    throw new Error(i18n.t("apiResponses.postAgroBusinessAccountError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
  }
};

const putAgroBusinessAccount = async (
  props: CrudProps
): Promise<AgroBusinessAccount | undefined> => {
  const { headers, values } = props;
  const aba = values as AgroBusinessAccount;
  const abaId = aba?.id;
  return fetch(
    `${process.env.REACT_APP_API_URL}/agroBusinessAccount/${abaId}`,
    {
      method: "PUT",
      headers,
      body: JSON.stringify(aba.mapToApiModel()),
    }
  ).then((response: Response) =>
    onPutAgroBusinessAccountResponse({ response, crudProps: props })
  );
};

const onPutAgroBusinessAccountResponse = async (
  props: CrudResponseProps
): Promise<AgroBusinessAccount | undefined> => {
  const { response, crudProps } = props;
  const { queryClient } = crudProps;
  const data = await getResponseData(response);
  if (response.status === 200) {
    const updatedABA = new AgroBusinessAccount(data);
    // Update list
    queryClient.refetchQueries({
      queryKey: ["agroBusinessAccounts"],
      type: "active",
    });
    return updatedABA;
  } else {
    throw new Error(i18n.t("apiResponses.putAgroBusinessAccountError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
  }
};

const deleteAgroBusinessAccounts = async (props: CrudProps): Promise<void> => {
  const { headers, values } = props;
  const selectedABAs = values as AgroBusinessAccount[];
  return Promise.all(
    selectedABAs.map((aba: AgroBusinessAccount) => {
      //TODO: if(aba.id === 0) return Promise.resolve()
      const agrobusinessId = aba.agroBusiness?.id;
      return fetch(
        `${process.env.REACT_APP_API_URL}/agroBusiness/${agrobusinessId}/disable`,
        {
          method: "PUT",
          headers,
        }
      );
    })
  ).then((response: Response[]) =>
    onDeleteAgroBusinessAccountResponse({
      response: response[0],
      crudProps: props,
    })
  );
};

const onDeleteAgroBusinessAccountResponse = async (
  props: CrudResponseProps
): Promise<void> => {
  const { response, crudProps } = props;
  const { queryClient } = crudProps;
  const data = await getResponseData(response);
  if (response.status === 200) {
    // Update list
    queryClient.refetchQueries({
      queryKey: ["agroBusinessAccounts"],
      type: "active",
    });
  } else {
    throw new Error(i18n.t("apiResponses.deleteAgroBusinessAccountsError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
  }
};

const putAgroBusinessAccountPermission = async (
  props: CrudProps
): Promise<void> => {
  const { headers, values } = props;
  const agroBusinessAccountPerson = values as AgroBusinessAccountPerson;
  const agroBusinessAccountId =
    agroBusinessAccountPerson?.selectedAgroBusinessAccount?.id;
  const email = agroBusinessAccountPerson?.person?.email;
  const role = agroBusinessAccountPerson?.personRole?.role;
  const whatsappEnabled = agroBusinessAccountPerson?.whatsappEnabled;
  const whatsappNumber =
    agroBusinessAccountPerson?.person?.whatsappNumber?.replace(/[+\s]/g, "");
  const url = `${
    process.env.REACT_APP_API_URL
  }/agroBusinessAccount/${agroBusinessAccountId}/role?email=${email}&role=${role}&whatsappEnabled=${whatsappEnabled}${
    whatsappEnabled ? `&whatsappNumber=${whatsappNumber}` : ""
  }`;
  return fetch(url, {
    method: "PUT",
    headers,
  }).then((response: Response) =>
    onSetAgroBusinessAccountPermissionResponse({ response, crudProps: props })
  );
};

const onSetAgroBusinessAccountPermissionResponse = async (
  props: CrudResponseProps
): Promise<void> => {
  const { response, crudProps } = props;
  const { queryClient } = crudProps;
  const data = await getResponseData(response);
  if (response.status === 200) {
    // Update list
    queryClient.refetchQueries({
      queryKey: ["agroBusinessAccountPermissions"],
      type: "active",
    });
  } else {
    const exceptionClassName = data?.exceptionClassName;
    const errorMessage = data?.message;
    let errorText = i18n.t(
      "apiResponses.putAgroBusinessAccountPermissionErrorOther"
    );
    if (errorMessage?.includes("is already assigned"))
      errorText = i18n.t(
        "apiResponses.putAgroBusinessAccountPermissionErrorPhone"
      );
    else if (
      errorMessage?.includes("email") &&
      errorMessage?.includes("is already enabled")
    )
      errorText = i18n.t(
        "apiResponses.putAgroBusinessAccountPermissionErrorEmail"
      );
    const errorData = { ...data, exceptionClassName: `_${exceptionClassName}` };
    throw new Error(errorText, {
      cause: { status: response.status, errorData } as ErrorCause,
    });
  }
};

const resetRecentlyTransferedFlagForCueAccountId = async (
  props: CrudProps
): Promise<void> => {
  const { headers, selectedCueAccount } = props;
  const cueAccountId = selectedCueAccount?.cueAccount?.id;
  return fetch(
    `${process.env.REACT_APP_API_URL}/agroBusinessAccount/resetRecentlyTransferedFlagForCueAccountId/${cueAccountId}`,
    {
      method: "PUT",
      headers,
    }
  ).then((response: Response) =>
    onResetRecentlyTransferedFlagForCueAccountIdResponse({
      response,
      crudProps: props,
    })
  );
};

const onResetRecentlyTransferedFlagForCueAccountIdResponse = async (
  props: CrudResponseProps
): Promise<void> => {
  const { response, crudProps } = props;
  const { queryClient } = crudProps;
  if (response.status === 200) {
    // Update list
    queryClient.refetchQueries({
      queryKey: ["agroBusinessAccounts"],
      type: "active",
    });
  }
  return Promise.resolve();
};

const deleteAgroBusinessAccountPermissions = async (
  props: CrudProps
): Promise<void> => {
  const { headers, values } = props;
  const agroBusinessAccountPersons = values as AgroBusinessAccountPerson[];
  const agroBusinessAccountId =
    agroBusinessAccountPersons[0]?.selectedAgroBusinessAccount?.id; // The same for all values
  return Promise.all(
    agroBusinessAccountPersons.map((abap: AgroBusinessAccountPerson) => {
      const email = abap?.person?.email;
      return fetch(
        `${process.env.REACT_APP_API_URL}/agroBusinessAccount/${agroBusinessAccountId}/role?email=${email}`,
        {
          method: "DELETE",
          headers,
        }
      );
    })
  ).then((response: Response[]) =>
    onDeleteAgroBusinessAccountRolePermissionsResponse({
      response: response[0],
      crudProps: props,
    })
  );
};

//TODO: Check all responses?
const onDeleteAgroBusinessAccountRolePermissionsResponse = async (
  props: CrudResponseProps
): Promise<void> => {
  const { response, crudProps } = props;
  const { queryClient } = crudProps;
  const data = await getResponseData(response);
  if (response.status === 200) {
    // Update list
    queryClient.refetchQueries({
      queryKey: ["agroBusinessAccountPermissions"],
      type: "active",
    });
  } else {
    throw new Error(
      i18n.t("apiResponses.deleteAgroBusinessAccountPermissionsError"),
      {
        cause: { status: response.status, data } as ErrorCause,
      }
    );
  }
};

const putCueAccountPermission = async (props: CrudProps): Promise<void> => {
  const { headers, values, selectedCueAccount } = props;
  const cueAccountId = selectedCueAccount?.cueAccount?.id;
  const agroBusinessAccountPerson = values as AgroBusinessAccountPerson;
  const email = agroBusinessAccountPerson?.person?.email;
  const role = agroBusinessAccountPerson?.personRole?.role;
  return fetch(
    `${process.env.REACT_APP_API_URL}/cueAccount/${cueAccountId}/role?email=${email}&role=${role}`,
    {
      method: "PUT",
      headers,
    }
  ).then((response: Response) =>
    onSetCueAccountPermissionResponse({ response, crudProps: props })
  );
};

const onSetCueAccountPermissionResponse = async (
  props: CrudResponseProps
): Promise<void> => {
  const { response, crudProps } = props;
  const { queryClient } = crudProps;
  const data = await getResponseData(response);
  if (response.status === 200) {
    // Update list
    queryClient.refetchQueries({
      queryKey: ["cueAccountPermissions"],
      type: "active",
    });
  } else {
    throw new Error(i18n.t("apiResponses.putCueAccountPermissionError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
  }
};

const deleteCueAccountPermissions = async (props: CrudProps): Promise<void> => {
  const { headers, values, selectedCueAccount } = props;
  const cueAccountId = selectedCueAccount?.cueAccount?.id;
  const agroBusinessAccountPersons = values as AgroBusinessAccountPerson[];
  return Promise.all(
    agroBusinessAccountPersons.map((abap: AgroBusinessAccountPerson) => {
      const email = abap?.person?.email;
      return fetch(
        `${process.env.REACT_APP_API_URL}/cueAccount/${cueAccountId}/role?email=${email}`,
        {
          method: "DELETE",
          headers,
        }
      );
    })
  ).then((response: Response[]) =>
    onDeleteCueAccountRolePermissionsResponse({
      response: response[0],
      crudProps: props,
    })
  );
};

//TODO: Check all responses?
const onDeleteCueAccountRolePermissionsResponse = async (
  props: CrudResponseProps
): Promise<void> => {
  const { response, crudProps } = props;
  const { queryClient } = crudProps;
  const data = await getResponseData(response);
  if (response.status === 200) {
    // Update list
    queryClient.refetchQueries({
      queryKey: ["cueAccountPermissions"],
      type: "active",
    });
  } else {
    throw new Error(i18n.t("apiResponses.deleteCueAccountPermissionsError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
  }
};

/**
 * ADVISOR NOTES CRUDs
 */

const postAdvisorNote = async (
  props: CrudProps
): Promise<AdvisorNote | undefined> => {
  const { headers, values, selectedCueAccount } = props;
  const advisorNote = values as AdvisorNote;
  const cueAccountId = selectedCueAccount?.cueAccount?.id;
  return fetch(
    `${process.env.REACT_APP_API_URL}/cueAccount/${cueAccountId}/advisorNote`,
    {
      method: "POST",
      headers,
      body: JSON.stringify(advisorNote.mapToApiModel()),
    }
  ).then((response: Response) =>
    onPostAdvisorNoteResponse({ response, crudProps: props })
  );
};

const onPostAdvisorNoteResponse = async (
  props: CrudResponseProps
): Promise<AdvisorNote | undefined> => {
  const { response, crudProps } = props;
  const { queryClient } = crudProps;
  const data = await getResponseData(response);
  if (response.status === 200) {
    // Update list
    queryClient.refetchQueries({
      queryKey: ["advisorNotes"],
      type: "active",
    });
    return new AdvisorNote(data);
  } else {
    throw new Error(i18n.t("apiResponses.postAdvisorNoteError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
  }
};

const putAdvisorNote = async (
  props: CrudProps
): Promise<AdvisorNote | undefined> => {
  const { headers, values, selectedCueAccount } = props;
  const advisorNote = values as AdvisorNote;
  const advisorNoteId = advisorNote?.id;
  const cueAccountId = selectedCueAccount?.cueAccount?.id;
  return fetch(
    `${process.env.REACT_APP_API_URL}/cueAccount/${cueAccountId}/advisorNote/${advisorNoteId}`,
    {
      method: "PUT",
      headers,
      body: JSON.stringify(advisorNote.mapToApiModel()),
    }
  ).then((response: Response) =>
    onPutAdvisorNoteResponse({ response, crudProps: props })
  );
};

const onPutAdvisorNoteResponse = async (
  props: CrudResponseProps
): Promise<AdvisorNote | undefined> => {
  const { response, crudProps } = props;
  const { queryClient } = crudProps;
  const data = await getResponseData(response);
  if (response.status === 200) {
    // Update list
    queryClient.refetchQueries({
      queryKey: ["advisorNotes"],
      type: "active",
    });
    return new AdvisorNote(data);
  } else {
    throw new Error(i18n.t("apiResponses.putAdvisorNoteError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
  }
};

const deleteAdvisorNotes = async (props: CrudProps): Promise<void> => {
  const { headers, values, selectedCueAccount } = props;
  const cueAccountId = selectedCueAccount?.cueAccount?.id;
  const selectedAdvisorNotes = values as AdvisorNote[];
  return Promise.all(
    selectedAdvisorNotes.map((advisorNote: AdvisorNote) => {
      const advisorNoteId = advisorNote?.id;
      return fetch(
        `${process.env.REACT_APP_API_URL}/cueAccount/${cueAccountId}/advisorNote/${advisorNoteId}/disable`,
        {
          method: "PUT",
          headers,
        }
      );
    })
  ).then((response: Response[]) =>
    onDeleteAdvisorNotesResponse({
      response: response[0],
      crudProps: props,
    })
  );
};

const onDeleteAdvisorNotesResponse = async (
  props: CrudResponseProps
): Promise<void> => {
  const { response, crudProps } = props;
  const { queryClient } = crudProps;
  const data = await getResponseData(response);
  if (response.status === 200) {
    // Update list
    queryClient.refetchQueries({
      queryKey: ["advisorNotes"],
      type: "active",
    });
  } else {
    throw new Error(i18n.t("apiResponses.deleteAdvisorNotesError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
  }
};

const putPersonLanguage = async (
  props: CrudProps
): Promise<Person | undefined> => {
  const { headers, values } = props;
  const languageCode = values as string;
  return fetch(
    `${process.env.REACT_APP_API_URL}/person/language?language=${languageCode}`,
    {
      method: "PUT",
      headers,
    }
  ).then(onPutPersonLanguageResponse);
};

const onPutPersonLanguageResponse = async (
  response: Response
): Promise<Person | undefined> => {
  const data = await getResponseData(response);
  if (response.status === 200) {
    return new Person(data);
  } else {
    throw new Error(i18n.t("apiResponses.updatePersonLanguageError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
  }
};

const changePhytoRecipeStatus = async (
  props: CrudProps
): Promise<PhytoRecipe | undefined> => {
  const { headers, values } = props;
  const statusValues = values as ChangePhytoRecipeStatusInterface;
  const agroBusinessId = statusValues.agroBusinessId;
  const phytoRecipeId = statusValues.id;
  const status = statusValues.status;

  return fetch(
    `${process.env.REACT_APP_API_URL}/agroBusiness/${agroBusinessId}/phytoRecipe/${phytoRecipeId}/changeStatus`,
    {
      method: "PUT",
      headers,
      body: JSON.stringify({ status }),
    }
  ).then(onChangePhytoRecipeStatusResponse);
};

const onChangePhytoRecipeStatusResponse = async (
  response: Response
): Promise<PhytoRecipe | undefined> => {
  const data = await getResponseData(response);
  if (response.status === 200) {
    return new PhytoRecipe(data);
  } else {
    throw new Error(i18n.t("apiResponses.changePhytoRecipeStatusError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
  }
};

const postPhytoRecipeObj = async (
  props: CrudProps,
  formattedValues: any
): Promise<PhytoRecipe | undefined> => {
  const { headers, values } = props;
  const agroBusinessId = values?.agroBusinessId;

  return fetch(
    `${process.env.REACT_APP_API_URL}/agroBusiness/${agroBusinessId}/phytoRecipe`,
    {
      method: "POST",
      headers,
      body: JSON.stringify(formattedValues),
    }
  ).then(onPostPhytoRecipeObjResponse);
};

const onPostPhytoRecipeObjResponse = async (
  response: Response
): Promise<PhytoRecipe | undefined> => {
  const data = await getResponseData(response);
  if (response.status === 200) {
    return new PhytoRecipe(data);
  } else if (data?.message?.includes("is not a phyto advisor")) {
    throw new Error(
      i18n.t("apiResponses.postPhytoRecipeNotPhytoAdvisorError"),
      {
        cause: { status: response.status, data } as ErrorCause,
      }
    );
  } else {
    throw new Error(i18n.t("apiResponses.postPhytoRecipeError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
  }
};

const putPhytoRecipeObj = async (
  props: CrudProps,
  formattedValues: any
): Promise<PhytoRecipe | undefined> => {
  const { headers, values } = props;
  const agroBusinessId = values?.agroBusinessId;
  const recipe = values?.values as PhytoRecipe;
  const recipeId = recipe?.id;
  return fetch(
    `${process.env.REACT_APP_API_URL}/agroBusiness/${agroBusinessId}/phytoRecipe/${recipeId}`,
    {
      method: "PUT",
      headers,
      body: JSON.stringify(formattedValues),
    }
  ).then(onPutPhytoRecipeObjResponse);
};

const onPutPhytoRecipeObjResponse = async (
  response: Response
): Promise<PhytoRecipe | undefined> => {
  const data = await getResponseData(response);
  if (response.status === 200) {
    return new PhytoRecipe(data);
  } else if (data?.message?.includes("is not a phyto advisor")) {
    throw new Error(
      i18n.t("apiResponses.postPhytoRecipeNotPhytoAdvisorError"),
      {
        cause: { status: response.status, data } as ErrorCause,
      }
    );
  } else {
    throw new Error(i18n.t("apiResponses.putPhytoRecipeError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
  }
};

const postPhytoRecipe = async (
  props: CrudProps
): Promise<PhytoRecipe | undefined> => {
  const { values } = props;
  const recipeData = values?.values as PhytoRecipe;

  const recipe = await postPhytoRecipeObj(props, recipeData.mapToApiModel());
  //TODO:if (recipe && recipe.id && recipeData.documents)
  /*   if (recipe && recipe.id)
    await attachDocuments({
      crudProps: props,
      //TODO:documents: recipeData.documents,
      documents: [],
      entityClassName: "FBPhytoRecipe",
      entityClassId: recipe.id,
      documentDate: recipe.date,
    }).catch(() => {
      throw FILE_ERROR;
    }); */
  return recipe;
};

const putPhytoRecipe = async (
  props: CrudProps
): Promise<PhytoRecipe | undefined> => {
  const { values } = props;
  const recipeData = values?.values as PhytoRecipe;

  const recipe = await putPhytoRecipeObj(props, recipeData.mapToApiModel());

  //TODO:if (recipe && recipe.id && recipeData.documents)
  /*   if (recipe && recipe.id)
    await attachDocuments({
      crudProps: props,
      //TODO: documents: recipeData.documents,
      documents: [],
      entityClassName: "FBPhytoRecipe",
      entityClassId: recipe.id,
      documentDate: recipe.date,
    }).catch(() => {
      throw FILE_ERROR;
    }); */

  /* TODO:  const uncategorizedDocuments = recipeData.lastSavedDocuments?.filter(
    (document: Document) =>
      !recipeData.documents?.find((d) => d.id === document.id)
  );
  if (uncategorizedDocuments)
    await dettachDocuments({
      crudProps: props,
      documents: uncategorizedDocuments,
    }).catch(() => {}); */

  return recipe;
};

const deletePhytoRecipe = async (props: CrudProps): Promise<void> => {
  const { headers, values } = props;
  const agroBusinessId = values?.agroBusinessId;
  const selectedRecipe = values?.values as PhytoRecipe;
  const recipeId = selectedRecipe?.id;
  return fetch(
    `${process.env.REACT_APP_API_URL}/agroBusiness/${agroBusinessId}/phytoRecipe/${recipeId}/disable`,
    {
      method: "PUT",
      headers,
    }
  ).then(onDeletePhytoRecipeResponse);
};

const onDeletePhytoRecipeResponse = async (
  response: Response
): Promise<void> => {
  const data = await getResponseData(response);
  if (response.status !== 200) {
    throw new Error(i18n.t("apiResponses.deletePhytoRecipeError"), {
      cause: { status: response.status, data } as ErrorCause,
    });
  }
};

const markNotificationAsRead = async (
  props: CrudProps
): Promise<Notification | undefined> => {
  const { headers, values } = props;
  const notification = values as Notification;
  const notificationId = notification?.id;
  return fetch(
    `${process.env.REACT_APP_API_URL}/notification/${notificationId}/read`,
    {
      method: "PUT",
      headers,
    }
  ).then(onMarkNotificationAsReadResponse);
};

const onMarkNotificationAsReadResponse = async (
  response: Response
): Promise<Notification | undefined> => {
  const data = await getResponseData(response);
  if (response.status === 200) {
    return new Notification(data);
  } else return undefined;
};

const executeSiexSync = async (props: CrudProps): Promise<void> => {
  const { headers, values, selectedCueAccount } = props;
  const cueAccountId = selectedCueAccount?.cueAccount?.id;
  const siexPendingItems = values as SiexRequestItem[];
  const siexPendingItemsIds = siexPendingItems.map((item) => item.request?.id);

  return fetch(
    `${process.env.REACT_APP_API_URL}/cueAccount/${cueAccountId}/siex/validateSiexRequestList`,
    {
      method: "POST",
      headers,
      body: JSON.stringify(siexPendingItemsIds),
    }
  ).then(onExecuteSiexSyncResponse);
};

const onExecuteSiexSyncResponse = async (response: Response): Promise<void> => {
  if (response.status !== 200) {
    throw new Error(i18n.t("apiResponses.executeSiexSyncError"), {
      cause: { status: response.status, data: {} } as ErrorCause,
    });
  }
};
