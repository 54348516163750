import moment from "moment";

import FBPhytosanitaryProduct from "../_api/phyto/FBPhytosanitaryProduct";
import Document from "../files/Document";

export const MEASUREMENT_UNIT_CODES = [
  "3",
  "4",
  "5",
  "6",
  "11",
  "25",
  "26",
  "27",
];

class PhytosanitaryProduct extends FBPhytosanitaryProduct {
  isExpired?: boolean;
  expirationDateFormatted?: string;
  documents?: Document[];
  lastSavedDocuments?: Document[];

  constructor(obj?: any) {
    super(obj);
    this.isExpired = obj?.expirationDate
      ? moment(obj.expirationDate, "YYYY-MM-DD").isBefore(moment())
      : false;
    if (obj?.expirationDate)
      this.expirationDateFormatted = moment(
        obj.expirationDate,
        "YYYY-MM-DD"
      ).format("DD/MM/YYYY");
    this.documents = obj?.documents?.map((doc: any) => new Document(doc));
    this.lastSavedDocuments = this.documents;
  }

  mapToApiModel(): any {
    const obj: any = super.mapBaseToApiModel();
    // Name is always required
    if (this.isRegistered())
      obj.name = this.registeredPhytosanitaryProduct?.name;
    // Remove custom variables
    delete obj.isExpired;
    delete obj.expirationDateFormatted;
    delete obj.documents;
    delete obj.lastSavedDocuments;
    return obj;
  }

  isRegistered(): boolean {
    return this.type?.code === "1";
  }
}

export default PhytosanitaryProduct;
