import React, { useState, useEffect, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import i18n from "../config/configI18n";

import {
  Autocomplete,
  Button,
  Drawer,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from "@mui/material";
import { ArrowBack, QuestionMark, Receipt, Search } from "@mui/icons-material";

import ScreenContentLayout from "../components/ScreenContentLayout";
import TestBanner from "../components/banners/TestBanner";
import AlertSnackbar from "../components/elements/AlertSnackbar";
import LoadingWithDelay from "../components/elements/LoadingWithDelay";
import EmptyTable from "../components/EmptyTable";

import RegisteredPhytosanitaryProduct from "../models/vademecum/RegisteredPhytosanitaryProduct";
import VadeAgent from "../models/vademecum/VadeAgent";
import VadeSubstance from "../models/vademecum/VadeSubstance";
import VadeCrop from "../models/vademecum/VadeCrop";
import AgroBusinessAccount from "../models/account/AgroBusinessAccount";
import VadeProductUse from "../models/vademecum/VadeProductUse";
import LegalContext from "../models/LegalContext";

import useFetch from "../hooks/useFetch";
import { useSession } from "../hooks/useSession";

import { getFieldValueByColumnNotation, uniqueById } from "../helpers/utils";
import {
  Column,
  SearchFieldInterface,
  SnackbarInterface,
} from "../constants/interfaces";
import { SEARCH_LIKE_TIMEOUT_DELAY_MS } from "../constants/constants";
import { PROTECTED_ROUTES } from "../routes/routeNames";

const VademecumPhytoScreen = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [snackbarMsg, setSnackbarMsg] = useState<SnackbarInterface | null>(
    null
  );
  const [selectedProduct, setSelectedProduct] =
    useState<RegisteredPhytosanitaryProduct | null>(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);

  // Open drawer of files form if url contains respective path
  useEffect(() => {
    setIsDrawerOpen(location.pathname.includes("/edit"));
  }, [location.pathname]);

  const openDrawer = () => {
    navigate(`${location.pathname}/edit`);
  };
  const closeDrawer = () => {
    setIsDrawerOpen(false);
    setTimeout(() => {
      setSelectedProduct(null);
      navigate(-1);
    }, 500);
  };

  const handleClickProduct = (
    product: RegisteredPhytosanitaryProduct | null
  ) => {
    setSelectedProduct(product);
    openDrawer();
  };

  return (
    <ScreenContentLayout>
      <TestBanner />
      <AlertSnackbar
        open={!!snackbarMsg}
        snackbarMsg={snackbarMsg}
        onClose={() => setSnackbarMsg(null)}
      />
      <VademecumTable onClick={handleClickProduct} onError={setSnackbarMsg} />
      <VadeProductView
        isOpen={isDrawerOpen}
        selected={selectedProduct}
        onClose={closeDrawer}
        onError={setSnackbarMsg}
      />
    </ScreenContentLayout>
  );
};

export default VademecumPhytoScreen;

interface VademecumTableProps {
  onClick?: (product: RegisteredPhytosanitaryProduct | null) => void;
  onError?: (snackBarError: SnackbarInterface) => void;
}
const VademecumTable = (props: VademecumTableProps) => {
  const { onClick, onError } = props;

  const columns: Column[] = [
    {
      id: "name",
      label: i18n.t("vademecum.phyto.columns.name"),
      numeric: false,
    },
    {
      id: "formulation",
      label: i18n.t("vademecum.phyto.columns.formulation"),
      numeric: false,
    },
    {
      id: "manufacturer",
      label: i18n.t("vademecum.phyto.columns.manufacturer"),
      numeric: false,
    },
    {
      id: "expirationDateFormatted",
      label: i18n.t("vademecum.phyto.columns.expirationDate"),
      numeric: false,
    },
  ];

  const { selectedCueAccount } = useSession();

  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [selectedABA, setSelectedABA] = useState<AgroBusinessAccount | null>(
    null
  );
  const [agentSearch, setAgentSearch] = useState<
    SearchFieldInterface<VadeAgent>
  >({ searchWord: "", selected: null, context: null });
  const [substanceSearch, setSubstanceSearch] = useState<
    SearchFieldInterface<VadeSubstance>
  >({ searchWord: "", selected: null, context: null });
  const [cropSearch, setCropSearch] = useState<SearchFieldInterface<VadeCrop>>({
    searchWord: "",
    selected: null,
    context: null,
  });
  const [productSearch, setProductSearch] = useState<
    SearchFieldInterface<RegisteredPhytosanitaryProduct>
  >({
    searchWord: "",
    selected: null,
    context: null,
  });
  const [ownerSearch, setOwnerSearch] = useState<
    SearchFieldInterface<RegisteredPhytosanitaryProduct>
  >({
    searchWord: "",
    selected: null,
    context: null,
  });
  const [formulationSearch, setFormulationSearch] = useState<
    SearchFieldInterface<RegisteredPhytosanitaryProduct>
  >({
    searchWord: "",
    selected: null,
    context: null,
  });

  const handleAgroBusinessAccountsResponse = (
    agroBusinessAccounts: AgroBusinessAccount[]
  ) => {
    const context =
      agroBusinessAccounts[0]?.context || new LegalContext({ id: 1 });
    const selectedAccount =
      agroBusinessAccounts[0] || new AgroBusinessAccount({ context });
    setSelectedABA(selectedAccount);
    setProductSearch((state) => ({ ...state, context }));
    setAgentSearch((state) => ({ ...state, context }));
    setSubstanceSearch((state) => ({ ...state, context }));
    setCropSearch((state) => ({ ...state, context }));
  };

  // Needed for get an context id
  const { isFetching: isFetchingABAs } = useFetch<AgroBusinessAccount[]>({
    queryKey: ["agroBusinessAccounts", selectedCueAccount?.cueAccount?.id],
    onSuccess: handleAgroBusinessAccountsResponse,
    enabled: !!selectedCueAccount,
  });

  const { data: products, isFetching: isFetchingProducts } = useFetch<
    RegisteredPhytosanitaryProduct[]
  >({
    queryKey: [
      "vadePhytoProducts",
      selectedABA?.context?.id,
      productSearch.searchWord,
      ownerSearch.searchWord,
      formulationSearch.searchWord,
      productSearch.selected,
      agentSearch.selected,
      substanceSearch.selected,
      cropSearch.selected,
    ],
    selected: {
      productSearch,
      ownerSearch,
      formulationSearch,
      agentSearch,
      substanceSearch,
      cropSearch,
    },
    enabled:
      !!selectedABA?.context?.id &&
      (productSearch.searchWord.length > 0 ||
        ownerSearch.searchWord.length > 0 ||
        formulationSearch.searchWord.length > 0 ||
        !!productSearch.selected ||
        !!agentSearch.selected ||
        !!substanceSearch.selected ||
        !!cropSearch.selected),
    onError,
  });

  const { data: agents, isFetching: isFetchingAgents } = useFetch<VadeAgent[]>({
    queryKey: ["vadeAgents", selectedABA?.context?.id, agentSearch.searchWord],
    selected: agentSearch,
    enabled: !!selectedABA?.context?.id && agentSearch.searchWord.length > 0,
    onError,
  });

  const { data: substances, isFetching: isFetchingSubstances } = useFetch<
    VadeSubstance[]
  >({
    queryKey: [
      "vadeSubstances",
      selectedABA?.context?.id,
      substanceSearch.searchWord,
    ],
    selected: substanceSearch,
    enabled:
      !!selectedABA?.context?.id && substanceSearch.searchWord.length > 0,
    onError,
  });

  const { data: crops, isFetching: isFetchingCrops } = useFetch<VadeCrop[]>({
    queryKey: ["vadeCrops", selectedABA?.context?.id, cropSearch.searchWord],
    selected: cropSearch,
    enabled: !!selectedABA?.context?.id && cropSearch.searchWord.length > 0,
    onError,
  });

  const notFilters: boolean = useMemo(
    () =>
      productSearch.searchWord.length === 0 &&
      !productSearch.selected &&
      ownerSearch.searchWord.length === 0 &&
      !ownerSearch.selected &&
      formulationSearch.searchWord.length === 0 &&
      !formulationSearch.selected &&
      !agentSearch.selected &&
      !substanceSearch.selected &&
      !cropSearch.selected,
    [
      productSearch,
      ownerSearch,
      formulationSearch,
      agentSearch,
      substanceSearch,
      cropSearch,
    ]
  );

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const visibleRows: RegisteredPhytosanitaryProduct[] = useMemo(
    () =>
      products
        ? products.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        : [],
    [products, page, rowsPerPage]
  );

  const handleChangeProductSearchWord = (value: string) => {
    setProductSearch((state) => ({ ...state, searchWord: value }));
  };

  const handleChangeProductOwnerSearchWord = (value: string) => {
    setOwnerSearch((state) => ({ ...state, searchWord: value }));
  };

  const handleChangeProductFormulationSearchWord = (value: string) => {
    setFormulationSearch((state) => ({ ...state, searchWord: value }));
  };

  const handleChangeAgentSearchWord = (value: string) => {
    setAgentSearch((state) => ({ ...state, searchWord: value }));
  };

  const handleChangeSubstanceSearchWord = (value: string) => {
    setSubstanceSearch((state) => ({ ...state, searchWord: value }));
  };

  const handleChangeCropSearchWord = (value: string) => {
    setCropSearch((state) => ({ ...state, searchWord: value }));
  };

  const handleChangeAgent = (value: VadeAgent | null) => {
    setAgentSearch((state) => ({ ...state, selected: value }));
  };

  const handleChangeSubstance = (value: VadeSubstance | null) => {
    setSubstanceSearch((state) => ({ ...state, selected: value }));
  };

  const handleChangeCrop = (value: VadeCrop | null) => {
    setCropSearch((state) => ({ ...state, selected: value }));
  };

  const handleClickRow = (product: RegisteredPhytosanitaryProduct) => {
    onClick && onClick(product);
  };

  return (
    <div className="vademecum-phyto-table">
      <LoadingWithDelay isVisible={isFetchingABAs || isFetchingProducts} />
      <div className="header">
        <h1>{i18n.t("vademecum.phyto.title")}</h1>
      </div>
      <div className="content">
        <div className="content-table-filters">
          <VadeProductFilter
            onChangeSearchWord={handleChangeProductSearchWord}
          />
          <VadeProductOwnerFilter
            onChangeSearchWord={handleChangeProductOwnerSearchWord}
          />
          <VadeProductFormulationFilter
            onChangeSearchWord={handleChangeProductFormulationSearchWord}
          />
          <VadeAgentFilter
            agents={agents || []}
            selected={agentSearch.selected}
            isLoading={isFetchingAgents}
            onChangeSearchWord={handleChangeAgentSearchWord}
            onChange={handleChangeAgent}
          />
          <VadeSubstanceFilter
            substances={substances || []}
            selected={substanceSearch.selected}
            isLoading={isFetchingSubstances}
            onChangeSearchWord={handleChangeSubstanceSearchWord}
            onChange={handleChangeSubstance}
          />
          <VadeCropFilter
            crops={crops || []}
            selected={cropSearch.selected}
            isLoading={isFetchingCrops}
            onChangeSearchWord={handleChangeCropSearchWord}
            onChange={handleChangeCrop}
          />
        </div>
        {visibleRows.length === 0 ? (
          <EmptyTable
            icon={<QuestionMark fontSize="large" />}
            title={
              notFilters
                ? i18n.t("vademecum.phyto.notFiltersTitle")
                : i18n.t("vademecum.phyto.notResultsTitle")
            }
          />
        ) : (
          <TableContainer className="table-container">
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      className="table-cell-head"
                      align={
                        column.align || (column.numeric ? "right" : "left")
                      }
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>

              <TableBody>
                {visibleRows.map((row) => (
                  <TableRow
                    key={row.id}
                    className="table-row"
                    onClick={() => handleClickRow(row)}
                  >
                    {columns.map((column) => (
                      <TableRowItem key={column.id} row={row} column={column} />
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <TablePagination
              className="table-pagination"
              component="div"
              count={products?.length || 0}
              rowsPerPage={rowsPerPage}
              page={page}
              labelRowsPerPage={i18n.t("words.rowsByPage")}
              labelDisplayedRows={({ from, to, count }) =>
                `${from}-${to} / ${count}`
              }
              rowsPerPageOptions={[5, 10, 25]}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableContainer>
        )}
      </div>
    </div>
  );
};

interface TableRowItemProps {
  row: RegisteredPhytosanitaryProduct;
  column: Column;
}
const TableRowItem = (props: TableRowItemProps) => {
  const { row, column } = props;

  const value = getFieldValueByColumnNotation(
    column.id,
    row,
    column.valueLabel,
    column.arrayFieldName,
    column.unitFieldName,
    column.unitValue,
    column.id2
  );
  const align = column.align || (column.numeric ? "right" : "left");

  return (
    <TableCell className="table-cell" align={align}>
      {value}
    </TableCell>
  );
};

interface ProductsFilterProps {
  onChangeSearchWord?: (searchWord: string) => void;
}
let productsSearchWordTimeout: NodeJS.Timeout | null;

const VadeProductFilter = (props: ProductsFilterProps) => {
  const { onChangeSearchWord } = props;

  const handleChangeProductSearchWord = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = e.target.value;
    if (productsSearchWordTimeout) {
      clearTimeout(productsSearchWordTimeout);
      productsSearchWordTimeout = null;
    }
    productsSearchWordTimeout = setTimeout(() => {
      const cleanSearchWord = value.trim();
      const searchWordEncoded = encodeURIComponent(cleanSearchWord);
      onChangeSearchWord && onChangeSearchWord(searchWordEncoded);
    }, SEARCH_LIKE_TIMEOUT_DELAY_MS);
  };

  return (
    <div className="table-filter">
      <TextField
        size="small"
        className="filter-text-field"
        label={i18n.t("vademecum.phyto.productsFilterLabel")}
        placeholder={i18n.t("vademecum.phyto.productsFilterPlaceholder")}
        InputProps={{ startAdornment: <Search /> }}
        onChange={handleChangeProductSearchWord}
      />
    </div>
  );
};

const VadeProductOwnerFilter = (props: ProductsFilterProps) => {
  const { onChangeSearchWord } = props;

  const handleChangeProductSearchWord = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = e.target.value;
    if (productsSearchWordTimeout) {
      clearTimeout(productsSearchWordTimeout);
      productsSearchWordTimeout = null;
    }
    productsSearchWordTimeout = setTimeout(() => {
      const cleanSearchWord = value.trim();
      const searchWordEncoded = encodeURIComponent(cleanSearchWord);
      onChangeSearchWord && onChangeSearchWord(searchWordEncoded);
    }, SEARCH_LIKE_TIMEOUT_DELAY_MS);
  };

  return (
    <div className="table-filter">
      <TextField
        size="small"
        className="filter-text-field"
        label={i18n.t("vademecum.phyto.productOwnerFilterLabel")}
        placeholder={i18n.t("vademecum.phyto.productOwnerFilterPlaceholder")}
        InputProps={{ startAdornment: <Search /> }}
        onChange={handleChangeProductSearchWord}
      />
    </div>
  );
};

const VadeProductFormulationFilter = (props: ProductsFilterProps) => {
  const { onChangeSearchWord } = props;

  const handleChangeProductSearchWord = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = e.target.value;
    if (productsSearchWordTimeout) {
      clearTimeout(productsSearchWordTimeout);
      productsSearchWordTimeout = null;
    }
    productsSearchWordTimeout = setTimeout(() => {
      const cleanSearchWord = value.trim();
      const searchWordEncoded = encodeURIComponent(cleanSearchWord);
      onChangeSearchWord && onChangeSearchWord(searchWordEncoded);
    }, SEARCH_LIKE_TIMEOUT_DELAY_MS);
  };

  return (
    <div className="table-filter">
      <TextField
        size="small"
        className="filter-text-field"
        label={i18n.t("vademecum.phyto.productFormulationFilterLabel")}
        placeholder={i18n.t(
          "vademecum.phyto.productFormulationFilterPlaceholder"
        )}
        InputProps={{ startAdornment: <Search /> }}
        onChange={handleChangeProductSearchWord}
      />
    </div>
  );
};

interface VadeAgentFilterProps {
  agents: VadeAgent[];
  selected: VadeAgent | null;
  isLoading?: boolean;
  onChangeSearchWord?: (searchWord: string) => void;
  onChange?: (agent: VadeAgent | null) => void;
}
let agentsSearchWordTimeout: NodeJS.Timeout | null;

const VadeAgentFilter = (props: VadeAgentFilterProps) => {
  const { agents, isLoading, selected, onChangeSearchWord, onChange } = props;

  const handleChangeAgentSearchWord = (event: any, value: string) => {
    if (agentsSearchWordTimeout) {
      clearTimeout(agentsSearchWordTimeout);
      agentsSearchWordTimeout = null;
    }
    agentsSearchWordTimeout = setTimeout(() => {
      const cleanSearchWord = value.trim();
      const searchWordEncoded = encodeURIComponent(cleanSearchWord);
      onChangeSearchWord && onChangeSearchWord(searchWordEncoded);
    }, SEARCH_LIKE_TIMEOUT_DELAY_MS);
  };

  const handleChangeAgent = (event: any, value: VadeAgent | null | string) => {
    if (typeof value !== "string") onChange && onChange(value);
  };

  return (
    <div className="table-filter">
      <Autocomplete
        id="agents-filter"
        className="filter-text-field"
        options={agents || []}
        getOptionLabel={(option) =>
          option instanceof VadeAgent ? option?.name || "" : option
        }
        noOptionsText={i18n.t("formErrors.notFoundResults")}
        freeSolo
        loading={isLoading}
        value={selected}
        onChange={handleChangeAgent}
        onInputChange={handleChangeAgentSearchWord}
        renderInput={(params) => (
          <TextField
            {...params}
            size="small"
            label={i18n.t("vademecum.phyto.agentsFilterLabel")}
            placeholder={i18n.t("vademecum.phyto.agentsFilterPlaceholder")}
            InputProps={{ ...params.InputProps, startAdornment: <Search /> }}
          />
        )}
      />
    </div>
  );
};

interface VadeSubstanceFilterProps {
  substances: VadeSubstance[];
  selected: VadeSubstance | null;
  isLoading?: boolean;
  onChangeSearchWord?: (searchWord: string) => void;
  onChange?: (substance: VadeSubstance | null) => void;
}
let substancesSearchWordTimeout: NodeJS.Timeout | null;

const VadeSubstanceFilter = (props: VadeSubstanceFilterProps) => {
  const { substances, isLoading, selected, onChangeSearchWord, onChange } =
    props;

  const handleChangeSubstanceSearchWord = (event: any, value: string) => {
    if (substancesSearchWordTimeout) {
      clearTimeout(substancesSearchWordTimeout);
      substancesSearchWordTimeout = null;
    }
    substancesSearchWordTimeout = setTimeout(() => {
      const cleanSearchWord = value.trim();
      const searchWordEncoded = encodeURIComponent(cleanSearchWord);
      onChangeSearchWord && onChangeSearchWord(searchWordEncoded);
    }, SEARCH_LIKE_TIMEOUT_DELAY_MS);
  };

  const handleChangeSubstance = (
    event: any,
    value: VadeSubstance | null | string
  ) => {
    if (typeof value !== "string") onChange && onChange(value);
  };

  return (
    <div className="table-filter">
      <Autocomplete
        id="substances-filter"
        className="filter-text-field"
        options={substances || []}
        getOptionLabel={(option) =>
          option instanceof VadeSubstance ? option?.name || "" : option
        }
        noOptionsText={i18n.t("formErrors.notFoundResults")}
        freeSolo
        loading={isLoading}
        value={selected}
        onChange={handleChangeSubstance}
        onInputChange={handleChangeSubstanceSearchWord}
        renderInput={(params) => (
          <TextField
            {...params}
            size="small"
            label={i18n.t("vademecum.phyto.substancesFilterLabel")}
            placeholder={i18n.t("vademecum.phyto.substancesFilterPlaceholder")}
            InputProps={{ ...params.InputProps, startAdornment: <Search /> }}
          />
        )}
      />
    </div>
  );
};

interface VadeCropFilterProps {
  crops: VadeCrop[];
  selected: VadeCrop | null;
  isLoading?: boolean;
  onChangeSearchWord?: (searchWord: string) => void;
  onChange?: (substance: VadeCrop | null) => void;
}
let cropsSearchWordTimeout: NodeJS.Timeout | null;

const VadeCropFilter = (props: VadeCropFilterProps) => {
  const { crops, isLoading, selected, onChangeSearchWord, onChange } = props;

  const handleChangeCropSearchWord = (event: any, value: string) => {
    if (cropsSearchWordTimeout) {
      clearTimeout(cropsSearchWordTimeout);
      cropsSearchWordTimeout = null;
    }
    cropsSearchWordTimeout = setTimeout(() => {
      const cleanSearchWord = value.trim();
      const searchWordEncoded = encodeURIComponent(cleanSearchWord);
      onChangeSearchWord && onChangeSearchWord(searchWordEncoded);
    }, SEARCH_LIKE_TIMEOUT_DELAY_MS);
  };

  const handleChangeCrop = (event: any, value: VadeCrop | null | string) => {
    if (typeof value !== "string") onChange && onChange(value);
  };

  return (
    <div className="table-filter">
      <Autocomplete
        id="crops-filter"
        className="filter-text-field"
        options={crops || []}
        getOptionLabel={(option) =>
          option instanceof VadeCrop ? option?.name || "" : option
        }
        noOptionsText={i18n.t("formErrors.notFoundResults")}
        freeSolo
        loading={isLoading}
        value={selected}
        onChange={handleChangeCrop}
        onInputChange={handleChangeCropSearchWord}
        renderInput={(params) => (
          <TextField
            {...params}
            size="small"
            label={i18n.t("vademecum.phyto.cropsFilterLabel")}
            placeholder={i18n.t("vademecum.phyto.cropsFilterPlaceholder")}
            InputProps={{ ...params.InputProps, startAdornment: <Search /> }}
          />
        )}
      />
    </div>
  );
};

interface VadeProductViewProps {
  isOpen: boolean;
  selected: RegisteredPhytosanitaryProduct | null;
  onClose: () => void;
  onError?: (snackBarError: SnackbarInterface) => void;
}
const VadeProductView = (props: VadeProductViewProps) => {
  const { isOpen, selected, onClose, onError } = props;

  const navigate = useNavigate();

  const [product, setProduct] = useState<RegisteredPhytosanitaryProduct | null>(
    null
  );

  const { isFetching: isUpdating } = useFetch<RegisteredPhytosanitaryProduct>({
    queryKey: ["registeredPhytoProductById", selected?.id],
    selected,
    onSuccess: setProduct,
    enabled: !!selected?.id,
    onError,
  });

  const handleCreateRecipe = () => {
    if (product !== null) {
      navigate(`/${PROTECTED_ROUTES.EXPLOITATION_RECIPES}`);
      navigate(`/${PROTECTED_ROUTES.EXPLOITATION_RECIPES}/0`, {
        state: { product: product },
      });
    }
  };

  if (!selected) return null;

  return (
    <Drawer
      open={isOpen}
      anchor="right"
      className="vademecum-phyto-drawer"
      PaperProps={{ className: "vademecum-phyto-drawer" }}
    >
      <div className="container">
        <div className="header">
          <IconButton onClick={onClose}>
            <ArrowBack fontSize="large" color="action" />
          </IconButton>
          <div className="header-titles">
            <h1>{product?.name}</h1>
            <h2>{product?.otherNamesAsString}</h2>
          </div>
          <div className="header-actions">
            <Button
              variant="contained"
              startIcon={<Receipt style={{ color: "white" }} />}
              onClick={handleCreateRecipe}
            >
              {i18n.t("vademecum.phyto.productView.createRecipeBtnLabel")}
            </Button>
          </div>
        </div>

        {isUpdating ? (
          <LoadingWithDelay isVisible />
        ) : (
          <div className="content">
            <StatusSection product={product} />
            <ApplicationSection product={product} />
            <CompositionSection product={product} />
            <ProviderSection product={product} />
            <UseCasesSection product={product} />
          </div>
        )}
      </div>
    </Drawer>
  );
};

interface SectionProps {
  product: RegisteredPhytosanitaryProduct | null;
}
export const StatusSection = (props: SectionProps) => {
  const { product } = props;

  return (
    <React.Fragment>
      <div className="section-header">
        <h3>{i18n.t("vademecum.phyto.productView.statusSectionTitle")}</h3>
        <p
          className={
            product?.isRegisterCancelled ? "error-text" : "success-text"
          }
        >
          {product?.registerStatus}
        </p>
      </div>
      <div className="content-row">
        {product?.registrationDate && (
          <div className="content-row-item">
            <h4>
              {i18n.t("vademecum.phyto.productView.registrationDateLabel")}
            </h4>
            <p>{product?.registrationDateFormatted}</p>
          </div>
        )}
        {product?.expirationDate && (
          <div className="content-row-item">
            <h4>{i18n.t("vademecum.phyto.productView.expirationDateLabel")}</h4>
            <p className={product?.isExpired ? "error-text" : "success-text"}>
              {product?.expirationDateFormatted}
            </p>
          </div>
        )}
        {product?.cancellationDate && (
          <div className="content-row-item">
            <h4>
              {i18n.t("vademecum.phyto.productView.cancellationDateLabel")}
            </h4>
            <p>{product?.cancellationDateFormatted}</p>
          </div>
        )}
        {product?.saleDeadlineDate && (
          <div className="content-row-item">
            <h4>
              {i18n.t("vademecum.phyto.productView.saleDeadlineDateLabel")}
            </h4>
            <p>{product?.saleDeadlineDateFormatted}</p>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export const ApplicationSection = (props: SectionProps) => {
  const { product } = props;

  return (
    <React.Fragment>
      <div className="section-header">
        <h3>{i18n.t("vademecum.phyto.productView.applicationSectionTitle")}</h3>
      </div>
      <div className="content-row">
        <div className="content-row-item">
          <p className="large-text">{product?.conditions}</p>
        </div>
      </div>
    </React.Fragment>
  );
};

export const CompositionSection = (props: SectionProps) => {
  const { product } = props;
  return (
    <React.Fragment>
      <div className="section-header">
        <h3>{i18n.t("vademecum.phyto.productView.compositionSectionTitle")}</h3>
      </div>
      <div className="content-row-item">
        <p style={{ marginBottom: "16px" }}>{product?.formulation}</p>
      </div>
      <div className="content-row-item">
        <table>
          <thead>
            <tr>
              <th>
                {i18n.t(
                  "vademecum.phyto.productView.compositionTableNameColumnLabel"
                )}
              </th>
              <th>
                {i18n.t(
                  "vademecum.phyto.productView.compositionTableConcentrationColumnLabel"
                )}
              </th>
            </tr>
          </thead>
          <tbody>
            {product?.composition?.map((composition) => (
              <tr key={composition.id}>
                <td>{composition.substance?.name}</td>
                <td>{`${composition.concentration}${composition.concentrationUnit}`}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </React.Fragment>
  );
};

export const ProviderSection = (props: SectionProps) => {
  const { product } = props;
  return (
    <React.Fragment>
      <div className="section-header">
        <h3>{i18n.t("vademecum.phyto.productView.providerSectionTitle")}</h3>
      </div>
      <div className="content-row">
        <div className="content-row-item">
          <h4>{i18n.t("vademecum.phyto.productView.ownerLabel")}</h4>
          <p>{product?.owner}</p>
        </div>
        <div className="content-row-item">
          <h4>{i18n.t("vademecum.phyto.productView.manufacturerLabel")}</h4>
          <p>{product?.manufacturer}</p>
        </div>
        <div className="content-row-item">
          <h4>{i18n.t("vademecum.phyto.productView.factoryLabel")}</h4>
          <p>{product?.factory}</p>
        </div>
      </div>
    </React.Fragment>
  );
};

const UseCasesSection = (props: SectionProps) => {
  const { product } = props;

  const [selectedAgent, setSelectedAgent] = useState<VadeAgent | null>(null);
  const [selectedCrop, setSelectedCrop] = useState<VadeCrop | null>(null);

  const useCasesFiltered = useMemo(
    () =>
      !selectedAgent && !selectedCrop && product?.uses
        ? product.uses
        : product?.uses?.filter((useCase) =>
            selectedAgent && selectedCrop
              ? useCase.agent?.id === selectedAgent.id &&
                useCase.crop?.id === selectedCrop.id
              : useCase.agent?.id === selectedAgent?.id ||
                useCase.crop?.id === selectedCrop?.id
          ) || [],
    [product, selectedAgent, selectedCrop]
  );

  const agents: VadeAgent[] = useMemo(
    () => uniqueById(useCasesFiltered?.map((useCase) => useCase.agent) || []),
    [useCasesFiltered]
  );
  const crops: VadeCrop[] = useMemo(
    () => uniqueById(useCasesFiltered?.map((useCase) => useCase.crop) || []),
    [useCasesFiltered]
  );

  const handleChangeAgent = (event: any, value?: VadeAgent | null) => {
    setSelectedAgent(value || null);
  };

  const handleChangeCrop = (event: any, value?: VadeCrop | null) => {
    setSelectedCrop(value || null);
  };

  return (
    <React.Fragment>
      <div className="section-header">
        <h3>{i18n.t("vademecum.phyto.productView.useCasesSectionTitle")}</h3>
      </div>

      <div className="filters-container">
        <Autocomplete
          id="use-cases-crop-filter"
          sx={{ mr: 1 }}
          options={crops || []}
          getOptionLabel={(option) => option?.name || ""}
          noOptionsText={i18n.t("formErrors.notFoundResults")}
          fullWidth
          value={selectedCrop}
          onChange={handleChangeCrop}
          renderInput={(params) => (
            <TextField
              {...params}
              size="small"
              label={i18n.t("vademecum.phyto.cropsFilterLabel")}
              placeholder={i18n.t("vademecum.phyto.cropsFilterPlaceholder")}
              InputProps={{ ...params.InputProps, startAdornment: <Search /> }}
            />
          )}
        />

        <Autocomplete
          id="use-cases-agent-filter"
          sx={{ ml: 1 }}
          options={agents || []}
          getOptionLabel={(option) => option?.name || ""}
          noOptionsText={i18n.t("formErrors.notFoundResults")}
          fullWidth
          value={selectedAgent}
          onChange={handleChangeAgent}
          renderInput={(params) => (
            <TextField
              {...params}
              size="small"
              label={i18n.t("vademecum.phyto.agentsFilterLabel")}
              placeholder={i18n.t("vademecum.phyto.agentsFilterPlaceholder")}
              InputProps={{ ...params.InputProps, startAdornment: <Search /> }}
            />
          )}
        />
      </div>

      {useCasesFiltered.map((use) => (
        <div key={use.id} className="use-container">
          <div className="content-row">
            <div className="content-row-item">
              <h4>{i18n.t("vademecum.phyto.productView.useCaseCropLabel")}</h4>
              <p>{use.crop?.name}</p>
            </div>
            <div className="content-row-item">
              <h4>{i18n.t("vademecum.phyto.productView.useCaseAgentLabel")}</h4>
              <p>{use.agent?.name}</p>
            </div>
            <div className="content-row-item">
              <h4>
                {i18n.t(
                  "vademecum.phyto.productView.useCaseSpecificConditioningLabel"
                )}
              </h4>
              <p className="large-text">{use.specificConditioning}</p>
            </div>

            <div className="content-row">
              <UseCaseApplicationsTable use={use} />
              <UseCaseDosageTable use={use} />
              <UseCaseOthersTable use={use} />
            </div>
          </div>
        </div>
      ))}
    </React.Fragment>
  );
};

interface UseCaseTableProps {
  use: VadeProductUse;
}
const UseCaseApplicationsTable = (props: UseCaseTableProps) => {
  const { use } = props;

  return (
    <div className="table-section">
      <h4>
        {i18n.t("vademecum.phyto.productView.useCaseApplicationsTableTitle")}
      </h4>
      <table>
        <thead>
          <tr>
            <th>
              {i18n.t(
                "vademecum.phyto.productView.useCaseApplicationsColumnLabel"
              )}
            </th>
            <th>
              {i18n.t(
                "vademecum.phyto.productView.useCaseApplicationsIntervalColumnLabel"
              )}
            </th>
            <th>
              {i18n.t(
                "vademecum.phyto.productView.useCaseApplicationMethodColumnLabel"
              )}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{use.applications}</td>
            <td>{use.applicationsInterval}</td>
            <td>{use.applicationMethod}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

const UseCaseDosageTable = (props: UseCaseTableProps) => {
  const { use } = props;

  return (
    <div className="table-section">
      <h4>{i18n.t("vademecum.phyto.productView.useCaseDosageTableTitle")}</h4>
      <table>
        <thead>
          <tr>
            <th>
              {i18n.t(
                "vademecum.phyto.productView.useCaseDosageMinMaxColumnLabel"
              )}
            </th>
            <th>
              {i18n.t(
                "vademecum.phyto.productView.useCaseVolumeMinMaxColumnLabel"
              )}
            </th>
            <th>
              {i18n.t(
                "vademecum.phyto.productView.useCaseBrothVolumeColumnLabel"
              )}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{`${use.minDose}-${use.maxDose} ${use.doseUnit}`}</td>
            <td>{`${use.minVolume}-${use.maxVolume} ${use.volumeUnit}`}</td>
            <td>{use.brothVolume}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

const UseCaseOthersTable = (props: UseCaseTableProps) => {
  const { use } = props;

  return (
    <div className="table-section">
      <h4>{i18n.t("vademecum.phyto.productView.useCaseOthersTableTitle")}</h4>
      <table>
        <thead>
          <tr>
            <th>
              {i18n.t(
                "vademecum.phyto.productView.useCaseOtherScopeColumnLabel"
              )}
            </th>
            <th>
              {i18n.t(
                "vademecum.phyto.productView.useCaseOtherCropSystemColumnLabel"
              )}
            </th>
            <th>
              {i18n.t(
                "vademecum.phyto.productView.useCaseOtherSecurityPeriodColumnLabel"
              )}
            </th>
            <th>
              {i18n.t(
                "vademecum.phyto.productView.useCaseOtherUserTypeColumnLabel"
              )}
            </th>
            <th>
              {i18n.t(
                "vademecum.phyto.productView.useCaseOtherBBCHColumnLabel"
              )}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{use.scope}</td>
            <td>{use.cropSystem}</td>
            <td>{use.securityPeriod}</td>
            <td>{use.userType}</td>
            <td>{use.bbch}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};
