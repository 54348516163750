export const SEARCH_LIKE_TIMEOUT_DELAY_MS = 500;

export const EMPTY_TABLE_ICON_SIZE = 72;
export const SECTION_TABLE_FONT_SIZE = 14;
export const RIGHT_DRAWER_ICON_SIZE = 32;
export const UPLOAD_FILE_ERROR = -1;
export const ILEGAL_DATA_OPERATION_ERROR = "FBIllegalDataOperation";
export const INVALID_TOKEN_ERROR = "FBInvalidTokenException";
export const EXPIRED_TOKEN_ERROR = "FBAuthExpiredToken";
export const ENTITY_NOT_FOUND_ERROR = "FBEntityNotFound";
export const ENTITY_NOT_FOUND_EXCEPTION_ERROR = "FBEntityNotFoundException";
export const DATA_INTEGRITY_ERROR = "FBDataIntegrityViolation";
export const EXPIRED_TOKEN_REFETCH_TIMEOUT = 3000; // 3s
export const CHAT_CONVERSATION_HEADER_HEIGHT_PX = 48;
export const CHAT_CONVERSATION_BACK_TO_BOTTOM_THRESOLD = -100;
export const RESEND_CODE_BTN_INTERVAL = 1000 * 60;
export const REFRESH_PHYTO_RECIPES_NOTIFICATION_INTERVAL = 30 * 1000; // 30s
export const MAX_VISIBLE_NOTIFICATIONS = 6;
