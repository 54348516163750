import { Avatar, IconButton, useMediaQuery, useTheme } from "@mui/material";
import { ArrowBack } from "@mui/icons-material";

import AdvisorChat from "../../models/advisor/AdvisorChat";

interface Props {
  advisorChat: AdvisorChat | null;
  onClickBack?: () => void;
}
const ChatConversationHeader = (props: Props) => {
  const { advisorChat, onClickBack } = props;

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("medium"));

  const userName =
    advisorChat?.profileName || advisorChat?.person?.email || "B";
  const avatarLetter = userName?.substring(0, 1).toUpperCase();
  const exploitationName = advisorChat?.defaultAgroBusinessAccount?.name || "";

  return (
    <div className="chat-conversation-header">
      {isSmallScreen && (
        <IconButton size="large" onClick={onClickBack}>
          <ArrowBack />
        </IconButton>
      )}
      <Avatar className="avatar">{avatarLetter}</Avatar>
      <div className="content">
        <span className="title">{userName}</span>
        <span className="subtitle">{exploitationName}</span>
      </div>
    </div>
  );
};

export default ChatConversationHeader;
