import FBVadeFertilizerProductType from "../_api/vademecumFertiliz/FBVadeFertilizerProductType";

class VadeFertilizerProductType extends FBVadeFertilizerProductType {
  constructor(obj?: any) {
    super(obj);
  }

  mapToApiModel(): any {
    const obj: any = super.mapBaseToApiModel();
    return obj;
  }
}

export default VadeFertilizerProductType;
