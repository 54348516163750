import { useEffect } from "react";
import moment, { Moment } from "moment";
import { useFormik } from "formik";
import i18n from "../../config/configI18n";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormGroup,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";

import FormikAutocomplete from "../formik/FormikAutocomplete";

import { useSession } from "../../hooks/useSession";
import useFetch from "../../hooks/useFetch";

import AgroBusinessAccount from "../../models/account/AgroBusinessAccount";

import { AdvisorNoteChipFilter } from "../../constants/interfaces";

interface AdvisorNotesFilters {
  agroBusinessAccount: AgroBusinessAccount | null;
  dateFrom: Moment | null;
  dateTo: Moment | null;
}

interface Props {
  open: boolean;
  noteFilters: AdvisorNoteChipFilter[];
  onCancel: () => void;
  onConfirm: (noteFilters: AdvisorNoteChipFilter[]) => void;
}
const DialogAdvisorNotesFilters = (props: Props) => {
  const { open, noteFilters, onCancel, onConfirm } = props;

  const { selectedCueAccount } = useSession();

  const handleSubmit = (values: AdvisorNotesFilters) => {
    const noteFilters = [] as AdvisorNoteChipFilter[];
    if (values.agroBusinessAccount?.id) {
      noteFilters.push({
        filterName: "agroBusinessAccountId",
        label: values.agroBusinessAccount.name || "",
        value: values.agroBusinessAccount.id,
      });
    }
    if (values.dateFrom) {
      noteFilters.push({
        filterName: "fromTimestamp",
        label: `${i18n.t("words.from")} ${values.dateFrom.format(
          "DD/MM/YYYY"
        )}`,
        value: values.dateFrom.valueOf(),
      });
    }
    if (values.dateTo) {
      noteFilters.push({
        filterName: "toTimestamp",
        label: `${i18n.t("words.to")} ${values.dateTo.format("DD/MM/YYYY")}`,
        value: values.dateTo.valueOf(),
      });
    }
    onConfirm(noteFilters);
  };

  const formik = useFormik({
    initialValues: {} as AdvisorNotesFilters,
    onSubmit: handleSubmit,
  });

  const updateForm = (
    agroBusinessAccounts: AgroBusinessAccount[],
    noteFilters: AdvisorNoteChipFilter[]
  ) => {
    let agroBusinessAccount = null;
    let dateFrom = null;
    let dateTo = null;

    const selectedAgroBusinessAccountId = noteFilters.find(
      (noteFilter) => noteFilter.filterName === "agroBusinessAccountId"
    )?.value;
    if (selectedAgroBusinessAccountId) {
      agroBusinessAccount =
        agroBusinessAccounts.find(
          (agroBusinessAccount) =>
            agroBusinessAccount.id === selectedAgroBusinessAccountId
        ) || null;
    }
    const fromTimestamp = noteFilters.find(
      (noteFilter) => noteFilter.filterName === "fromTimestamp"
    )?.value;
    if (fromTimestamp) dateFrom = moment(fromTimestamp);
    const toTimestamp = noteFilters.find(
      (noteFilter) => noteFilter.filterName === "toTimestamp"
    )?.value;
    if (toTimestamp) dateTo = moment(toTimestamp);
    formik.setValues({ agroBusinessAccount, dateFrom, dateTo });
  };

  const { data: agroBusinessAccounts, isFetching } = useFetch<
    AgroBusinessAccount[]
  >({
    queryKey: ["agroBusinessAccounts", selectedCueAccount?.cueAccount?.id],
    enabled: !!selectedCueAccount,
  });

  useEffect(() => {
    if (agroBusinessAccounts) updateForm(agroBusinessAccounts, noteFilters);
  }, [noteFilters, agroBusinessAccounts]);

  const handleConfirm = () => formik.submitForm();

  const handleCancel = () => {
    if (agroBusinessAccounts) updateForm(agroBusinessAccounts, noteFilters);
    onCancel();
  };

  return (
    <Dialog open={open} className="dialog" onClose={handleCancel}>
      <DialogTitle className="title">
        {i18n.t("notes.filtersDialogTitle")}
      </DialogTitle>
      <DialogContent className="content">
        <FormGroup className="form-group">
          <FormControl variant="outlined" className="form-control">
            <FormikAutocomplete
              formik={formik}
              name="agroBusinessAccount"
              label={i18n.t("words.exploitation")}
              options={agroBusinessAccounts || []}
              optionLabelFieldName="name"
              loading={isFetching}
            />
          </FormControl>
          <FormControl variant="outlined" className="form-control">
            <DatePicker
              className="form-input"
              format="DD/MM/YYYY"
              label={i18n.t("words.from")}
              value={formik.values?.dateFrom}
              onChange={(date) => formik.setFieldValue("dateFrom", date)}
            />
          </FormControl>
          <FormControl variant="outlined" className="form-control">
            <DatePicker
              className="form-input"
              format="DD/MM/YYYY"
              label={i18n.t("words.to")}
              value={formik.values?.dateTo}
              onChange={(date) => formik.setFieldValue("dateTo", date)}
            />
          </FormControl>
        </FormGroup>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} color="primary">
          {i18n.t("words.cancel")}
        </Button>
        <Button onClick={handleConfirm} color="primary">
          {i18n.t("words.confirm")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogAdvisorNotesFilters;
