class Person {
  id?: number;
  email?: string;
  disabled?: boolean;
  master?: boolean;
  emailForNotifications?: string;
  whatsappNumber?: string;
  timeZone?: string;
  termsOfUseShow?: boolean;
  creationDate?: string;
  timeZoneOffset?: number;
  language?: string;
  acceptedPrivacyPolicyVersion?: string;
  privacyPolicyAcceptedOn?: string;
  pendingPrivacyPolicyUrl?: string;
  pendingPrivacyPolicyVersion?: string;

  constructor(obj: any) {
    this.id = obj?.id;
    this.email = obj?.email;
    this.disabled = obj?.disabled;
    this.master = obj?.master;
    this.emailForNotifications = obj?.emailForNotifications;
    this.whatsappNumber = obj?.whatsappNumber ? `+${obj.whatsappNumber}` : "";
    this.timeZone = obj?.timeZone;
    this.termsOfUseShow = obj?.termsOfUseShow;
    this.creationDate = obj?.creationDate;
    this.timeZoneOffset = obj?.timeZoneOffset;
    this.language = obj?.language;
    this.acceptedPrivacyPolicyVersion = obj?.acceptedPrivacyPolicyVersion;
    this.privacyPolicyAcceptedOn = obj?.privacyPolicyAcceptedOn;
    this.pendingPrivacyPolicyUrl = obj?.pendingPrivacyPolicyUrl;
    this.pendingPrivacyPolicyVersion = obj?.pendingPrivacyPolicyVersion;
  }
}

export default Person;
