import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import i18n from "../config/configI18n";

import {
  AppBar,
  Toolbar,
  IconButton,
  Avatar,
  Menu,
  MenuItem,
  ListItemIcon,
  Autocomplete,
  TextField,
  useMediaQuery,
  Button,
  Tooltip,
  styled,
  BadgeProps,
  Badge,
} from "@mui/material";
import {
  Menu as MenuIcon,
  Logout,
  Settings,
  AdminPanelSettings,
  WhatsApp,
  ContactPage,
  SyncProblem,
} from "@mui/icons-material";

import DialogChooseCueAccount from "./dialogs/DialogChooseCueAccount";
import simpleLogoUrl from "../assets/logo_simple.png";

import CueAccountWithRole from "../models/account/CueAccountWithRole";

import { useAuth } from "../hooks/useAuth";
import { useSession } from "../hooks/useSession";

import { PROTECTED_ROUTES } from "../routes/routeNames";

const SiexErrorsBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
  "& .MuiBadge-badge": { right: -8 },
}));

interface Props {
  onClick?: React.MouseEventHandler;
  scrollOnTop?: boolean;
}
const Navbar = ({ onClick, scrollOnTop }: Props) => {
  const isLargeScreen = useMediaQuery("(min-width:840px)");
  const navigate = useNavigate();
  const { user, logout } = useAuth();
  const {
    advisorCueAccounts,
    selectedCueAccount,
    changeSelectedCueAccount,
    siexPendingRegisters,
    siexErrorRegisters,
  } = useSession();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isOpenAvatarMenu, setIsOpenAvatarMenu] = useState<boolean>(false);

  const elevate = isLargeScreen || !scrollOnTop;

  const handleClickLogo = () => {
    navigate(PROTECTED_ROUTES.DASHBOARD);
  };

  const handleClickAvatar = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setIsOpenAvatarMenu(true);
  };
  const handleClickSettings = () => {
    navigate(PROTECTED_ROUTES.SETTINGS);
    handleCloseAvatarMenu();
  };
  const handleClickLogout = () => {
    logout();
    handleCloseAvatarMenu();
  };

  const handleCloseAvatarMenu = () => {
    setAnchorEl(null);
    setIsOpenAvatarMenu(false);
  };

  const handleChangeSelectedCueAccount = (newValue: CueAccountWithRole) =>
    changeSelectedCueAccount(newValue);

  const handleClickGoToCustomerPanel = () => {
    window.open(
      process.env.REACT_APP_CUSTOMER_WEB_URL,
      "_blank",
      "noopener,noreferrer"
    );
  };
  // Open chat in a new tab
  const handleClickGoToChat = () => {
    const chatURL = `${process.env.REACT_APP_ADVISOR_WEB_URL}/${PROTECTED_ROUTES.CHAT}`;
    window.open(chatURL, "_blank", "noopener,noreferrer");
  };

  const handleClickGoToSiexPendingRegisters = () => {
    navigate(PROTECTED_ROUTES.SIEX_PENDING);
  };

  const handleClickGoToSiexErrorRegisters = () => {
    navigate(PROTECTED_ROUTES.SIEX_ERRORS);
  };

  return (
    <AppBar component="nav" className="navbar" elevation={elevate ? 4 : 0}>
      <Toolbar className="toolbar" component="nav">
        <div className="toolbar-left">
          <IconButton className="sidebar-btn" size="large" onClick={onClick}>
            <MenuIcon />
          </IconButton>
          <img
            className="logo"
            alt={i18n.t("components.navbar.altLogo")}
            src={simpleLogoUrl}
            onClick={handleClickLogo}
          />
          <NavbarMenuExpanded
            classname="menu-expanded"
            advisorCueAccounts={advisorCueAccounts}
            selectedCueAccount={selectedCueAccount}
            onChangeSelectedCueAccount={handleChangeSelectedCueAccount}
          />
          {siexPendingRegisters.length > 0 && (
            <Tooltip
              title={`${siexPendingRegisters.length} ${
                siexPendingRegisters.length === 1
                  ? i18n.t("components.navbar.siexPendingTooltip")
                  : i18n.t("components.navbar.siexPendingTooltipPlural")
              }`}
            >
              <IconButton
                className="siex-pending-btn"
                onClick={handleClickGoToSiexPendingRegisters}
              >
                <SiexErrorsBadge
                  className="siex-badge"
                  badgeContent={siexPendingRegisters.length}
                  color="warning"
                >
                  <SyncProblem fontSize="small" sx={{ color: "white" }} />
                </SiexErrorsBadge>
              </IconButton>
            </Tooltip>
          )}
          {siexErrorRegisters.length > 0 && (
            <Tooltip
              title={`${siexErrorRegisters.length} ${
                siexErrorRegisters.length === 1
                  ? i18n.t("components.navbar.siexErrorsTooltip")
                  : i18n.t("components.navbar.siexErrorsTooltipPlural")
              }`}
            >
              <IconButton
                className="siex-errors-btn"
                onClick={handleClickGoToSiexErrorRegisters}
              >
                <SiexErrorsBadge
                  className="siex-badge"
                  badgeContent={siexErrorRegisters.length}
                  color="error"
                >
                  <SyncProblem fontSize="small" sx={{ color: "white" }} />
                </SiexErrorsBadge>
              </IconButton>
            </Tooltip>
          )}
        </div>

        <div className="toolbar-right">
          <NavbarMenuCollapsed
            classname="menu-collapsed"
            advisorCueAccounts={advisorCueAccounts}
            selectedCueAccount={selectedCueAccount}
            onChangeSelectedCueAccount={handleChangeSelectedCueAccount}
          />

          <Tooltip title={i18n.t("tooltips.customerPanelLink")}>
            <Button
              className="link-btn"
              variant="outlined"
              onClick={handleClickGoToCustomerPanel}
            >
              {i18n.t("components.navbar.customerPanelBtn")}
            </Button>
          </Tooltip>
          <Tooltip title={i18n.t("tooltips.chatLink")}>
            <Button
              className="link-btn"
              variant="contained"
              startIcon={<WhatsApp />}
              onClick={handleClickGoToChat}
            >
              {i18n.t("words.chat")}
            </Button>
          </Tooltip>
          <IconButton size="large" onClick={handleClickAvatar}>
            <Avatar className="avatar">
              {user?.person?.email?.charAt(0)?.toUpperCase() || ""}
            </Avatar>
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={isOpenAvatarMenu}
            onClick={handleCloseAvatarMenu}
          >
            <MenuItem onClick={handleClickSettings}>
              <ListItemIcon>
                <Settings />
              </ListItemIcon>
              {i18n.t("words.settings")}
            </MenuItem>
            <MenuItem onClick={handleClickLogout}>
              <ListItemIcon>
                <Logout color="error" />
              </ListItemIcon>
              {i18n.t("logout")}
            </MenuItem>
          </Menu>
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;

interface NavbarMenuProps {
  classname?: string;
  advisorCueAccounts: CueAccountWithRole[];
  selectedCueAccount: CueAccountWithRole | null;
  onChangeSelectedCueAccount: (cawr: CueAccountWithRole) => void;
}
const NavbarMenuCollapsed = (props: NavbarMenuProps) => {
  const {
    advisorCueAccounts,
    selectedCueAccount,
    onChangeSelectedCueAccount,
    classname,
  } = props;

  const [isOpenCueAccountDialog, setIsOpenCueAccountDialog] =
    useState<boolean>(false);

  const openCueAccountDialog = () => setIsOpenCueAccountDialog(true);
  const closeCueAccountDialog = () => setIsOpenCueAccountDialog(false);

  const handleClickGoToCustomerPanel = () => {
    window.open(
      process.env.REACT_APP_CUSTOMER_WEB_URL,
      "_blank",
      "noopener,noreferrer"
    );
  };
  // Open chat in a new tab
  const handleClickGoToChat = () => {
    const chatURL = `${process.env.REACT_APP_ADVISOR_WEB_URL}/${PROTECTED_ROUTES.CHAT}`;
    window.open(chatURL, "_blank", "noopener,noreferrer");
  };

  return (
    <div className={classname}>
      <Tooltip title={i18n.t("tooltips.switchAccount")}>
        <IconButton className="account-btn" onClick={openCueAccountDialog}>
          <AdminPanelSettings fontSize="large" />
        </IconButton>
      </Tooltip>
      <Tooltip title={i18n.t("components.navbar.customerPanelLink")}>
        <IconButton
          className="account-btn"
          onClick={handleClickGoToCustomerPanel}
        >
          <ContactPage fontSize="large" />
        </IconButton>
      </Tooltip>
      <Tooltip title={i18n.t("chatLink")}>
        <IconButton className="account-btn" onClick={handleClickGoToChat}>
          <WhatsApp fontSize="large" />
        </IconButton>
      </Tooltip>

      <DialogChooseCueAccount
        open={isOpenCueAccountDialog}
        options={advisorCueAccounts}
        selected={selectedCueAccount}
        onClose={closeCueAccountDialog}
        onConfirm={onChangeSelectedCueAccount}
      />
    </div>
  );
};

const NavbarMenuExpanded = (props: NavbarMenuProps) => {
  const {
    advisorCueAccounts,
    selectedCueAccount,
    onChangeSelectedCueAccount,
    classname,
  } = props;

  const handleChangeSelectedCueAccount = (
    event: React.SyntheticEvent,
    newValue: CueAccountWithRole | null
  ) => {
    if (newValue) onChangeSelectedCueAccount(newValue);
  };

  return (
    <div className={classname}>
      <Autocomplete
        id="advisor-navbar"
        className="account-select"
        options={advisorCueAccounts}
        getOptionLabel={(option) => option.cueAccount?.name || ""}
        isOptionEqualToValue={(option, value) =>
          option.cueAccount?.id === value.cueAccount?.id
        }
        noOptionsText={i18n.t("formErrors.notFoundResults")}
        popupIcon={advisorCueAccounts.length > 1 ? undefined : false}
        clearIcon={advisorCueAccounts.length > 1 ? undefined : false}
        readOnly={advisorCueAccounts.length < 2}
        value={selectedCueAccount}
        onChange={handleChangeSelectedCueAccount}
        renderInput={(params) => (
          <TextField
            {...params}
            size="small"
            label={i18n.t("words.account")}
            placeholder={i18n.t("components.navbar.searchAccount")}
          />
        )}
      />
    </div>
  );
};
