import FBActiveSubstance from "../_api/catalogue/FBActiveSubstance";

class ActiveSubstance extends FBActiveSubstance {
  constructor(obj?: any) {
    super(obj);
  }

  mapToApiModel(): any {
    const obj: any = this.mapBaseToApiModel();
    return obj;
  }
}

export default ActiveSubstance;
