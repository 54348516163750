import { useState, useEffect, useRef } from "react";
import moment from "moment";
import { Avatar, IconButton, useTheme, useMediaQuery } from "@mui/material";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import i18n from "../../config/configI18n";

import MessageStatusIcon from "./ChatMessageStatusIcon";

import AdvisorChatMessage from "../../models/advisor/AdvisorChatMessage";

import { chatMsgDateHumanizer } from "../../helpers/utils";
import {
  FBEAdvisorChatMessageSource,
  FBEAdvisorChatMessageType,
} from "../../constants/enums";
import brioAgroLogo from "../../assets/logo_simple.png";

const MESSAGE_TEXT_FONT_SIZE = 16;
const MIN_VISIBLE_MESSAGE_TEXT_LINES = 10;

interface Props {
  message: AdvisorChatMessage;
  beforeMessage: AdvisorChatMessage | null;
}
const ChatMessage = (props: Props) => {
  const { message, beforeMessage } = props;

  const textoRef = useRef<HTMLDivElement>(null);

  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("medium"));

  const msgTime = message.messageTimestamp
    ? moment(message.messageTimestamp * 1000).format("HH:mm")
    : "";
  const sameBeforeMessageSource = beforeMessage?.source === message.source;
  const messageMoment = message.messageTimestamp
    ? moment(message.messageTimestamp * 1000)
    : null;
  const beforeMessageMoment = beforeMessage?.messageTimestamp
    ? moment(beforeMessage.messageTimestamp * 1000)
    : null;
  const messageGroupDate =
    messageMoment &&
    (!beforeMessage ||
      (beforeMessageMoment &&
        !messageMoment.isSame(beforeMessageMoment, "day")));
  const isImage = message.type === FBEAdvisorChatMessageType.IMAGE;
  const isDocument = message.type === FBEAdvisorChatMessageType.DOCUMENT;
  const isVideo = message.type === FBEAdvisorChatMessageType.VIDEO;

  const [isCollapsible, setIsCollapsible] = useState<boolean>(false);
  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  // Listen window resize to check if message text is overflowing
  useEffect(() => {
    const handleResize = () => {
      const messageText = textoRef.current;
      if (
        messageText &&
        messageText.clientHeight >
          MESSAGE_TEXT_FONT_SIZE * MIN_VISIBLE_MESSAGE_TEXT_LINES
      ) {
        setIsCollapsible(true);
      } else {
        setIsCollapsible(false);
        setIsExpanded(false);
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleClickImage = () => {
    window.open(
      message.document?.documentLink,
      "_blank",
      "noopener,noreferrer"
    );
  };

  const handleClickDocument = () => {
    window.open(
      message.document?.documentLink,
      "_blank",
      "noopener,noreferrer"
    );
  };

  return (
    <>
      <div
        id={message.id?.toString()}
        className={`chat-message-${message.source?.toLocaleLowerCase()}`}
        style={{ marginTop: sameBeforeMessageSource ? "2px" : "" }}
      >
        <MessageAvatar
          message={message}
          sameBeforeMessageSource={sameBeforeMessageSource}
        />
        <div className={`chat-content-${message.source?.toLocaleLowerCase()}`}>
          <MessageTitle
            message={message}
            sameBeforeMessageSource={sameBeforeMessageSource}
          />
          {message.type === FBEAdvisorChatMessageType.AUDIO &&
            message.audioLink && (
              <audio controls>
                <source src={message?.audioLink} type="audio/ogg" />
              </audio>
            )}
          {isImage &&
            (message.document?.documentLink ? (
              <img
                className="header-img"
                src={message.document?.documentLink}
                alt={i18n.t("words.attachedImage")}
                onClick={handleClickImage}
              />
            ) : (
              <span>{i18n.t("words.gettingImageDots")}</span>
            ))}
          {isDocument &&
            (message.document?.thumbnailLink ? (
              <img
                className="header-img"
                src={message.document?.thumbnailLink}
                alt={i18n.t("words.attachedDoc")}
                onClick={handleClickDocument}
              />
            ) : (
              <span>{i18n.t("words.gettingDocDots")}</span>
            ))}
          {isVideo &&
            (message.document?.documentLink ? (
              <video
                className="header-img"
                src={message.document?.documentLink}
                controls
                onClick={handleClickImage}
              />
            ) : (
              <span>{i18n.t("words.gettingVideoDots")}</span>
            ))}
          <div ref={textoRef} className="message-text-container">
            <p
              className={`message-text ${
                isLargeScreen || isExpanded ? "message-text-expanded" : ""
              }`}
            >
              {message.messageText}
            </p>
            {!isLargeScreen && isCollapsible && (
              <IconButton
                size="small"
                onClick={() => setIsExpanded((state) => !state)}
              >
                {isExpanded ? (
                  <KeyboardArrowUp fontSize="small" />
                ) : (
                  <KeyboardArrowDown fontSize="small" />
                )}
              </IconButton>
            )}
          </div>
          <div className="footer">
            <span className="time">{msgTime}</span>
            <MessageStatusIcon message={message} margin="left" />
          </div>
        </div>
      </div>
      {messageGroupDate && <MessageDate messageMoment={messageMoment} />}
    </>
  );
};

export default ChatMessage;

interface MessageProps {
  message: AdvisorChatMessage;
  sameBeforeMessageSource: boolean;
}
const MessageAvatar = (props: MessageProps) => {
  const { message, sameBeforeMessageSource } = props;

  if (sameBeforeMessageSource) return <Avatar className="avatar-transparent" />;

  let avatarLetter = "";
  switch (message.source) {
    case FBEAdvisorChatMessageSource.BRIOAGRO:
      avatarLetter = i18n.t("words.aiInitials");
      return (
        <Avatar className="avatar" alt={avatarLetter} src={brioAgroLogo}>
          {avatarLetter}
        </Avatar>
      );
    case FBEAdvisorChatMessageSource.CLIENT:
      avatarLetter =
        message.waProfileName?.substring(0, 1)?.toUpperCase() || "C";
      return <Avatar className="avatar">{avatarLetter}</Avatar>;

    default:
      return <Avatar className="avatar-transparent" />;
  }
};

const MessageTitle = (props: MessageProps) => {
  const { message, sameBeforeMessageSource } = props;

  if (sameBeforeMessageSource) return null;

  let title = "";

  switch (message.source) {
    case FBEAdvisorChatMessageSource.BRIOAGRO:
      title = i18n.t("chat.brioAgroAIMessageTitle");
      break;
    case FBEAdvisorChatMessageSource.CLIENT:
      title = message.waProfileName || i18n.t("words.customer");
      break;
    case FBEAdvisorChatMessageSource.SYSTEM:
      title = i18n.t("chat.systemMessageTitle");
      break;
    default:
      break;
  }

  return (
    <div className={`title-${message.source?.toLowerCase()}`}>{title}</div>
  );
};

const MessageDate = (props: { messageMoment: moment.Moment }) => {
  const { messageMoment } = props;

  const dateText = chatMsgDateHumanizer(messageMoment, true);

  return (
    <div className="message-date-container">
      <span className="date-text">{dateText}</span>
    </div>
  );
};

export const ChatMessageEmpty = () => {
  return (
    <div className="chat-message-empty">
      <div className="message-content">
        <p className="message-text">{i18n.t("chat.emptyChatMessage1")}</p>
        <p className="message-text">{i18n.t("chat.emptyChatMessage2")}</p>
      </div>
    </div>
  );
};
