import AgroBusiness from "../../agrobusiness/AgroBusiness";
import CueAccount from "../../account/CueAccount";
import LegalContext from "../../LegalContext";
import RegisterRegion from "../../RegisterRegion";

class FBAgroBusinessAccount {
  id?: number;
  name?: string;
  cueAccount?: CueAccount;
  ownerId?: string;
  siexEnabled?: boolean;
  registerRegion?: RegisterRegion;
  context?: LegalContext;
  agroBusiness?: AgroBusiness;
  recentlyTransferred?: boolean;

  constructor(obj?: any) {
    this.id = obj?.id;
    this.name = obj?.name;
    this.cueAccount = new CueAccount(obj?.cueAccount);
    this.ownerId = obj?.ownerId;
    this.siexEnabled = obj?.siexEnabled;
    this.registerRegion = new RegisterRegion(obj?.registerRegion);
    this.context = new LegalContext(obj?.context);
    this.agroBusiness = new AgroBusiness(obj?.agroBusiness);
    this.recentlyTransferred = obj?.recentlyTransferred;
  }

  mapBaseToApiModel(): any {
    const obj: any = { ...this };
    obj.cueAccount = this.cueAccount?.mapToApiModel();
    obj.registerRegion = this.registerRegion?.mapToApiModel();
    obj.context = this.context?.mapToApiModel();
    obj.agroBusiness = this.agroBusiness?.mapToApiModel();
    return obj;
  }
}

export default FBAgroBusinessAccount;
