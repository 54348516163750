import { useState, useEffect } from "react";
import { useFormik } from "formik";
import moment from "moment";
import { StompSubscription } from "@stomp/stompjs";
import { getAuth } from "firebase/auth";
import i18n from "../../config/configI18n";

import {
  Fab,
  Fade,
  LinearProgress,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { Send, KeyboardArrowDown } from "@mui/icons-material";

import FormikTextField from "../formik/FormikTextField";
import ChatMessage, { ChatMessageEmpty } from "./ChatMessage";
import ChatConversationHeader from "./ChatConversationHeader";
import ChatHomeScreen from "../../screens/ChatHomeScreen";

import AdvisorChat from "../../models/advisor/AdvisorChat";
import AdvisorChatMessage from "../../models/advisor/AdvisorChatMessage";

import { useSession } from "../../hooks/useSession";
import { useChat } from "../../hooks/useChat";

import { chatMsgDateHumanizer, updateList } from "../../helpers/utils";

import {
  CHAT_CONVERSATION_BACK_TO_BOTTOM_THRESOLD,
  CHAT_CONVERSATION_HEADER_HEIGHT_PX,
} from "../../constants/constants";
import { FBEAdvisorChatMessageType } from "../../constants/enums";
import { useAuth } from "../../hooks/useAuth";

let chatSubscription: StompSubscription | undefined = undefined;

interface Props {
  advisorChat: AdvisorChat | null;
  onAllMessagesRead?: () => void;
  onClose?: () => void;
}
const ChatConversation = (props: Props) => {
  const { advisorChat, onClose } = props;

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("medium"));

  const unsubscribeConversation = () => {
    chatSubscription?.unsubscribe();
    chatSubscription = undefined;
  };

  const handleBackPressed = (event: Event) => {
    event.preventDefault();
    onClose && onClose();
  };

  const handlePressedKey = (event: KeyboardEvent) => {
    if (event.key === "Escape" && isSmallScreen) {
      event.preventDefault();
      onClose && onClose();
    }
  };

  // Unsubscribe to conversation updates
  useEffect(() => {
    return () => {
      unsubscribeConversation();
    };
  }, []);
  useEffect(() => {
    if (!advisorChat) unsubscribeConversation();
  }, [advisorChat]);

  // Detect back button pressed in mobile devices
  useEffect(() => {
    window.addEventListener("popstate", handleBackPressed);
    window.addEventListener("keydown", handlePressedKey);

    return () => {
      window.removeEventListener("popstate", handleBackPressed);
      window.removeEventListener("keydown", handlePressedKey);
    };
  }, [isSmallScreen]);

  if (!advisorChat) return <ChatHomeScreen />;

  return (
    <div className="chat-conversation">
      <ChatConversationHeader advisorChat={advisorChat} onClickBack={onClose} />
      <ChatMessages {...props} />
      <ChatFooter {...props} />
    </div>
  );
};

export default ChatConversation;

let lastRequestMessageId: number | undefined = undefined;
const ChatMessages = (props: Props) => {
  const { advisorChat, onAllMessagesRead } = props;

  const auth = getAuth();
  const { user } = useAuth();
  const { selectedCueAccount } = useSession();
  const { chatClient, isConnected } = useChat();

  const [messages, setMessages] = useState<AdvisorChatMessage[]>([]);
  const [scrollPosition, setScrollPosition] = useState<number>(0);
  const [isSubscribing, setIsSubscribing] = useState<boolean>(true);
  const [isFetchingMoreMessages, setIsFetchingMoreMessages] =
    useState<boolean>(false);

  const markMessagesAsRead = async () => {
    if (scrollPosition >= 0) {
      const cueAccountId = selectedCueAccount?.cueAccount?.id;
      const advisorChatId = advisorChat?.id;
      const lastMessage = messages[0];
      const messageId = lastMessage?.id;
      const isAlreadyMarked = lastMessage?.messageRead;
      const markReadByAdvisorEndpointUrl = `${process.env.REACT_APP_CHAT_API_URL}/advisor/${cueAccountId}/chat/${advisorChatId}/message/${messageId}/markReadByAdvisor`;
      if (!isAlreadyMarked && cueAccountId && advisorChatId && messageId) {
        const idToken = (await auth.currentUser?.getIdToken()) || user?.idToken;
        fetch(markReadByAdvisorEndpointUrl, {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${idToken}`,
            "Content-Type": "application/json",
          },
        }).then((response) => {
          // Mark all before messages as read
          if (response.status === 200) {
            const readTimestamp = new Date().getTime() / 1000;
            const readMessages = messages.map((message) =>
              message.pendingToSend
                ? message
                : ({
                    ...message,
                    messageRead: true,
                    waReadOnTimestamp: readTimestamp,
                  } as AdvisorChatMessage)
            );
            setMessages(readMessages);
            if (onAllMessagesRead) onAllMessagesRead(); // Update chat list unread messages count
          }
        });
      }
    }
  };

  const updateAndSortMessages = (
    oldMessages: AdvisorChatMessage[],
    newMessages: AdvisorChatMessage[]
  ): AdvisorChatMessage[] => {
    const ul = updateList(
      oldMessages,
      newMessages,
      "id"
    ) as AdvisorChatMessage[];
    return ul.sort(
      (a, b) => (b.messageTimestamp || 0) - (a.messageTimestamp || 0)
    );
  };

  const unsubscribeToCurrentConversation = () => {
    setMessages([]);
    if (chatSubscription?.id) chatSubscription.unsubscribe();
  };

  const subscribeToConversationUpdates = () => {
    const selectedCueAccountId = selectedCueAccount?.cueAccount?.id;
    const selectedConversationId = advisorChat?.id;
    const newEndpointUrl = `/advisor/${selectedCueAccountId}/chat/${selectedConversationId}/messages`;
    if (
      chatClient?.connected &&
      selectedCueAccountId &&
      selectedConversationId &&
      chatSubscription?.id !== newEndpointUrl
    ) {
      setIsSubscribing(true);
      unsubscribeToCurrentConversation();
      chatSubscription = chatClient?.subscribe(
        newEndpointUrl,
        (message) => {
          const data = JSON.parse(message.body);
          const newMessages = data?.map(
            (message: any) => new AdvisorChatMessage(message)
          );
          // Set and order messages (last at the bottom)
          setIsSubscribing(false);
          setMessages((messages) =>
            updateAndSortMessages(messages, newMessages)
          );
        },
        { id: newEndpointUrl }
      );
    }
  };

  // Update sticky date when advisorChat changes
  useEffect(() => {
    setTimeout(() => {
      updateStickyDate();
    }, 500); // Wait for messages to be rendered (image or video messages)
  }, [messages]);

  // Subscribe to messages updates
  useEffect(() => {
    if (isConnected) subscribeToConversationUpdates();
  }, [isConnected, selectedCueAccount, advisorChat]);

  // Reset subscription to force subscribe when auto disconnect the websocket
  useEffect(() => {
    if (!isConnected) chatSubscription = undefined;
  }, [isConnected]);

  // Update last message read if scroll position is at the bottom
  useEffect(() => {
    markMessagesAsRead();
  }, [messages]);

  // Mark last message as read if scroll position is at the bottom
  useEffect(() => {
    if (scrollPosition === 0) markMessagesAsRead();
  }, [scrollPosition]);

  const getLastVisibleMessage = (): AdvisorChatMessage | undefined => {
    let lastVisibleMessage: AdvisorChatMessage | undefined = undefined;

    // Get messages by classname
    const elements = document.querySelectorAll('[class^="chat-message"]');
    const messageElements = Array.from(elements);
    let lastVisibleMessageElement: any;
    let lowerBottom: number | null = null;
    // Find the last visible message
    messageElements.forEach((element) => {
      const rect = element.getBoundingClientRect();
      const rbottom = rect.bottom - (CHAT_CONVERSATION_HEADER_HEIGHT_PX + 24);
      if (rbottom >= 0 && (!lowerBottom || rbottom < lowerBottom)) {
        lastVisibleMessageElement = element;
        lowerBottom = rbottom;
      }
    });
    if (lastVisibleMessageElement) {
      const lastVisibleMessageId = lastVisibleMessageElement.getAttribute("id");
      lastVisibleMessage = messages.find(
        (message) => message.id?.toString() === lastVisibleMessageId
      );
    }
    return lastVisibleMessage;
  };

  const showStickyDate = (date: string) => {
    // Show the sticky date container
    const stickyDateContainerElement = document.getElementById(
      "message-date-container-sticky"
    );
    const stickyDate = document.getElementById("stycky-date");
    if (stickyDateContainerElement && stickyDate && date.length > 0) {
      stickyDateContainerElement.style.display = "block";
      stickyDate.innerHTML = date;
    }
  };

  const hideStickyDate = () => {
    // Hide the sticky date container
    const stickyDateContainerElement = document.getElementById(
      "message-date-container-sticky"
    );
    if (stickyDateContainerElement)
      stickyDateContainerElement.style.display = "none";
  };

  const updateStickyDate = () => {
    // Get the message date and set it to sticky
    const lastVisibleMessage = getLastVisibleMessage();
    if (lastVisibleMessage && lastVisibleMessage.messageTimestamp) {
      const dateText = chatMsgDateHumanizer(
        moment(lastVisibleMessage.messageTimestamp * 1000),
        true
      );
      showStickyDate(dateText);
    } else hideStickyDate();
  };

  // Request more messages if the last visible is the las message in the list
  const requestMoreMessageIfNeed = async () => {
    const lastVisibleMessage = getLastVisibleMessage();
    const lastRxMessage = messages[messages.length - 1];

    if (
      !isFetchingMoreMessages &&
      lastVisibleMessage?.id === lastRxMessage?.id &&
      lastRxMessage?.id !== lastRequestMessageId
    ) {
      const cueAccountId = selectedCueAccount?.cueAccount?.id;
      const advisorChatId = advisorChat?.id;
      const lastMessageId = lastRxMessage?.id;
      const requestMessagesEndpointUrl = `${process.env.REACT_APP_CHAT_API_URL}/advisor/${cueAccountId}/chat/${advisorChatId}/requestMessages?lastMessageId=${lastMessageId}`;
      if (cueAccountId && advisorChatId && lastMessageId) {
        setIsFetchingMoreMessages(true);

        const idToken = (await auth.currentUser?.getIdToken()) || user?.idToken;
        fetch(requestMessagesEndpointUrl, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${idToken}`,
            "Content-Type": "application/json",
          },
        })
          .then(async (response) => {
            if (response.status === 200) {
              lastRequestMessageId = lastMessageId;
              const data = await response.json();
              const newMessages = data?.map(
                (message: any) => new AdvisorChatMessage(message)
              );
              // Set and order messages (last at the bottom)
              setMessages((messages) =>
                updateAndSortMessages(messages, newMessages)
              );
              setIsFetchingMoreMessages(false);
            }
            // Wait 5 seconds to retry if unexpected error
            else {
              setTimeout(() => {
                setIsFetchingMoreMessages(false);
              }, 5000);
            }
          })
          .catch((error) => {
            // Wait 5 seconds to retry if unexpected error
            setTimeout(() => {
              setIsFetchingMoreMessages(false);
            }, 5000);
          });
      }
    }
  };

  const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    updateStickyDate();
    requestMoreMessageIfNeed();
    setScrollPosition(event.currentTarget.scrollTop);
  };

  const scrollToBottom = () => {
    const chatMessageListElement = document.querySelector(".chat-message-list");
    if (chatMessageListElement) {
      chatMessageListElement.scrollTop = chatMessageListElement.scrollHeight;
    }
  };

  return (
    <>
      {(isSubscribing || isFetchingMoreMessages) && (
        <LinearProgress sx={{ width: "100%" }} />
      )}
      {!isSubscribing && messages.length === 0 ? (
        <ChatMessageEmpty />
      ) : (
        <div className="chat-message-list" onScroll={handleScroll}>
          {messages.map((advisorMessage, index) => {
            // List order is inverted
            const beforeMessage =
              index < messages.length - 1 ? messages[index + 1] : null;

            return (
              <ChatMessage
                key={advisorMessage.id}
                message={advisorMessage}
                beforeMessage={beforeMessage}
              />
            );
          })}
          <StickyDate />
          <ScrollToDownFab
            isVisible={
              scrollPosition < CHAT_CONVERSATION_BACK_TO_BOTTOM_THRESOLD
            }
            onClick={scrollToBottom}
          />
        </div>
      )}
    </>
  );
};

const ChatFooter = (props: Props) => {
  const { advisorChat } = props;

  const auth = getAuth();
  const { selectedCueAccount } = useSession();

  const postAdvisorMessage = async (message: AdvisorChatMessage) => {
    const selectedCueAccountId = selectedCueAccount?.cueAccount?.id;
    const advisorChatId = advisorChat?.id;
    const idToken = await auth.currentUser?.getIdToken();
    if (selectedCueAccountId && advisorChatId && idToken) {
      const postAdvisorMessageEndpointUrl = `${process.env.REACT_APP_CHAT_API_URL}/advisor/${selectedCueAccountId}/chat/${advisorChatId}/message`;
      const payload = {
        type: FBEAdvisorChatMessageType.TEXT,
        messageText: message.messageText,
      };
      fetch(postAdvisorMessageEndpointUrl, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${idToken}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });
    }
  };

  const formik = useFormik({
    initialValues: new AdvisorChatMessage(),
    onSubmit: postAdvisorMessage,
  });

  const handleClickSendMessage = () => {
    const messageText = formik.values.messageText;
    if (messageText && messageText?.length > 0) {
      formik.submitForm();
      setTimeout(() => {
        formik.resetForm();
      }, 150);
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleClickSendMessage();
    }
  };

  return (
    <div className="chat-footer">
      <FormikTextField
        formik={formik}
        name="messageText"
        placeholder={i18n.t("chat.typeMessagePlaceholder")}
        className="chat-input"
        onKeyDown={handleKeyDown}
      />
      <Fab color="primary" size="medium" onClick={handleClickSendMessage}>
        <Send />
      </Fab>
    </div>
  );
};

const StickyDate = () => {
  return (
    <div
      id="message-date-container-sticky"
      className="message-date-container-sticky"
    >
      <span id="stycky-date" className="date-text"></span>
    </div>
  );
};

interface ScrollToDownFabProps {
  isVisible: boolean;
  onClick: () => void;
}
const ScrollToDownFab = (props: ScrollToDownFabProps) => {
  const { isVisible, onClick } = props;

  const footerHeight = document.querySelector(".chat-footer")?.clientHeight;

  return (
    <Fade in={isVisible}>
      <Fab
        size="small"
        sx={{ position: "fixed", bottom: footerHeight, right: 12 }}
        onClick={onClick}
      >
        <KeyboardArrowDown />
      </Fab>
    </Fade>
  );
};
