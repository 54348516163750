import {
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  Navigate,
} from "react-router-dom";
import * as Sentry from "@sentry/react";

import AuthLayout from "./AuthLayout";
import HomeLayout from "./HomeLayout";
import ProtectedLayout from "./ProtectedLayout";

import ErrorScreen from "../screens/ErrorScreen";
import LoginScreen from "../screens/LoginScreen";
import EmailCodeScreen from "../screens/EmailCodeScreen";
import WhatsappCodeScreen from "../screens/WhatsappCodeScreen";
import OnBoardingScreen from "../screens/OnBoardingScreen";
import ExploitationsScreen from "../screens/ExploitationsScreen";
import ExploitationsPermissionsScreen from "../screens/ExploitationsPermissionsScreen";
import NotesScreen from "../screens/NotesScreen";
import StaffScreen from "../screens/StaffScreen";
import ChatScreen from "../screens/ChatScreen";
import SettingsScreen from "../screens/SettingsScreen";
import PoliciesScreen from "../screens/PoliciesScreen";
import VademecumPhytoScreen from "../screens/VademecumPhytoScreen";
import VademecumFertilizScreen from "../screens/VademecumFertilizScreen";
import AccountSyncScreen from "../screens/AccountSyncScreen";
import ExploitationRecipesScreen from "../screens/ExploitationRecipesScreen";
import SiexPendingScreen from "../screens/SiexPendingScreen";
import SiexErrorsScreen from "../screens/SiexErrorsScreen";

import { PUBLIC_ROUTES, PROTECTED_ROUTES } from "./routeNames";

const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouter(createBrowserRouter);

const router = sentryCreateBrowserRouter(
  createRoutesFromElements(
    <Route element={<AuthLayout />} errorElement={<ErrorScreen />}>
      <Route element={<HomeLayout />}>
        <Route
          path={PUBLIC_ROUTES.HOME}
          element={<Navigate to={PUBLIC_ROUTES.LOGIN} replace />}
        />
        <Route path={PUBLIC_ROUTES.LOGIN} element={<LoginScreen />} />
        <Route
          path={PUBLIC_ROUTES.EMAIL_VERIFICATION}
          element={<EmailCodeScreen />}
        />
        <Route
          path={PUBLIC_ROUTES.WHATSAPP_VERIFICATION}
          element={<WhatsappCodeScreen />}
        />
        <Route path={PUBLIC_ROUTES.POLICIES} element={<PoliciesScreen />} />
      </Route>

      <Route element={<ProtectedLayout />}>
        <Route
          path={PROTECTED_ROUTES.ONBOARDING}
          element={<OnBoardingScreen />}
        />
        <Route
          path={PROTECTED_ROUTES.DASHBOARD}
          element={
            <Navigate to={`/${PROTECTED_ROUTES.EXPLOITATIONS}`} replace />
          }
        />
        <Route
          path={`${PROTECTED_ROUTES.EXPLOITATIONS}/*`}
          element={<ExploitationsScreen />}
        />
        <Route
          path={`${PROTECTED_ROUTES.EXPLOITATIONS_PERMISSIONS}/*`}
          element={<ExploitationsPermissionsScreen />}
        />
        <Route path={`${PROTECTED_ROUTES.NOTES}/*`} element={<NotesScreen />} />
        <Route path={`${PROTECTED_ROUTES.STAFF}/*`} element={<StaffScreen />} />
        <Route path={`${PROTECTED_ROUTES.CHAT}/*`} element={<ChatScreen />} />
        <Route
          path={`${PROTECTED_ROUTES.EXPLOITATION_RECIPES}/:recipeId?/:productId?`}
          element={<ExploitationRecipesScreen />}
        />
        <Route
          path={`${PROTECTED_ROUTES.VADEMECUM_PHYTO}/*`}
          element={<VademecumPhytoScreen />}
        />
        <Route
          path={`${PROTECTED_ROUTES.VADEMECUM_FERTILIZER}/*`}
          element={<VademecumFertilizScreen />}
        />
        <Route path={PROTECTED_ROUTES.SETTINGS} element={<SettingsScreen />} />
        <Route
          path={PROTECTED_ROUTES.ACCOUNT_SYNC}
          element={<AccountSyncScreen />}
        />
        <Route
          path={PROTECTED_ROUTES.SIEX_PENDING}
          element={<SiexPendingScreen />}
        />
        <Route
          path={PROTECTED_ROUTES.SIEX_ERRORS}
          element={<SiexErrorsScreen />}
        />
      </Route>
    </Route>
  )
);

const AppRouter = () => {
  return <RouterProvider router={router} />;
};

export default AppRouter;
