import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import ChatList from "../components/chat/ChatList";
import ChatConversation from "../components/chat/ChatConversation";
import AlertSnackbar from "../components/elements/AlertSnackbar";

import AdvisorChat from "../models/advisor/AdvisorChat";

import { ChatProvider } from "../hooks/useChat";

import { SnackbarInterface } from "../constants/interfaces";

const ChatScreen = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [snackbarMsg, setSnackbarMsg] = useState<SnackbarInterface | null>(
    null
  );
  const [selectedConversation, setSelectedConversation] =
    useState<AdvisorChat | null>(null);

  const closeConversation = () => {
    setSelectedConversation(null);
    navigate(-1);
  };

  const handleOnAllMessagesRead = () => {
    if (selectedConversation) {
      const updatedChat = {
        ...selectedConversation,
        unreadMessagesCount: 0,
      } as AdvisorChat;
      setSelectedConversation(updatedChat);
    }
  };

  const handleChatConnectionError = (message: string) => {
    setSnackbarMsg({
      severity: "error",
      message,
    });
  };

  const handleChangeSelectedChat = (advisorChat: AdvisorChat | null) => {
    if (advisorChat && !location.pathname.includes("/conversation"))
      navigate(`${location.pathname}/conversation`);
    setSelectedConversation(advisorChat);
  };

  return (
    <div className="layout-chat-container">
      <AlertSnackbar
        open={!!snackbarMsg}
        snackbarMsg={snackbarMsg}
        onClose={() => setSnackbarMsg(null)}
      />
      <div className="content">
        <ChatProvider onError={handleChatConnectionError}>
          <ChatList
            selectedChat={selectedConversation}
            onChangeSelectedChat={handleChangeSelectedChat}
          />
          <div className="body">
            <ChatConversation
              advisorChat={selectedConversation}
              onAllMessagesRead={handleOnAllMessagesRead}
              onClose={closeConversation}
            />
          </div>
        </ChatProvider>
      </div>
    </div>
  );
};

export default ChatScreen;
