import Person from "../../Person";
import AgroBusinessAccount from "../../account/AgroBusinessAccount";

class FBAdvisorNote {
  id?: number;
  person?: Person;
  agroBusinessAccount?: AgroBusinessAccount;
  timestamp?: number;
  description?: string;
  enabledDate?: string;
  disabledDate?: string;

  constructor(obj?: any) {
    this.id = obj?.id;
    this.person = new Person(obj?.person);
    this.agroBusinessAccount = new AgroBusinessAccount(
      obj?.agroBusinessAccount
    );
    this.timestamp = obj?.timestamp;
    this.description = obj?.description;
    this.enabledDate = obj?.enabledDate;
    this.disabledDate = obj?.disabledDate;
  }

  mapBaseToApiModel(): any {
    const obj: any = { ...this };
    return obj;
  }
}

export default FBAdvisorNote;
