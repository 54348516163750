import moment from "moment";

import FBDocument from "../_api/files/FBDocument";

import { dateConverter } from "../../helpers/utils";

class Document extends FBDocument {
  idx?: number;
  file?: File;
  documentLink?: string;
  thumbnailLink?: string;
  dateFormatted?: string;
  creationDate?: string;

  constructor(obj?: any) {
    super(obj);
    this.idx = this.id;
    this.documentLink = obj?.documentUrl
      ? `${process.env.REACT_APP_API_BASE_URL}${obj.documentUrl}`
      : undefined;
    this.thumbnailLink = obj?.thumbnailUrl
      ? `${process.env.REACT_APP_API_BASE_URL}${obj.thumbnailUrl}`
      : undefined;
    this.dateFormatted = dateConverter({ date: this.date });
    this.creationDate = moment((this.creationInstant as number) * 1000).format(
      "DD MMM YY HH:mm"
    );
  }

  mapToApiModel(): any {
    const obj: any = super.mapBaseToApiModel();

    if (!this.date) obj.date = moment().format("YYYY-MM-DD");

    // Remove custom variables
    delete obj.idx;
    delete obj.file;
    delete obj.documentLink;
    delete obj.thumbnailLink;
    delete obj.dateFormatted;
    delete obj.creationDate;
    return obj;
  }
}

export default Document;
