import { FBESiexRequestStatus } from "../../../constants/enums";
import AgroBusiness from "../../agrobusiness/AgroBusiness";

class FBSiexRequest {
  id?: number;
  agroBusiness?: AgroBusiness;
  status?: FBESiexRequestStatus;
  creationTimestamp?: number;
  requestTimestamp?: number;
  responseTimestamp?: number;

  constructor(obj?: any) {
    this.id = obj?.id;
    this.agroBusiness = new AgroBusiness(obj?.agroBusiness);
    this.status = obj?.status;
    this.creationTimestamp = obj?.creationTimestamp;
    this.requestTimestamp = obj?.requestTimestamp;
    this.responseTimestamp = obj?.responseTimestamp;
  }

  mapBaseToApiModel(): any {
    const obj: any = { ...this };
    obj.agroBusiness = this.agroBusiness?.mapBaseToApiModel();
    return obj;
  }
}

export default FBSiexRequest;
