class FBVadeFertilizerManufacturer {
  id?: number;
  name?: string;
  code?: string;
  address?: string;
  municipality?: string;
  province?: string;

  constructor(obj?: any) {
    this.id = obj?.id;
    this.name = obj?.name;
    this.code = obj?.code;
    this.address = obj?.address;
    this.municipality = obj?.municipality;
    this.province = obj?.province;
  }

  mapBaseToApiModel(): any {
    const obj: any = { ...this };
    return obj;
  }
}

export default FBVadeFertilizerManufacturer;
