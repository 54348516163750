import { Navigate, Outlet, useLocation } from "react-router-dom";
import { Cookies } from "react-cookie";

import PoliciesScreen from "../screens/PoliciesScreen";

import { useAuth } from "../hooks/useAuth";
import { useSession } from "../hooks/useSession";

import { PROTECTED_ROUTES, PUBLIC_ROUTES } from "./routeNames";

const HomeLayout = () => {
  const { user } = useAuth();
  const { advisorCueAccounts } = useSession();
  const location = useLocation();
  const cookies = new Cookies();
  const lastPath = cookies.get("lastURLPath");

  if (location.pathname === PUBLIC_ROUTES.POLICIES) return <PoliciesScreen />;

  // Redirect to last saved url path if exits, else redirect to Dashboard
  if (user?.person && advisorCueAccounts.length > 0)
    return (
      <Navigate
        to={
          lastPath &&
          lastPath.length > 1 &&
          !lastPath.includes(PROTECTED_ROUTES.ONBOARDING)
            ? lastPath
            : PROTECTED_ROUTES.DASHBOARD
        }
        replace
      />
    );

  if (user?.idToken)
    return <Navigate to={PROTECTED_ROUTES.ONBOARDING} replace />;

  return <Outlet />;
};

export default HomeLayout;
