import moment from "moment";

import FBAdvisorNote from "../_api/advisor/FBAdvisorNote";

class AdvisorNote extends FBAdvisorNote {
  date?: string;

  constructor(obj?: any) {
    super(obj);
    if (obj?.timestamp)
      this.date = moment(obj.timestamp * 1000).format("DD MMM YY HH:mm");
  }

  mapToApiModel(): any {
    const obj: any = super.mapBaseToApiModel();
    if (!this.agroBusinessAccount?.id) obj.agroBusinessAccount = null;

    // Delete any properties that should not be sent to the API
    delete obj.date;
    return obj;
  }
}

export default AdvisorNote;
