import { FBPersonRole } from "../../../constants/enums";
import CueAccount from "../../account/CueAccount";

class FBCueAccountWithRole {
  cueAccount?: CueAccount;
  role?: FBPersonRole;

  constructor(obj?: any) {
    this.cueAccount = new CueAccount(obj?.cueAccount);
    this.role = obj?.role;
  }

  mapBaseToApiModel(): any {
    const obj: any = { ...this };
    obj.cueAccount = this.cueAccount?.mapToApiModel();
    return obj;
  }
}

export default FBCueAccountWithRole;
