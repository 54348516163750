class FBAgroBusiness {
  id?: number;
  registerId?: string;
  regionRegisterId?: string;
  fieldBookId?: string;
  registerDate?: string;
  autocontrol?: boolean;
  directSale?: boolean;
  enabledDate?: string;
  disabledDate?: string;
  siexEntity?: boolean;
  siexUpdatedOn?: number;

  constructor(obj?: any) {
    this.id = obj?.id;
    this.registerId = obj?.registerId;
    this.regionRegisterId = obj?.regionRegisterId;
    this.fieldBookId = obj?.fieldBookId;
    this.registerDate = obj?.registerDate;
    this.autocontrol = obj?.autocontrol;
    this.directSale = obj?.directSale;
    this.enabledDate = obj?.enabledDate;
    this.disabledDate = obj?.disabledDate;
    this.siexEntity = obj?.siexEntity;
    this.siexUpdatedOn = obj?.siexUpdatedOn;
  }

  mapBaseToApiModel(): any {
    const obj: any = { ...this };
    return obj;
  }
}

export default FBAgroBusiness;
