import FBAdvisorChatMessage from "../_api/advisor/FBAdvisorChatMessage";

class AdvisorChatMessage extends FBAdvisorChatMessage {
  audioLink?: string;

  constructor(obj?: any) {
    super(obj);
    this.audioLink = obj?.audioUrl
      ? `${process.env.REACT_APP_API_BASE_URL}${obj.audioUrl}`
      : undefined;
  }

  mapToApiModel(): any {
    const obj: any = super.mapBaseToApiModel();

    delete obj.audioLink;
    return obj;
  }
}

export default AdvisorChatMessage;
