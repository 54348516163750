import { useMemo } from "react";

import { Collapse, Chip, Grid } from "@mui/material";

import { ChipFilter } from "../../constants/interfaces";

interface Props {
  chipFilters: ChipFilter[];
  onChange?: (chipFilters: ChipFilter[]) => void;
  onClick?: (chipFilter: ChipFilter) => void;
}
const ChipFilters = (props: Props) => {
  const { chipFilters, onChange, onClick } = props;

  const handleOnDeleteFilter = (filterName: string) => {
    onChange &&
      onChange(
        chipFilters.filter((chipFilter) => chipFilter.filterName !== filterName)
      );
  };

  const handleOnClickFilter = (chipFilter: ChipFilter) => {
    onClick && onClick(chipFilter);
  };

  return useMemo(
    () => (
      <Collapse
        in={chipFilters.length > 0}
        className={`content-table-filters${
          chipFilters.length === 0 ? "-collapsed" : ""
        }`}
      >
        <Grid container spacing={1}>
          {chipFilters.map((chipFilter) => (
            <Grid item>
              <Chip
                label={chipFilter.label}
                color="primary"
                variant="outlined"
                onClick={() => handleOnClickFilter(chipFilter)}
                onDelete={() => handleOnDeleteFilter(chipFilter.filterName)}
              />
            </Grid>
          ))}
        </Grid>
      </Collapse>
    ),
    [chipFilters]
  );
};

export default ChipFilters;
