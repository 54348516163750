import FBLocalAuthSendCodeRequest from "../_api/auth/FBLocalAuthSendCodeRequest";

class LocalAuthSendCodeRequest extends FBLocalAuthSendCodeRequest {
  constructor() {
    super();
  }

  mapToClass(obj: any): LocalAuthSendCodeRequest | undefined {
    if (!obj) return undefined;
    this.mapToBaseClass(obj);
    return this;
  }

  mapToApiModel(): any {
    const obj: any = this.mapBaseToApiModel();
    return obj;
  }
}

export default LocalAuthSendCodeRequest;
