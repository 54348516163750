import { PhytoRecipeStatus, PhytoRecipeType } from "../../../constants/enums";
import AgroBusinessPhytosanitaryInvolvedEntity from "../../agrobusiness/AgroBusinessPhytosanitaryInvolvedEntity";
import MeasurementUnit from "../../catalogue/MeasurementUnit";
import PhytosanitaryActionJustificationType from "../../catalogue/PhytosanitaryActionJustificationType";
import PlantDisease from "../../catalogue/PlantDisease";
import PlantPlague from "../../catalogue/PlantPlague";
import PlantRegulatorsAndOthers from "../../catalogue/PlantRegulatorsAndOthers";
import PlantWeed from "../../catalogue/PlantWeed";
import Crop from "../../crop/Crop";
import PhytosanitaryProduct from "../../phyto/PhytosanitaryProduct";
import PhytoRecipeStatusHistory from "../../phytoRecipe/PhytoRecipeStatusHistory";
import RegisteredPhytosanitaryProduct from "../../vademecum/RegisteredPhytosanitaryProduct";
import VadeProductUse from "../../vademecum/VadeProductUse";

class FBPhytoRecipe {
  id?: number;
  type?: PhytoRecipeType;
  advisor?: AgroBusinessPhytosanitaryInvolvedEntity;
  status?: PhytoRecipeStatus;
  statusHistory?: PhytoRecipeStatusHistory[];
  date?: string;
  description?: string;
  startRecipeDate?: string;
  endRecipeDate?: string;
  crop?: Crop;
  product?: PhytosanitaryProduct;
  registeredProduct?: RegisteredPhytosanitaryProduct;
  productsUses?: VadeProductUse[];
  weeds?: PlantWeed[];
  plagues?: PlantPlague[];
  diseases?: PlantDisease[];
  regulators?: PlantRegulatorsAndOthers[];
  observations?: string;
  dose?: number;
  doseUnit?: MeasurementUnit;
  justifications?: PhytosanitaryActionJustificationType[];
  enabledDate?: string;
  disabledDate?: string;

  constructor(obj?: any) {
    this.id = obj?.id;
    this.type = obj?.type;
    this.advisor = new AgroBusinessPhytosanitaryInvolvedEntity(obj?.advisor);
    this.status = obj?.status;
    this.statusHistory = obj?.statusHistory?.map(
      (statusHistory: any) => new PhytoRecipeStatusHistory(statusHistory)
    );
    this.date = obj?.date;
    this.description = obj?.description;
    this.startRecipeDate = obj?.startRecipeDate;
    this.endRecipeDate = obj?.endRecipeDate;
    if (obj?.crop) this.crop = new Crop(obj.crop);
    if (obj?.product) this.product = new PhytosanitaryProduct(obj.product);
    if (obj?.registeredProduct)
      this.registeredProduct = new RegisteredPhytosanitaryProduct(
        obj.registeredProduct
      );
    this.productsUses = obj?.productsUses?.map(
      (productUse: any) => new VadeProductUse(productUse)
    );
    this.weeds = obj?.weeds?.map((weed: any) => new PlantWeed(weed));
    this.plagues = obj?.plagues?.map((plague: any) => new PlantPlague(plague));
    this.diseases = obj?.diseases?.map(
      (disease: any) => new PlantDisease(disease)
    );
    this.regulators = obj?.regulators?.map(
      (regulator: any) => new PlantRegulatorsAndOthers(regulator)
    );
    this.observations = obj?.observations;
    this.dose = obj?.dose;
    this.doseUnit = new MeasurementUnit(obj?.doseUnit);
    this.justifications = obj?.justifications?.map(
      (justification: any) =>
        new PhytosanitaryActionJustificationType(justification)
    );
    this.enabledDate = obj?.enabledDate;
    this.disabledDate = obj?.disabledDate;
  }

  mapBaseToApiModel(): any {
    const obj: any = { ...this };
    obj.advisor = this.advisor?.mapToApiModel();
    obj.crop = this.crop?.mapToApiModel();
    obj.product = this.product?.mapToApiModel();
    obj.registeredProduct = this.registeredProduct?.mapToApiModel();
    obj.productsUses = this.productsUses?.map((productUse: VadeProductUse) =>
      productUse?.mapToApiModel()
    );
    obj.weeds = this.weeds?.map((weed: PlantWeed) => weed?.mapToApiModel());
    obj.plagues = this.plagues?.map((plague: PlantPlague) =>
      plague?.mapToApiModel()
    );
    obj.diseases = this.diseases?.map((disease: PlantDisease) =>
      disease?.mapToApiModel()
    );
    obj.regulators = this.regulators?.map(
      (regulator: PlantRegulatorsAndOthers) => regulator?.mapToApiModel()
    );
    obj.doseUnit = this.doseUnit?.mapBaseToApiModel();
    obj.justifications = this.justifications?.map(
      (justification: PhytosanitaryActionJustificationType) =>
        justification?.mapBaseToApiModel()
    );
    return obj;
  }
}

export default FBPhytoRecipe;
