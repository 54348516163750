import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import i18n from "../config/configI18n";

import {
  FormGroup,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  SelectChangeEvent,
  CircularProgress,
  Box,
  useMediaQuery,
} from "@mui/material";
import { ArrowDropDown } from "@mui/icons-material";

import ScreenContentLayout from "../components/ScreenContentLayout";
import TestBanner from "../components/banners/TestBanner";
import { BrioCard } from "../components/BrioCard";
import AlertSnackbar from "../components/elements/AlertSnackbar";

import { useAuth } from "../hooks/useAuth";
import useCrud from "../hooks/useCrud";

import { LANGUAGE_LIST } from "../constants/lists";
import { LanguageInterface, SnackbarInterface } from "../constants/interfaces";

const SettingsScreen = () => {
  const isLargeScreen = useMediaQuery("(min-width:840px)");
  const { user } = useAuth();
  const navigate = useNavigate();

  const [selectedLanguage, setSelectedLanguage] =
    useState<LanguageInterface | null>(null);
  const [snackbarMsg, setSnackbarMsg] = useState<SnackbarInterface | null>(
    null
  );

  useEffect(() => {
    const userLanguageCode = user?.person?.language || i18n.language;
    if (userLanguageCode && userLanguageCode !== selectedLanguage?.code) {
      setSelectedLanguage(
        LANGUAGE_LIST.find((lng) => lng.code === userLanguageCode) || null
      );
    }
  }, [user]);

  const updateUserLanguageMutation = useCrud({
    key: "putPersonLanguage",
    onSuccess: () => navigate(0),
    onError: setSnackbarMsg,
  });

  const handleChangeLanguage = (event: SelectChangeEvent) => {
    const code = event.target.value;
    updateUserLanguageMutation.mutate(code);
  };

  return (
    <ScreenContentLayout>
      <TestBanner />
      <AlertSnackbar
        open={!!snackbarMsg}
        snackbarMsg={snackbarMsg}
        onClose={() => setSnackbarMsg(null)}
      />
      <Box sx={{ mt: isLargeScreen ? "8px" : 0 }}>
        <BrioCard
          title={i18n.t("words.settings")}
          defaultExpanded
          disableAccordion
        >
          <FormGroup className="form-group">
            <FormControl variant="outlined" className="form-control">
              <InputLabel>{i18n.t("words.language")}</InputLabel>
              <Select
                label={i18n.t("words.language")}
                value={selectedLanguage?.code || ""}
                disabled={updateUserLanguageMutation.isLoading}
                IconComponent={() =>
                  updateUserLanguageMutation.isLoading ? (
                    <CircularProgress size={20} sx={{ mr: 2 }} />
                  ) : (
                    <ArrowDropDown sx={{ mr: 2, color: "grey" }} />
                  )
                }
                onChange={handleChangeLanguage}
              >
                {LANGUAGE_LIST.map((lng) => (
                  <MenuItem key={lng.id} value={lng.code}>
                    {lng.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </FormGroup>
        </BrioCard>
      </Box>
    </ScreenContentLayout>
  );
};

export default SettingsScreen;
