import { createContext, useState, useMemo, useContext, useEffect } from "react";
import { getAuth } from "firebase/auth";
import { Client } from "@stomp/stompjs";
import i18n from "../config/configI18n";

import { useAuth } from "../hooks/useAuth";

interface ChatInterface {
  chatClient: Client | null;
  isLoading: boolean;
  isConnected: boolean;
}
const ChatContext = createContext<ChatInterface>({} as ChatInterface);

interface Props {
  children: React.ReactNode;
  onError?: (message: string) => void;
}
export const ChatProvider = (props: Props) => {
  const { children, onError } = props;

  const { user } = useAuth();
  const auth = getAuth();

  const [chatClient, setChatClient] = useState<Client | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isConnected, setIsConnected] = useState<boolean>(false);

  const connectToChatBroker = async () => {
    // Refresh idToken to avoid expiration when connecting
    const idToken = (await auth.currentUser?.getIdToken()) || user?.idToken;
    const client = new Client({
      brokerURL: process.env.REACT_APP_CHAT_BROKER_URL,
      connectHeaders: {
        Authorization: `Bearer ${idToken}`,
      },
      onConnect: () => {
        setIsConnected(true);
        setIsLoading(false);
      },
      onDisconnect: () => {
        setIsConnected(false);
        setIsLoading(false);
      },
      onWebSocketClose: () => {
        setIsConnected(false);
        setIsLoading(false);
      },
      onStompError: (frame) => {
        setIsConnected(false);
        setIsLoading(false);
        onError?.(i18n.t("apiResponses.chatConnectionError"));
        console.log(`Broker reported error: ${frame.headers.message}`);
        console.log(`Additional details: ${frame.body}`);
      },
    });
    client.debug = (str) => {
      console.log(str);
    };
    client.activate();
    setChatClient(client);
  };

  // Websocket connection
  useEffect(() => {
    if (!user?.idToken) {
      if (chatClient) chatClient.deactivate();
      setIsConnected(false);
      setIsLoading(false);
      return;
    }
    if (!chatClient?.connected) connectToChatBroker();
    else setIsLoading(false);

    return () => {
      chatClient?.deactivate();
    };
  }, [user]);

  const value = useMemo(
    () => ({ chatClient, isLoading, isConnected }),
    [chatClient, isLoading, isConnected]
  );

  return <ChatContext.Provider value={value}>{children}</ChatContext.Provider>;
};
export const useChat = (): ChatInterface => {
  return useContext<ChatInterface>(ChatContext);
};
