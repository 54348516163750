import React from "react";

import { Button, Typography } from "@mui/material";
import { Image } from "@mui/icons-material";

interface EmptyTableProps {
  icon?: React.ReactNode;
  title?: string;
  subtitle?: string;
  btnText?: string;
  onClick?: () => void;
}
const EmptyTable = (props: EmptyTableProps) => {
  const { icon = <Image />, title = "", subtitle, btnText, onClick } = props;
  const subtitleByLines = subtitle?.split("\n");

  return (
    <div className="empty-table">
      <div className="icon">{icon}</div>
      <Typography
        variant="subtitle1"
        sx={{ textAlign: "center", mt: 1, width: "60%" }}
      >
        {title}
      </Typography>
      {subtitleByLines && (
        <Typography
          variant="subtitle2"
          sx={{ textAlign: "center", mb: 2, width: "60%" }}
        >
          {subtitleByLines.map((line, index) => (
            <span key={index}>
              {subtitleByLines.length > 1 && <hr />}
              {line}
            </span>
          ))}
        </Typography>
      )}
      {btnText && (
        <Button variant="contained" size="large" onClick={onClick}>
          {btnText}
        </Button>
      )}
    </div>
  );
};

export default EmptyTable;
