import { useState, useEffect, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { FormikProps, FormikTouched, useFormik } from "formik";
import i18n from "../config/configI18n";
import * as Yup from "yup";

import {
  Autocomplete,
  Collapse,
  FormControl,
  FormGroup,
  TextField,
} from "@mui/material";
import { Search, Groups2 } from "@mui/icons-material";
import { MuiTelInput, matchIsValidTel } from "mui-tel-input";

import ScreenContentLayout from "../components/ScreenContentLayout";
import LoadingWithDelay from "../components/elements/LoadingWithDelay";
import TestBanner from "../components/banners/TestBanner";
import AlertSnackbar from "../components/elements/AlertSnackbar";
import FormAlertDialog from "../components/dialogs/FormAlertDialog";
import { BrioCard, TableBrioCard } from "../components/BrioCard";
import RightDrawer from "../components/RightDrawer";
import FormikTextField from "../components/formik/FormikTextField";
import FormikSelect from "../components/formik/FormikSelect";
import FormikListItemSwitch from "../components/formik/FormikListItemSwitch";
import EmptyTable from "../components/EmptyTable";
import ErrorScreen from "./ErrorScreen";

import { useSession } from "../hooks/useSession";
import useFetch from "../hooks/useFetch";
import useCrud from "../hooks/useCrud";

import AgroBusinessAccount from "../models/account/AgroBusinessAccount";
import AgroBusinessAccountPerson from "../models/account/AgroBusinessAccountPerson";
import Person from "../models/Person";

import {
  Column,
  PersonPermissionRoleInterface,
  SnackbarInterface,
} from "../constants/interfaces";
import { FBPersonRole, FormMode } from "../constants/enums";
import { PERSON_PERMISSION_ROLES_LIST } from "../providers/YupProvider";
import { EMPTY_TABLE_ICON_SIZE } from "../constants/constants";
import { helperTextStyle } from "../components/formik/FormikAutocomplete";
import { PROTECTED_ROUTES } from "../routes/routeNames";

const validatePhone = (values: AgroBusinessAccountPerson) => {
  const errors: any = {};
  if (
    values.whatsappEnabled &&
    !matchIsValidTel(values.person?.whatsappNumber || "")
  )
    errors.person = {
      whatsappNumber: i18n.t("formErrors.invalidPhoneNumber"),
    };
  return errors;
};

const ExploitationsPermissionsScreen = () => {
  const ExploitationPermissionValidatorSchema = Yup.object().shape({
    selectedAgroBusinessAccount: Yup.object({
      id: Yup.number().required(),
    }).required(),
    person: Yup.object({
      email: Yup.string().email().required(),
    }).required(),
    personRole: Yup.object({
      role: Yup.string().required(),
    }).required(),
  });

  const exploitationPermissionsColumns: Column[] = [
    {
      id: "person.email",
      label: i18n.t("exploitationPermissions.column.email"),
      numeric: false,
    },
    {
      id: "personRole.name",
      label: i18n.t("exploitationPermissions.column.personRole"),
      numeric: false,
    },
    {
      id: "whatsappEnabled",
      label: i18n.t("exploitationPermissions.column.whatsappEnabled"),
      numeric: false,
      valueLabel: "boolean",
    },
  ];

  const location = useLocation();
  const navigate = useNavigate();
  const { selectedCueAccount } = useSession();

  const [isOpenDrawer, setIsOpenDrawer] = useState<boolean>(false);
  const [snackbarMsg, setSnackbarMsg] = useState<SnackbarInterface | null>(
    null
  );
  const [isOpenBackAlertDialog, setIsOpenBackAlertDialog] =
    useState<boolean>(false);
  const [isOpenConfirmDeleteAlertDialog, setIsOpenConfirmDeleteAlertDialog] =
    useState<boolean>(false);
  const [formMode, setFormMode] = useState<FormMode | undefined>(undefined);
  const [selectedRowIds, setSelectedRowIds] = useState<number[]>([]);
  const [selectedAgroBusinessAccount, setSelectedAgroBusinessAccount] =
    useState<AgroBusinessAccount | null>(null);

  const handleSubmit = (values: AgroBusinessAccountPerson) => {
    switch (formMode) {
      case FormMode.CREATE:
      case FormMode.EDIT:
        abaPermissionEditMutation.mutate(values);
        break;
      default:
        break;
    }
  };

  const formik = useFormik({
    initialValues: new AgroBusinessAccountPerson({ whatsappEnabled: true }),
    validationSchema: ExploitationPermissionValidatorSchema,
    validate: validatePhone,
    onSubmit: handleSubmit,
  });

  // Set first agroBusinessAccount as default value
  const handleAgroBusinessAccountsResponse = (
    agroBusinessAccounts: AgroBusinessAccount[]
  ) => {
    if (agroBusinessAccounts.length > 0) {
      setSelectedAgroBusinessAccount(agroBusinessAccounts[0]);
    } else setSelectedAgroBusinessAccount(null);
  };

  const { data: agroBusinessAccounts, isFetching: isFetchingABAs } = useFetch<
    AgroBusinessAccount[]
  >({
    queryKey: ["agroBusinessAccounts", selectedCueAccount?.cueAccount?.id],
    onSuccess: handleAgroBusinessAccountsResponse,
    enabled: !!selectedCueAccount,
  });

  const {
    data: agroBusinessAccountPermissions,
    isFetching: isFetchingPermissions,
  } = useFetch<AgroBusinessAccountPerson[]>({
    queryKey: [
      "agroBusinessAccountPermissions",
      selectedAgroBusinessAccount?.id,
    ],
    selected: selectedAgroBusinessAccount,
    enabled: !!selectedAgroBusinessAccount?.id,
  });

  const drawerTitle =
    formik.status === FormMode.CREATE
      ? i18n.t("exploitationPermissions.drawerTitleCreate")
      : i18n.t("exploitationPermissions.drawerTitleEdit");
  const drawerBtnText =
    formik.status === FormMode.CREATE
      ? i18n.t("words.create")
      : i18n.t("words.update");

  // Open drawer of files form if url contains respective path
  useEffect(() => {
    setIsOpenDrawer(location.pathname.includes("/edit"));
  }, [location.pathname]);

  // Handle browser back button
  useEffect(() => {
    const handleBackButton = (event: any) => {
      event.preventDefault();
      if (isOpenDrawer && formik.dirty && formik.status === FormMode.CREATE) {
        openBackAlertDialog();
        navigate(`${location.pathname}`);
      }
    };

    window.addEventListener("popstate", handleBackButton);

    return () => {
      window.removeEventListener("popstate", handleBackButton);
    };
  }, [isOpenDrawer, formik.values]);

  const clearForm = () => {
    setFormMode(FormMode.CREATE);
    formik.resetForm();
    formik.setErrors({});
    formik.setStatus(FormMode.CREATE);
  };
  const openDrawer = (formMode: FormMode) => {
    if (formMode === FormMode.CREATE) clearForm();
    formik.setFieldValue(
      "selectedAgroBusinessAccount",
      selectedAgroBusinessAccount
    ); // It is reset when drawer is closed
    setFormMode(formMode);
    navigate(`${location.pathname}/edit`);
  };
  const closeDrawer = () => {
    setIsOpenDrawer(false);
    navigate(-1);
  };
  const openBackAlertDialog = () => setIsOpenBackAlertDialog(true);
  const cancelBackAlertDialog = () => setIsOpenBackAlertDialog(false);
  const openConfirmDeleteAlertDialog = () =>
    setIsOpenConfirmDeleteAlertDialog(true);
  const cancelConfirmDeleteAlertDialog = () =>
    setIsOpenConfirmDeleteAlertDialog(false);

  const closeDialogAndUnselectedRows = () => {
    closeDrawer();
    setSelectedRowIds([]);
    cancelConfirmDeleteAlertDialog();
  };

  const manageCrudError = (snackBarError: SnackbarInterface) => {
    if (snackBarError?.hasDocError) closeDialogAndUnselectedRows();
    setSnackbarMsg(snackBarError);
  };

  const abaPermissionEditMutation = useCrud<AgroBusinessAccountPerson>({
    key: "putAgroBusinessAccountPermission",
    values: formik.values,
    onSuccess: () => {
      setSnackbarMsg({
        severity: "success",
        message: i18n.t("apiResponses.updateSuccess"),
      });
      closeDialogAndUnselectedRows();
    },
    onError: manageCrudError,
  });

  const abaPermissionDeleteMutation = useCrud({
    key: "deleteAgroBusinessAccountPermissions",
    values: agroBusinessAccountPermissions
      ?.filter((abap: AgroBusinessAccountPerson) =>
        selectedRowIds.includes(abap?.idx || 0)
      )
      .map((abap) => ({ ...abap, selectedAgroBusinessAccount })), // Add selectedAgroBusinessAccount to the values
    onSuccess: () => {
      setSnackbarMsg({
        severity: "success",
        message: i18n.t("apiResponses.deleteSuccess"),
      });
      setSelectedRowIds([]);
      cancelConfirmDeleteAlertDialog();
    },
    onError: (error: SnackbarInterface) => {
      setSnackbarMsg(error);
      cancelConfirmDeleteAlertDialog();
    },
  });

  const handleClickCloseDrawer = () => {
    formik.dirty && formik.status === FormMode.CREATE
      ? openBackAlertDialog()
      : closeDrawer();
  };

  const handleClickAdd = () => {
    formik.setStatus(FormMode.CREATE);
    setFormMode(FormMode.CREATE);
    openDrawer(FormMode.CREATE);
  };

  const handleClickSave = async () => {
    const errors = await formik.validateForm();
    if (Object.keys(errors).length > 0) {
      setSnackbarMsg({
        severity: "warning",
        message: i18n.t("formErrors.requiredFields"),
      });
      // Mark all fields as touched to show errors
      formik.setTouched(errors as FormikTouched<any>);
    } else formik.submitForm();
  };

  const handleClickEdit = (id: number) => {
    const selectedAgroBusinessAccountPermission =
      agroBusinessAccountPermissions?.find(
        (abap: AgroBusinessAccountPerson) => abap.idx === id
      );
    if (selectedAgroBusinessAccountPermission)
      formik.setValues(selectedAgroBusinessAccountPermission);
    formik.setStatus(FormMode.EDIT);
    setFormMode(FormMode.EDIT);
    openDrawer(FormMode.EDIT);
  };

  const handleClickDelete = (ids: number[]) => {
    if (ids.length > 0) {
      formik.setStatus(FormMode.DELETE);
      setFormMode(FormMode.DELETE);
      openConfirmDeleteAlertDialog();
    }
  };

  const handleOnFormError = (snackBarError: SnackbarInterface) => {
    setSnackbarMsg(snackBarError);
  };

  const handleConfirmBackAlertDialog = () => {
    cancelBackAlertDialog();
    closeDrawer();
  };

  const handleConfirmDeleteAlertDialog = () => {
    abaPermissionDeleteMutation.mutate(selectedRowIds);
  };

  const handleChangeSelectedAgroBusinessAccount = (
    event: React.SyntheticEvent,
    newValue: AgroBusinessAccount | null
  ) => {
    setSelectedAgroBusinessAccount(newValue);
    formik.setFieldValue("selectedAgroBusinessAccount", newValue);
  };

  const handleClickGoExploitations = () => {
    navigate(`/${PROTECTED_ROUTES.EXPLOITATIONS}`);
  };

  const TableFiltersMemo = useMemo(() => {
    return (
      <div className="content-table-filters">
        <Autocomplete
          id="agroBusinessAccounts-filter"
          options={agroBusinessAccounts || []}
          getOptionLabel={(option) => option?.name || ""}
          noOptionsText={i18n.t("formErrors.notFoundResults")}
          value={selectedAgroBusinessAccount}
          onChange={handleChangeSelectedAgroBusinessAccount}
          renderOption={(props, option) => (
            <li {...props} key={option.id}>
              {option.name}
            </li>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              size="small"
              sx={{ minWidth: 250, maxWidth: 300 }}
              label={i18n.t(
                "exploitationPermissions.agrobusinessAccountFilterLabel"
              )}
              placeholder={i18n.t(
                "exploitationPermissions.agrobusinessAccountFilterPlaceholder"
              )}
              InputProps={{ ...params.InputProps, startAdornment: <Search /> }}
            />
          )}
        />
      </div>
    );
  }, [selectedAgroBusinessAccount]);

  if (selectedCueAccount && selectedCueAccount.role !== FBPersonRole.ADMIN)
    return <ErrorScreen errorStatus={401} />;

  if (agroBusinessAccounts?.length === 0)
    return (
      <EmptyTable
        icon={<Groups2 sx={{ fontSize: EMPTY_TABLE_ICON_SIZE }} />}
        title={i18n.t("exploitationPermissions.emptyAgroBusinessAccountsTitle")}
        subtitle={i18n.t(
          "exploitationPermissions.emptyAgroBusinessAccountsSubtitle"
        )}
        btnText="Crear explotación ahora"
        onClick={handleClickGoExploitations}
      />
    );

  return (
    <ScreenContentLayout>
      <LoadingWithDelay isVisible={isFetchingABAs || isFetchingPermissions} />
      <TestBanner />
      <AlertSnackbar
        open={!!snackbarMsg}
        snackbarMsg={snackbarMsg}
        onClose={() => setSnackbarMsg(null)}
      />
      <FormAlertDialog
        id="backAlertDialog"
        title={i18n.t("exploitationPermissions.backAlertTitle")}
        contentText={i18n.t("exploitationPermissions.backAlertDescription")}
        open={isOpenBackAlertDialog}
        formAction={formMode}
        onCancel={cancelBackAlertDialog}
        onConfirm={handleConfirmBackAlertDialog}
      />
      <FormAlertDialog
        id="confirmDeleteAlertDialog"
        title={i18n.t("exploitationPermissions.confirmDeleteAlertDialogTitle")}
        contentText={i18n.t(
          "exploitationPermissions.confirmDeleteAlertDialogDescription"
        )}
        open={isOpenConfirmDeleteAlertDialog}
        formAction={formMode}
        isLoading={abaPermissionDeleteMutation.isLoading}
        onCancel={cancelConfirmDeleteAlertDialog}
        onConfirm={handleConfirmDeleteAlertDialog}
      />
      <TableBrioCard
        idx="idx"
        title={i18n.t("exploitationPermissions.tableTitle")}
        colums={exploitationPermissionsColumns}
        rows={agroBusinessAccountPermissions}
        selectedRows={selectedRowIds}
        emptyTableIcon={<Groups2 sx={{ fontSize: EMPTY_TABLE_ICON_SIZE }} />}
        emptyTableTitle={i18n.t("exploitationPermissions.emptyTableTitle")}
        emptyTableSubtitle={i18n.t(
          "exploitationPermissions.emptyTableSubtitle"
        )}
        emptyTableBtnText={i18n.t("exploitationPermissions.emptyTableBtnText")}
        isLoading={isFetchingPermissions}
        addBtnVariant="contained"
        filtersChildren={TableFiltersMemo}
        onChangeSelectedRows={setSelectedRowIds}
        onClickAdd={handleClickAdd}
        onClickEdit={handleClickEdit}
        onClickDelete={handleClickDelete}
      />
      <RightDrawer
        title={drawerTitle}
        titleBtn={drawerBtnText}
        isOpen={isOpenDrawer}
        isLoading={abaPermissionEditMutation.isLoading}
        onClose={handleClickCloseDrawer}
        onConfirm={handleClickSave}
      >
        <AgroBusinessAccountPermissionForm
          formik={formik}
          selectedEditRow={
            formMode === FormMode.EDIT && selectedRowIds.length === 1
              ? formik.values
              : undefined
          }
          onError={handleOnFormError}
        />
      </RightDrawer>
    </ScreenContentLayout>
  );
};

export default ExploitationsPermissionsScreen;

interface AgroBusinessAccountPermissionFormProps {
  formik: FormikProps<AgroBusinessAccountPerson>;
  selectedEditRow?: AgroBusinessAccountPerson;
  onError?: (snackBarError: SnackbarInterface) => void;
}

const AgroBusinessAccountPermissionForm = (
  props: AgroBusinessAccountPermissionFormProps
) => {
  const { formik, selectedEditRow } = props;

  useEffect(() => {
    if (selectedEditRow) formik.setValues(selectedEditRow);
  }, [selectedEditRow]);

  return (
    <>
      <CustomerDataForm {...props} />
      <CustomerPermissionsForm {...props} />
    </>
  );
};

const CustomerDataForm = (props: AgroBusinessAccountPermissionFormProps) => {
  const { formik } = props;

  return (
    <BrioCard
      title={i18n.t("exploitationPermissions.customerDataForm.title")}
      disableAccordion
      defaultExpanded
    >
      <FormGroup className="form-group">
        <FormControl variant="outlined" className="form-control">
          <FormikTextField
            formik={formik}
            id="email"
            name="person.email"
            label={i18n.t(
              "exploitationPermissions.customerDataForm.emailLabel"
            )}
            type="email"
            required
          />
        </FormControl>
      </FormGroup>
    </BrioCard>
  );
};

const CustomerPermissionsForm = (
  props: AgroBusinessAccountPermissionFormProps
) => {
  const { formik } = props;

  const getHelperText = (permission?: PersonPermissionRoleInterface) => {
    switch (permission?.role) {
      case FBPersonRole.ADMIN:
        return i18n.t("exploitationPermissions.adminPermissionHelperText");
      case FBPersonRole.WRITER:
        return i18n.t("exploitationPermissions.writerPermissionHelperText");
      case FBPersonRole.READER:
        return i18n.t("exploitationPermissions.readerPermissionHelperText");
      case FBPersonRole.NONE:
        return i18n.t("exploitationPermissions.nonePermissionHelperText");
      default:
        return i18n.t("exploitationPermissions.defaultPermissionHelperText");
    }
  };

  const handleChangePhoneNumber = (phone: string) => {
    formik.setFieldTouched("person.whatsappNumber", true);
    formik.setFieldValue("person.whatsappNumber", phone);
  };

  return (
    <BrioCard
      title={i18n.t("exploitationPermissions.customerPermissionsForm.title")}
      disableAccordion
      defaultExpanded
    >
      <FormGroup className="form-group">
        <FormControl variant="outlined" className="form-control">
          <FormikSelect
            formik={formik}
            name="personRole"
            label={i18n.t(
              "exploitationPermissions.customerPermissionsForm.personRoleLabel"
            )}
            helperText={getHelperText(formik.values.personRole)}
            required
            options={PERSON_PERMISSION_ROLES_LIST}
            optionLabelFieldName="name"
          />
        </FormControl>
        <FormControl className="form-control" sx={{ mb: "0px !important" }}>
          <FormikListItemSwitch
            formik={formik}
            name="whatsappEnabled"
            primaryText={i18n.t(
              "exploitationPermissions.customerPermissionsForm.whatsappEnabledLabel"
            )}
            secondaryText={i18n.t(
              "exploitationPermissions.customerPermissionsForm.whatsappEnabledHelperText"
            )}
          />
        </FormControl>
        <Collapse in={formik.values.whatsappEnabled}>
          <div className="grow-container">
            <FormControl className="form-control">
              <MuiTelInput
                id="whatsappNumber"
                fullWidth
                required={formik.values.whatsappEnabled}
                error={
                  !!(formik.touched.person as any)?.whatsappNumber &&
                  !!(formik.errors?.person as Person)?.whatsappNumber
                }
                className="form-input"
                FormHelperTextProps={{ style: helperTextStyle }}
                helperText={
                  !!(formik.touched.person as any)?.whatsappNumber &&
                  (formik.errors?.person as Person)?.whatsappNumber
                }
                defaultCountry="ES"
                langOfCountryName="ES"
                value={formik.values.person?.whatsappNumber}
                onChange={handleChangePhoneNumber}
              />
            </FormControl>
          </div>
        </Collapse>
      </FormGroup>
    </BrioCard>
  );
};
