import moment from "moment";

import { Avatar, Fade } from "@mui/material";

import MessageStatusIcon from "./ChatMessageStatusIcon";

import AdvisorChat from "../../models/advisor/AdvisorChat";

import { chatMsgDateHumanizer } from "../../helpers/utils";

interface Props {
  advisorChat: AdvisorChat;
  selected?: boolean;
  onClick?: (advisorChat: AdvisorChat) => void;
}
const ChatListItem = (props: Props) => {
  const { advisorChat, selected, onClick } = props;

  const userName =
    advisorChat?.profileName || advisorChat?.person?.email || "B";
  const avatarLetter = userName?.substring(0, 1).toUpperCase();
  const title = `${userName}${
    advisorChat?.defaultAgroBusinessAccount?.name
      ? ` - ${advisorChat?.defaultAgroBusinessAccount?.name}`
      : ""
  }`;
  const subtitle = advisorChat.lastMessage?.messageText || "";
  const dateHumanized = chatMsgDateHumanizer(
    advisorChat.lastMessage?.messageTimestamp
      ? moment(advisorChat.lastMessage.messageTimestamp * 1000)
      : undefined
  );

  const handleClick = () => {
    if (onClick) onClick(advisorChat);
  };

  return (
    <div
      className={`chat-list-item${selected ? "-selected" : ""}`}
      onClick={handleClick}
    >
      <Avatar className="avatar">{avatarLetter}</Avatar>
      <div className="content">
        <span className="title">{title}</span>
        <div className="subtitle-container">
          <MessageStatusIcon message={advisorChat?.lastMessage} />
          <span className="subtitle">{subtitle}</span>
        </div>
      </div>
      <div className="right-content">
        <span className="last-msg-date">{dateHumanized}</span>
        <UnreadMessagesBadge
          unreadMessagesCount={advisorChat.unreadMessagesCount}
        />
      </div>
    </div>
  );
};

export default ChatListItem;

interface UnreadMessagesBadgeProps {
  unreadMessagesCount?: number;
}
const UnreadMessagesBadge = (props: UnreadMessagesBadgeProps) => {
  const { unreadMessagesCount = 0 } = props;

  return (
    <Fade in={unreadMessagesCount > 0}>
      <Avatar className="unread-msgs-container">
        {unreadMessagesCount > 0 && (
          <span className="unread-msgs-title">{unreadMessagesCount}</span>
        )}
      </Avatar>
    </Fade>
  );
};
