import FBRegisterRegion from "./_api/FBRegisterRegion";

class RegisterRegion extends FBRegisterRegion {
  constructor(obj?: any) {
    super(obj);
  }

  mapToApiModel(): any {
    const obj: any = super.mapBaseToApiModel();
    return obj;
  }
}

export default RegisterRegion;
