import { ArrowBack } from "@mui/icons-material";
import { AppBar, Drawer, IconButton, Toolbar } from "@mui/material";
import { LoadingButton } from "@mui/lab";

interface Props {
  isOpen?: boolean;
  isLoading?: boolean;
  title?: string;
  subtitle?: string;
  titleBtn?: string;
  actionBtn?: boolean;
  iconBtn?: React.ReactNode;
  onClose?(): any;
  onConfirm?(): any;
  onClickIconBtn?(): any;
  children?: React.ReactNode;
}

const RightDrawer = (props: Props) => {
  const {
    title = "Title",
    subtitle,
    titleBtn = "Save",
    isOpen,
    isLoading = false,
    actionBtn = true,
    iconBtn,
    onClose,
    onConfirm,
    onClickIconBtn,
    children,
  } = props;

  return (
    <Drawer
      open={isOpen}
      anchor="right"
      className="right-drawer"
      PaperProps={{ className: "right-drawer" }}
    >
      <div className="content">
        <AppBar
          component="nav"
          className="appbar"
          position="sticky"
          elevation={0}
        >
          <Toolbar className="header">
            <div className="header-left">
              <IconButton onClick={onClose}>
                <ArrowBack fontSize="large" color="action" />
              </IconButton>
              <div className="header-texts">
                <h1>{title}</h1>
                {subtitle && <h2>{subtitle}</h2>}
              </div>
            </div>

            <div className="header-right">
              {iconBtn && (
                <IconButton size="large" onClick={onClickIconBtn}>
                  {iconBtn}
                </IconButton>
              )}
              {actionBtn && (
                <LoadingButton
                  id="save-btn"
                  variant="contained"
                  className="btn"
                  type="submit"
                  disableElevation
                  loading={isLoading}
                  onClick={onConfirm}
                >
                  {titleBtn}
                </LoadingButton>
              )}
            </div>
          </Toolbar>
        </AppBar>
        <div className="children">{children}</div>
      </div>
    </Drawer>
  );
};

export default RightDrawer;
