import moment from "moment";

import FBRegisteredPhytosanitaryProduct from "../_api/vademecum/FBRegisteredPhytosanitaryProduct";

class RegisteredPhytosanitaryProduct extends FBRegisteredPhytosanitaryProduct {
  expirationDateFormatted?: string;
  cancellationDateFormatted?: string;
  saleDeadlineDateFormatted?: string;
  registrationDateFormatted?: string;
  otherNamesAsString?: string;
  isExpired?: boolean;
  isRegisterCancelled?: boolean;

  constructor(obj?: any) {
    super(obj);
    if (obj?.expirationDate)
      this.expirationDateFormatted = moment(
        obj.expirationDate,
        "YYYY-MM-DD"
      ).format("DD/MM/YY");
    if (obj?.cancellationDate)
      this.cancellationDateFormatted = moment(
        obj.cancellationDate,
        "YYYY-MM-DD"
      ).format("DD/MM/YY");
    if (obj?.saleDeadlineDate)
      this.saleDeadlineDateFormatted = moment(
        obj.saleDeadlineDate,
        "YYYY-MM-DD"
      ).format("DD/MM/YY");
    if (obj?.registrationDate)
      this.registrationDateFormatted = moment(
        obj.registrationDate,
        "YYYY-MM-DD"
      ).format("DD/MM/YY");
    this.otherNamesAsString = obj?.otherNames
      ?.map((item: any) => item.otherName)
      .join(", ");
    this.isExpired = obj?.expirationDate
      ? moment(obj.expirationDate, "YYYY-MM-DD").isBefore(moment())
      : false;
    //TODO: Translate from server
    if (
      obj?.registerStatus &&
      ["Vigente", "Cancelado"].includes(obj.registerStatus)
    )
      this.isRegisterCancelled = obj.registerStatus === "Cancelado";
  }

  mapToApiModel(): any {
    const obj: any = super.mapBaseToApiModel();

    delete obj.expirationDateFormatted;
    delete obj.cancellationDateFormatted;
    delete obj.saleDeadlineDateFormatted;
    delete obj.registrationDateFormatted;
    delete obj.otherNamesAsString;
    delete obj.isExpired;
    delete obj.isRegisterCancelled;
    return obj;
  }
}

export default RegisteredPhytosanitaryProduct;
