import React, { useState, useEffect, useMemo } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import i18n from "../config/configI18n";

import {
  Drawer,
  List,
  ListItem,
  Collapse,
  IconButton,
  Toolbar,
  useTheme,
  useMediaQuery,
  Fade,
} from "@mui/material";
import {
  MenuOpen,
  ExpandLess,
  ExpandMore,
  CorporateFare,
  Groups2,
  StickyNote2,
  Badge,
  Science,
  MenuBook,
  Link,
  Receipt,
  CircleNotifications,
  GasMeter,
} from "@mui/icons-material";

import { useAuth } from "../hooks/useAuth";
import { useSession } from "../hooks/useSession";
import useFetch from "../hooks/useFetch";

import Notification from "../models/notification/Notification";

import { PROTECTED_ROUTES } from "../routes/routeNames";
import { MenuOption } from "../constants/interfaces";
import { FBPersonRole } from "../constants/enums";
import { REFRESH_PHYTO_RECIPES_NOTIFICATION_INTERVAL } from "../constants/constants";

const getUserMenuOptions = (
  userRole: FBPersonRole,
  nNotifications?: number
): MenuOption[] => {
  return [
    {
      id: "exploitations-list",
      label: i18n.t("components.sidebar.exploitationListLabel"),
      icon: <CorporateFare />,
      route: PROTECTED_ROUTES.EXPLOITATIONS,
    },
    {
      id: "exploitations-permissions",
      label: i18n.t("components.sidebar.exploitationPermissionsLabel"),
      icon: <Groups2 />,
      route: PROTECTED_ROUTES.EXPLOITATIONS_PERMISSIONS,
      hidden: userRole !== FBPersonRole.ADMIN,
    },
    {
      id: "notes",
      label: i18n.t("components.sidebar.notesLabel"),
      icon: <StickyNote2 />,
      route: PROTECTED_ROUTES.NOTES,
    },
    {
      id: "staff",
      label: i18n.t("components.sidebar.staffLabel"),
      icon: <Badge />,
      route: PROTECTED_ROUTES.STAFF,
      hidden: userRole !== FBPersonRole.ADMIN,
    },
    {
      id: "exploit-recipes",
      label: i18n.t("components.sidebar.exploitRecipes"),
      icon: <Receipt />,
      route: PROTECTED_ROUTES.EXPLOITATION_RECIPES,
      nNotifications,
    },
    {
      id: "vademecum",
      label: i18n.t("components.sidebar.vademecum"),
      icon: <MenuBook />,
      children: [
        {
          id: "vademecum-phytosanitary",
          label: i18n.t("components.sidebar.vademecumPhytosanitary"),
          icon: <Science />,
          route: PROTECTED_ROUTES.VADEMECUM_PHYTO,
        },
        {
          id: "vademecum-fertilizer",
          label: i18n.t("components.sidebar.vademecumFertilizer"),
          icon: <GasMeter />,
          route: PROTECTED_ROUTES.VADEMECUM_FERTILIZER,
        },
      ],
    },
  ];
};

interface Props {
  isOpen?: boolean;
  setIsOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  options?: MenuOption[];
  onClose?(): any;
}

const Sidebar = ({ isOpen = false, setIsOpen, onClose }: Props) => {
  const { selectedCueAccount } = useSession();

  const [options, setOptions] = useState<MenuOption[]>([]);

  const { data: unreadNotifications } = useFetch<Notification[]>({
    queryKey: ["notifications", selectedCueAccount?.cueAccount?.id],
    refetchInterval: REFRESH_PHYTO_RECIPES_NOTIFICATION_INTERVAL,
    enabled: !!selectedCueAccount?.cueAccount?.id,
  });

  // Show permissions option only for admins
  useEffect(() => {
    const menuOptions = getUserMenuOptions(
      selectedCueAccount?.role || FBPersonRole.NONE,
      unreadNotifications?.length
    );
    setOptions(menuOptions);
  }, [selectedCueAccount, unreadNotifications]);

  return (
    <>
      <Drawer
        className="sidebar-temp-none"
        variant="temporary"
        anchor="left"
        ModalProps={{ keepMounted: true }}
        open={isOpen}
        onClose={onClose}
        PaperProps={{ className: "sidebar-temp" }}
      >
        <DrawerContent
          isOpen={isOpen}
          options={options}
          setIsOpen={setIsOpen}
          onClose={onClose}
        />
      </Drawer>

      <div className="sidebar-perm">
        <Drawer
          variant="permanent"
          anchor="left"
          open
          PaperProps={{ className: "sidebar-perm" }}
        >
          <DrawerContent isOpen options={options} />
        </Drawer>
      </div>
    </>
  );
};

export default Sidebar;

const DrawerContent = ({
  isOpen = true,
  setIsOpen,
  onClose,
  options,
}: Props) => {
  const { user } = useAuth();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("large"));
  const location = useLocation();
  const navigate = useNavigate();

  const vinculationNeeded = useMemo(
    () =>
      !user?.person?.email ||
      user.person.email.length === 0 ||
      !user?.person?.whatsappNumber,
    [user]
  );

  const [isExpanded, setIsExpanded] = useState<string[]>([]);

  // Expand sidebar options when the user navigates to a child route
  useEffect(() => {
    const pathId = options?.find((option) => {
      return option?.children?.find((child) =>
        location.pathname.includes(child?.route || "-1")
      );
    })?.id;
    if (pathId && !isExpanded.includes(pathId))
      setIsExpanded((prevExpanded) => [...prevExpanded, pathId]);
  }, [location.pathname]);

  useEffect(() => {
    if (setIsOpen) {
      if (isDesktop && !isOpen) setIsOpen(true);
      else if (!isDesktop && isOpen) setIsOpen(false);
    }
  }, [isDesktop]);

  const handleOnCollapse = (id: string) => {
    if (isExpanded.includes(id)) {
      setIsExpanded(isExpanded.filter((item) => item !== id));
    } else setIsExpanded([...isExpanded, id]);
  };

  const handleClick = (
    event: React.MouseEvent<HTMLAnchorElement>,
    option: MenuOption
  ) => {
    if (option.children && option.children.length > 0) {
      event.preventDefault();
      handleOnCollapse(option.id);
      if (option.children.length === 1 && option.children[0].route)
        navigate(option.children[0].route);
    } else if (setIsOpen) setIsOpen(false);
  };

  return (
    <div className="drawer-content">
      <List component="nav">
        {!isDesktop && (
          <ListItem disablePadding>
            <IconButton onClick={onClose}>
              <MenuOpen />
            </IconButton>
          </ListItem>
        )}

        {isDesktop && <Toolbar />}

        {options?.map((option) => (
          <div
            key={option.id}
            style={{ display: option.hidden ? "none" : "block" }}
          >
            <NavLink
              key={option.id}
              to={option.route || ""}
              className={({ isActive }) =>
                `list-item-container${
                  isActive && !option.children ? "-active" : ""
                }`
              }
              onClick={(event) => handleClick(event, option)}
            >
              <div className="item-container">
                <div className="left">
                  <div className="icon">{option.icon}</div>
                  <span>{option.label}</span>
                </div>
                <div className="right">
                  {option.children && option.children.length > 0 && (
                    <div className="icon">
                      {isExpanded.includes(option.id) ? (
                        <ExpandLess />
                      ) : (
                        <ExpandMore />
                      )}
                    </div>
                  )}
                  <Fade
                    in={
                      !!option.nNotifications && !!(option.nNotifications > 0)
                    }
                  >
                    <CircleNotifications color="success" />
                  </Fade>
                </div>
              </div>
            </NavLink>

            {option.children && option.children.length > 0 && (
              <Collapse
                in={isExpanded.includes(option.id)}
                timeout="auto"
                unmountOnExit
              >
                <List
                  className="collapsable-list"
                  component="div"
                  disablePadding
                >
                  {option.children.map((child) => (
                    <NavLink
                      key={child.id}
                      to={child.route || ""}
                      onClick={() => setIsOpen && setIsOpen(false)}
                      className={({ isActive }) =>
                        `list-subitem-container${
                          isActive
                            ? "-active"
                            : child.disabled
                            ? "-disabled"
                            : ""
                        }`
                      }
                    >
                      <div className="item-container">
                        <div className="left">
                          <div className="icon">{child.icon}</div>
                          <span>{child.label}</span>
                        </div>
                        <div className="right"></div>
                      </div>
                    </NavLink>
                  ))}
                </List>
              </Collapse>
            )}
          </div>
        ))}

        {vinculationNeeded && (
          <NavLink
            to={PROTECTED_ROUTES.ACCOUNT_SYNC}
            type="button"
            className={({ isActive }) =>
              `account-sync-item-container${isActive ? "-active" : ""}`
            }
          >
            <div className="item-container">
              <div className="left">
                <div className="icon">
                  <Link />
                </div>
                <span>
                  {user?.person?.whatsappNumber
                    ? i18n.t("components.sidebar.accountSyncEmail")
                    : i18n.t("components.sidebar.accountSyncWhatsapp")}
                </span>
              </div>
            </div>
          </NavLink>
        )}
      </List>
    </div>
  );
};
