import { capitalize } from "../../helpers/utils";
import FBCrop from "../_api/crop/FBCrop";
import CropProduct from "../catalogue/CropProduct";

class Crop extends FBCrop {
  product?: CropProduct;
  productName?: string;

  constructor(obj?: any) {
    super(obj);
    if (obj?.varieties && obj?.varieties?.length > 0) {
      // Varieties of the same product
      this.product = new CropProduct(obj.varieties[0].productType);
      this.productName = capitalize(this.product?.name || "");
    }
  }

  mapToApiModel(): any {
    const obj: any = super.mapBaseToApiModel();

    delete obj.product;
    delete obj.productName;
    return obj;
  }
}

export default Crop;
