import moment from "moment";

export class FBDocumentType {
  id?: number;
  name?: string;

  constructor(obj?: any) {
    this.id = obj?.id;
    this.name = obj?.name;
  }

  mapToApiModel(): any {
    const obj: any = { ...this };
    return obj;
  }
}

class FBDocument {
  id?: number;
  name?: string; //*
  description?: string;
  type?: FBDocumentType; //*
  date?: string; //*
  mimeType?: string;
  entityClassName?: string;
  entityClassId?: number;
  enabledDate?: string;
  disabledDate?: string;
  creationInstant?: number;
  documentUrl?: string;
  thumbnailUrl?: string;

  constructor(obj?: any) {
    this.id = obj?.id;
    this.name = obj?.name;
    this.description = obj?.description;
    this.type = new FBDocumentType(obj?.type);
    this.date = obj?.date || moment().format("YYYY-MM-DD");
    this.mimeType = obj?.mimeType;
    this.entityClassName = obj?.entityClassName;
    this.entityClassId = obj?.entityClassId;
    this.enabledDate = obj?.enabledDate;
    this.disabledDate = obj?.disabledDate;
    this.creationInstant = obj?.creationInstant;
    this.documentUrl = obj?.documentUrl;
    this.thumbnailUrl = obj?.thumbnailUrl;
  }

  mapBaseToApiModel(): any {
    const obj: any = { ...this };
    obj.type = this.type?.mapToApiModel();
    return obj;
  }
}

export default FBDocument;
