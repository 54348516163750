import FBProduct from "../_api/catalogue/FBProduct";

class CropProduct extends FBProduct {
  constructor(obj?: any) {
    super(obj);
  }

  mapToApiModel(): any {
    const obj: any = super.mapBaseToApiModel();
    return obj;
  }
}

export default CropProduct;
