import { useState, useEffect } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import toast from "react-hot-toast";
import ReactGA from "react-ga4";

import { Receipt } from "@mui/icons-material";

import Navbar from "../components/Navbar";
import Sidebar from "../components/Sidebar";
import Footer from "../components/Footer";
import NewExploitationsBanner from "../components/banners/NewExploitationsBanner";
import DialogPolicies from "../components/dialogs/DialogPolicies";

import { useAuth } from "../hooks/useAuth";
import { useSession } from "../hooks/useSession";

import Person from "../models/Person";
import Notification from "../models/notification/Notification";

import useFetch from "../hooks/useFetch";
import useCrud from "../hooks/useCrud";

import {
  MAX_VISIBLE_NOTIFICATIONS,
  REFRESH_PHYTO_RECIPES_NOTIFICATION_INTERVAL,
} from "../constants/constants";
import { gTagOptionsInterface } from "../constants/interfaces";
import { PROTECTED_ROUTES, PUBLIC_ROUTES } from "../routes/routeNames";

const ProtectedLayout = () => {
  const { user, setUser } = useAuth();
  const {
    advisorCueAccounts,
    selectedCueAccount,
    setSiexPendingRegisters,
    setSiexErrorRegisters,
  } = useSession();
  const location = useLocation();

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [scrollOnTop, setScrollOnTop] = useState(true);
  const [isOpenPoliciesDialog, setIsOpenPoliciesDialog] =
    useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { data: unreadNotifications } = useFetch<Notification[]>({
    queryKey: ["notifications", selectedCueAccount?.cueAccount?.id],
    refetchInterval: REFRESH_PHYTO_RECIPES_NOTIFICATION_INTERVAL,
    enabled: !!selectedCueAccount?.cueAccount?.id,
  });

  // Get SIEX pending and errors registers
  useFetch({
    queryKey: ["siexPendingRegisters", selectedCueAccount?.cueAccount?.id],
    enabled: !!selectedCueAccount?.cueAccount?.id,
    onSuccess: setSiexPendingRegisters,
  });
  useFetch({
    queryKey: ["siexErrorRegisters", selectedCueAccount?.cueAccount?.id],
    enabled: !!selectedCueAccount?.cueAccount?.id,
    onSuccess: setSiexErrorRegisters,
  });

  const markNotificationAsReadMutation = useCrud({
    key: "markNotificationAsRead",
  });

  useEffect(() => {
    // Initialize GA4 if production environment and not master user
    if (user) {
      const user_id = user?.person?.id;
      const traffic_type = user?.person?.master ? "internal" : "organic";
      const options: gTagOptionsInterface = { user_id, traffic_type };
      if (process.env.REACT_APP_ENV !== "prod")
        options.debug_mode = "debug_mode";
      ReactGA.initialize([
        {
          trackingId: process.env.REACT_APP_GA4_ID || "",
          gaOptions: options,
          gtagOptions: options,
        },
      ]);
    }

    // Show dialog if user has not accepted a privacy policy update
    setIsOpenPoliciesDialog(
      !!user?.person?.pendingPrivacyPolicyUrl &&
        !!user?.person?.pendingPrivacyPolicyVersion
    );
  }, [user]);

  // Set GA4 page view
  useEffect(() => {
    ReactGA.send({
      hitType: "pageview",
      page: location.pathname,
      title: location.pathname,
    });
  }, [location]);

  // Show phyto recipes notifications and mark them as read
  useEffect(() => {
    const showNotifications = () => {
      // Show maximum notifications at the same time
      const visibleNotifications =
        unreadNotifications &&
        unreadNotifications.length > MAX_VISIBLE_NOTIFICATIONS
          ? unreadNotifications.slice(-MAX_VISIBLE_NOTIFICATIONS)
          : unreadNotifications;

      visibleNotifications?.map((notification) =>
        toast(notification.text || "", {
          id: String(notification.id),
          position: "top-right",
          icon: <Receipt />,
          style: {
            background: "#d1ffa0",
          },
          duration: 5000,
        })
      );

      // Mark notifications as read
      unreadNotifications?.map((notification) =>
        markNotificationAsReadMutation.mutate(notification)
      );
    };

    showNotifications();
  }, [unreadNotifications]);

  const handleOnClickNavbar = () => {
    setIsSidebarOpen((state) => !state);
  };

  const handleOnCloseSidebar = () => {
    setIsSidebarOpen(false);
  };

  const handleBodyScroll = (e: any) => {
    const scrollPos = e.target.scrollTop;
    setScrollOnTop(scrollPos === 0);
  };

  const handleConfirmPolicies = () => {
    const person = user?.person;
    if (person) {
      setIsLoading(true);
      const version = person?.pendingPrivacyPolicyVersion;
      fetch(
        `${process.env.REACT_APP_API_URL}/person/acceptPrivacyPolicy?version=${version}`,
        {
          method: "PUT",
          headers: { Authorization: `Bearer ${user?.idToken}` },
        }
      )
        .then(async (response: Response) => {
          if (response.status === 200) {
            const data = await response.json();
            const updatedPerson = new Person(data);
            setUser({ ...user, person: updatedPerson });
          }
          setIsLoading(false);
          setIsOpenPoliciesDialog(false);
        })
        .catch(() => {
          setIsOpenPoliciesDialog(false);
          setIsLoading(false);
        });
    }
  };

  if (!(window as any).Cypress && !user?.idToken) {
    return <Navigate to={PUBLIC_ROUTES.LOGIN} replace />;
  }

  if (user?.idToken && advisorCueAccounts.length === 0)
    return (
      <>
        <Outlet />
        <Navigate to={PROTECTED_ROUTES.ONBOARDING} replace />
      </>
    );

  if (location.pathname.includes(PROTECTED_ROUTES.CHAT)) return <Outlet />;

  return (
    <>
      <DialogPolicies
        open={isOpenPoliciesDialog}
        isLoading={isLoading}
        onConfirm={handleConfirmPolicies}
        onClose={() => setIsOpenPoliciesDialog(false)}
      />
      <Navbar onClick={handleOnClickNavbar} scrollOnTop={scrollOnTop} />
      <div className="layout-container">
        <div className="layout-content">
          <div className="layout-sidebar">
            <Sidebar
              isOpen={isSidebarOpen}
              setIsOpen={setIsSidebarOpen}
              onClose={handleOnCloseSidebar}
            />
          </div>
          <div className="layout-body" onScroll={handleBodyScroll}>
            <div className="layout-body-content">
              <NewExploitationsBanner />
              <Outlet />
            </div>
            <Footer />
          </div>
        </div>
      </div>
    </>
  );
};

export default ProtectedLayout;
