import brioAgroLogo from "../assets/logo_simple.png";
import { useTranslation } from "react-i18next";

const ChatHomeScreen = () => {
  const { t } = useTranslation();
  return (
    <div className="chat-home-container">
      <img className="logo" alt="logo" src={brioAgroLogo} />
      <h1>{t("chat.homeScreen.msg1")}</h1>
      <h2>
        {t("chat.homeScreen.msg2")} <strong>Whatsapp</strong>{" "}
        {t("chat.homeScreen.msg3")}
      </h2>
      <h3>
        <strong>{t("chat.homeScreen.msg4")}</strong> {t("chat.homeScreen.msg5")}
      </h3>
    </div>
  );
};

export default ChatHomeScreen;
