import {
  FBESiexOperationResult,
  FBESiexOperationType,
} from "../../../constants/enums";
import Person from "../../Person";
import SiexRequest from "../../siex/SiexRequest";

class FBSiexRequestItem {
  id?: number;
  request?: SiexRequest;
  entityClassName?: string;
  entityClassId?: number;
  operationType?: FBESiexOperationType;
  operationResult?: FBESiexOperationResult;
  errorMessage?: string;
  siexId?: number;
  siexBookId?: number;
  revisedBy?: Person;
  revisedTimestamp?: number;
  discarded?: boolean;

  constructor(obj?: any) {
    this.id = obj?.id;
    this.request = new SiexRequest(obj?.request);
    this.entityClassName = obj?.entityClassName;
    this.entityClassId = obj?.entityClassId;
    this.operationType = obj?.operationType;
    this.operationResult = obj?.operationResult;
    this.errorMessage = obj?.errorMessage;
    this.siexId = obj?.siexId;
    this.siexBookId = obj?.siexBookId;
    this.revisedBy = new Person(obj?.revisedBy);
    this.revisedTimestamp = obj?.revisedTimestamp;
    this.discarded = obj?.discarded;
  }

  mapBaseToApiModel(): any {
    const obj: any = { ...this };
    obj.request = this.request?.mapBaseToApiModel();
    return obj;
  }
}

export default FBSiexRequestItem;
