import moment from "moment";
import FBSiexRequest from "../_api/siex/FBSiexRequest";

class SiexRequest extends FBSiexRequest {
  creationDateFormatted?: string;
  requestDateFormatted?: string;

  constructor(obj?: any) {
    super(obj);
    if (this.creationTimestamp) {
      this.creationDateFormatted = moment(this.creationTimestamp).format(
        "DD MMMM HH:mm"
      );
    }
    if (this.requestTimestamp) {
      this.requestDateFormatted = moment(this.requestTimestamp).format(
        "DD MMMM HH:mm"
      );
    }
  }

  mapToApiModel(): any {
    const obj: any = super.mapBaseToApiModel();

    delete obj.creationDateFormatted;
    delete obj.requestDateFormatted;
    return obj;
  }
}

export default SiexRequest;
