import { useState, useEffect } from "react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import i18n from "./config/configI18n";
import * as Sentry from "@sentry/react";

import { ThemeProvider } from "@mui/material/styles";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { Toaster } from "react-hot-toast";

import AlertSnackbar from "./components/elements/AlertSnackbar";
import ErrorScreen from "./screens/ErrorScreen";

import AppRouter from "./routes/AppRouter";
import YupProvider from "./providers/YupProvider";

// Config theme
import theme from "./config/configStyles";

// Config styles
import "./styles/styles.scss";

// Config Firebase
import "./config/configFirebase";

// Prepare moment
import "moment/locale/es";
import "moment/locale/ca";
import "moment/locale/gl";
import "moment/locale/eu";
import "moment/locale/fr";
import "moment/locale/it";
import "moment/locale/pt";
import "moment/locale/de";

// Config Sentry
import "./config/configSentry";

import { SnackbarInterface } from "./constants/interfaces";

// Create a client, neccesarly for the react-query
const queryClient = new QueryClient();

// For google recaptcha enterprise
declare global {
  interface Window {
    grecaptcha: {
      enterprise: {
        ready: (callback: () => void) => void;
        execute: (
          siteKey: string,
          options: { action: string }
        ) => Promise<string>;
      };
    };
  }
}

function App() {
  const [snackbarMsg, setSnackbarMsg] = useState<SnackbarInterface | null>(
    null
  );

  const updateNetworkStatus = () => {
    if (!window.navigator.onLine) {
      setSnackbarMsg({
        severity: "error",
        message: i18n.t("formErrors.networkError"),
      });
    }
  };

  useEffect(() => {
    try {
      // Solve the 100vh problem in mobile devices (100vh ignore mobile browser navigation bottom bars)
      const documentHeight = () => {
        const doc = document.documentElement;
        doc.style.setProperty("--doc-height", `${window.innerHeight}px`);
      };
      window.addEventListener("resize", documentHeight);
      documentHeight();

      document.title = i18n.t("words.advisorPanel"); // Set tab title

      // Listen network changes
      window.addEventListener("online", updateNetworkStatus);
      window.addEventListener("offline", updateNetworkStatus);
    } catch (error) {
      Sentry.captureException(error);
    }

    return () => {
      window.removeEventListener("online", updateNetworkStatus);
      window.removeEventListener("offline", updateNetworkStatus);
    };
  }, []);

  return (
    <Sentry.ErrorBoundary fallback={<ErrorScreen />}>
      <ThemeProvider theme={theme}>
        <Toaster />
        <QueryClientProvider client={queryClient}>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <YupProvider>
              <AlertSnackbar
                open={!!snackbarMsg}
                snackbarMsg={snackbarMsg}
                onClose={() => setSnackbarMsg(null)}
              />
              <AppRouter />
            </YupProvider>
          </LocalizationProvider>
        </QueryClientProvider>
      </ThemeProvider>
    </Sentry.ErrorBoundary>
  );
}

export default App;
