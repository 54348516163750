import { useState } from "react";
import { IconButton, Tooltip } from "@mui/material";
import { AdminPanelSettings } from "@mui/icons-material";
import i18n from "../../config/configI18n";

import DialogChooseCueAccount from "../dialogs/DialogChooseCueAccount";

import CueAccountWithRole from "../../models/account/CueAccountWithRole";

import { useSession } from "../../hooks/useSession";

const ChatNavbar = () => {
  const { advisorCueAccounts, selectedCueAccount, changeSelectedCueAccount } =
    useSession();

  const handleChangeSelectedCueAccount = (newValue: CueAccountWithRole) =>
    changeSelectedCueAccount(newValue);

  return (
    <div className="chat-navbar">
      <div className="chat-navbar-left">
        <div
          className={`logo${advisorCueAccounts.length > 1 ? "" : "-center"}`}
        />
      </div>

      <div className="chat-navbar-right">
        <NavbarMenu
          classname="menu"
          advisorCueAccounts={advisorCueAccounts}
          selectedCueAccount={selectedCueAccount}
          onChangeSelectedCueAccount={handleChangeSelectedCueAccount}
        />
      </div>
    </div>
  );
};

export default ChatNavbar;

interface NavbarMenuProps {
  classname?: string;
  advisorCueAccounts: CueAccountWithRole[];
  selectedCueAccount: CueAccountWithRole | null;
  onChangeSelectedCueAccount: (cawr: CueAccountWithRole) => void;
}
const NavbarMenu = (props: NavbarMenuProps) => {
  const {
    advisorCueAccounts,
    selectedCueAccount,
    onChangeSelectedCueAccount,
    classname,
  } = props;

  const [isOpenCueAccountDialog, setIsOpenCueAccountDialog] =
    useState<boolean>(false);

  const openCueAccountDialog = () => setIsOpenCueAccountDialog(true);
  const closeCueAccountDialog = () => setIsOpenCueAccountDialog(false);

  return (
    <div className={classname}>
      {advisorCueAccounts.length > 1 && (
        <Tooltip title={i18n.t("tooltips.switchAccount")}>
          <IconButton className="account-btn" onClick={openCueAccountDialog}>
            <AdminPanelSettings fontSize="medium" />
          </IconButton>
        </Tooltip>
      )}
      <DialogChooseCueAccount
        open={isOpenCueAccountDialog}
        options={advisorCueAccounts}
        selected={selectedCueAccount}
        onClose={closeCueAccountDialog}
        onConfirm={onChangeSelectedCueAccount}
      />
    </div>
  );
};
