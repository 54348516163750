import { AuthProviders, FBPersonRole } from "../constants/enums";
import Person from "./Person";

class UserData {
  person?: Person;
  idToken?: string;
  authProvider?: AuthProviders;
  role?: FBPersonRole;

  constructor(obj: any) {
    this.person = obj?.person;
    this.idToken = obj?.idToken;
    this.authProvider = obj?.authProvider;
    this.role = obj?.role;
  }
}

export default UserData;
