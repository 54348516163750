import CropProductVariety from "../../catalogue/CropProductVariety";

class FBCrop {
  id?: number;
  name?: string;
  varieties?: CropProductVariety[];

  constructor(obj?: any) {
    this.id = obj?.id;
    this.name = obj?.name;
    this.varieties =
      obj?.varieties?.map((variety: any) => new CropProductVariety(variety)) ||
      [];
  }

  mapBaseToApiModel(): any {
    const obj: any = { ...this };
    obj.varieties =
      this.varieties?.map((variety: CropProductVariety) =>
        variety.mapToApiModel()
      ) || [];
    return obj;
  }
}
export default FBCrop;
