import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { FormikProps, FormikTouched, useFormik } from "formik";
import { useQueryClient } from "@tanstack/react-query";
import i18n from "../config/configI18n";
import * as Yup from "yup";

import { FormControl, FormGroup } from "@mui/material";

import ScreenContentLayout from "../components/ScreenContentLayout";
import LoadingWithDelay from "../components/elements/LoadingWithDelay";
import TestBanner from "../components/banners/TestBanner";
import AlertSnackbar from "../components/elements/AlertSnackbar";
import FormAlertDialog from "../components/dialogs/FormAlertDialog";
import { BrioCard, TableBrioCard } from "../components/BrioCard";
import { StickyNote2 } from "@mui/icons-material";
import { EMPTY_TABLE_ICON_SIZE } from "../constants/constants";
import RightDrawer from "../components/RightDrawer";
import FormikTextField from "../components/formik/FormikTextField";
import FormikAutocomplete from "../components/formik/FormikAutocomplete";
import ChipFilters from "../components/elements/ChipFilters";
import DialogAdvisorNotesFilters from "../components/dialogs/DialogAdvisorNotesFilters";

import { useSession } from "../hooks/useSession";
import useFetch from "../hooks/useFetch";
import useCrud from "../hooks/useCrud";

import AgroBusinessAccount from "../models/account/AgroBusinessAccount";
import AdvisorNote from "../models/advisor/AdvisorNote";

import {
  AdvisorNoteChipFilter,
  ChipFilter,
  Column,
  SnackbarInterface,
} from "../constants/interfaces";
import { FormMode } from "../constants/enums";

const NotesScreen = () => {
  const AdvisorNoteValidatorSchema = Yup.object().shape({
    description: Yup.string().required(),
  });

  const advisorNoteColumns: Column[] = [
    { id: "date", label: i18n.t("notes.column.date"), numeric: false },
    {
      id: "description",
      label: i18n.t("notes.column.description"),
      numeric: false,
    },
    {
      id: "person.email",
      label: i18n.t("notes.column.employee"),
      numeric: false,
    },
    {
      id: "agroBusinessAccount.name",
      label: i18n.t("notes.column.exploitation"),
      numeric: false,
    },
  ];

  const location = useLocation();
  const navigate = useNavigate();
  const { selectedCueAccount } = useSession();

  const [isOpenDrawer, setIsOpenDrawer] = useState<boolean>(false);
  const [snackbarMsg, setSnackbarMsg] = useState<SnackbarInterface | null>(
    null
  );
  const [isOpenBackAlertDialog, setIsOpenBackAlertDialog] =
    useState<boolean>(false);
  const [isOpenConfirmDeleteAlertDialog, setIsOpenConfirmDeleteAlertDialog] =
    useState<boolean>(false);
  const [isOpenFiltersDialog, setIsOpenFiltersDialog] =
    useState<boolean>(false);
  const [formMode, setFormMode] = useState<FormMode | undefined>(undefined);
  const [selectedRowIds, setSelectedRowIds] = useState<number[]>([]);
  const [noteFilters, setNoteFilters] = useState<AdvisorNoteChipFilter[]>([]);

  // Load when selectedCueAccount id or note filters changes
  const { data: advisorNotes, isFetching } = useFetch<AdvisorNote[]>({
    queryKey: [
      "advisorNotes",
      selectedCueAccount?.cueAccount?.id,
      ...noteFilters,
    ],
    selected: noteFilters, // Pass filters into selected field
    enabled: !!selectedCueAccount,
  });

  const handleSubmit = (values: AdvisorNote) => {
    switch (formMode) {
      case FormMode.CREATE:
        advisorNoteCreateMutation.mutate(values);
        break;
      case FormMode.EDIT:
        advisorNoteEditMutation.mutate(values);
        break;
      default:
        break;
    }
  };

  const formik = useFormik({
    initialValues: new AdvisorNote(),
    validationSchema: AdvisorNoteValidatorSchema,
    onSubmit: handleSubmit,
  });

  const drawerTitle =
    formik.status === FormMode.CREATE
      ? i18n.t("notes.drawerTitleCreate")
      : i18n.t("notes.drawerTitleEdit");
  const drawerBtnText =
    formik.status === FormMode.CREATE
      ? i18n.t("words.create")
      : i18n.t("words.update");

  // Open drawer of files form if url contains respective path
  useEffect(() => {
    setIsOpenDrawer(location.pathname.includes("/edit"));
  }, [location.pathname]);

  // Handle browser back button
  useEffect(() => {
    const handleBackButton = (event: any) => {
      event.preventDefault();
      if (isOpenDrawer && formik.dirty && formik.status === FormMode.CREATE) {
        openBackAlertDialog();
        navigate(`${location.pathname}`);
      } else setSelectedRowIds([]);
    };

    window.addEventListener("popstate", handleBackButton);

    return () => {
      window.removeEventListener("popstate", handleBackButton);
    };
  }, [isOpenDrawer, formik.values]);

  const clearForm = () => {
    setFormMode(FormMode.CREATE);
    formik.resetForm();
    formik.setErrors({});
    formik.setStatus(FormMode.CREATE);
  };
  const openDrawer = (formMode: FormMode) => {
    if (formMode === FormMode.CREATE) clearForm();
    setFormMode(formMode);
    navigate(`${location.pathname}/edit`);
  };
  const closeDrawer = () => {
    setSelectedRowIds([]);
    setIsOpenDrawer(false);
    navigate(-1);
  };
  const openBackAlertDialog = () => setIsOpenBackAlertDialog(true);
  const cancelBackAlertDialog = () => setIsOpenBackAlertDialog(false);
  const openConfirmDeleteAlertDialog = () =>
    setIsOpenConfirmDeleteAlertDialog(true);
  const cancelConfirmDeleteAlertDialog = () =>
    setIsOpenConfirmDeleteAlertDialog(false);

  const closeDialogAndUnselectedRows = () => {
    closeDrawer();
    cancelConfirmDeleteAlertDialog();
  };

  const manageCrudError = (snackBarError: SnackbarInterface) => {
    if (snackBarError?.hasDocError) closeDialogAndUnselectedRows();
    setSnackbarMsg(snackBarError);
  };

  const advisorNoteCreateMutation = useCrud<AdvisorNote>({
    key: "postAdvisorNote",
    values: formik.values,
    onSuccess: () => {
      setSnackbarMsg({
        severity: "success",
        message: i18n.t("apiResponses.createSuccess"),
      });
      closeDialogAndUnselectedRows();
    },
    onError: manageCrudError,
  });

  const advisorNoteEditMutation = useCrud<AdvisorNote>({
    key: "putAdvisorNote",
    values: formik.values,
    onSuccess: () => {
      setSnackbarMsg({
        severity: "success",
        message: i18n.t("apiResponses.updateSuccess"),
      });
      closeDialogAndUnselectedRows();
    },
    onError: manageCrudError,
  });

  const advisorNoteDeleteMutation = useCrud({
    key: "deleteAdvisorNotes",
    values: advisorNotes?.filter((advisorNote) =>
      selectedRowIds.includes(advisorNote?.id || 0)
    ),
    onSuccess: () => {
      setSnackbarMsg({
        severity: "success",
        message: i18n.t("apiResponses.deleteSuccess"),
      });
      setSelectedRowIds([]);
      cancelConfirmDeleteAlertDialog();
    },
    onError: (error: SnackbarInterface) => {
      setSnackbarMsg(error);
      cancelConfirmDeleteAlertDialog();
    },
  });

  const handleClickCloseDrawer = () => {
    formik.dirty && formik.status === FormMode.CREATE
      ? openBackAlertDialog()
      : closeDrawer();
  };

  const handleClickAdd = () => {
    formik.setStatus(FormMode.CREATE);
    setFormMode(FormMode.CREATE);
    openDrawer(FormMode.CREATE);
  };

  const handleClickSave = async () => {
    const errors = await formik.validateForm();
    if (Object.keys(errors).length > 0) {
      setSnackbarMsg({
        severity: "warning",
        message: i18n.t("formErrors.requiredFields"),
      });
      // Mark all fields as touched to show errors
      formik.setTouched(errors as FormikTouched<any>);
    } else formik.submitForm();
  };

  const handleClickEdit = (id: number) => {
    setSelectedRowIds([id]);
    formik.setStatus(FormMode.EDIT);
    setFormMode(FormMode.EDIT);
    openDrawer(FormMode.EDIT);
  };

  const handleClickDelete = (ids: number[]) => {
    if (ids.length > 0) {
      formik.setStatus(FormMode.DELETE);
      setFormMode(FormMode.DELETE);
      openConfirmDeleteAlertDialog();
    }
  };

  const handleOnFormError = (snackBarError: SnackbarInterface) => {
    setSnackbarMsg(snackBarError);
  };

  const handleConfirmBackAlertDialog = () => {
    cancelBackAlertDialog();
    closeDrawer();
  };

  const handleConfirmDeleteAlertDialog = () => {
    advisorNoteDeleteMutation.mutate(selectedRowIds);
  };

  const handleChangeNoteFilters = (noteFilters: ChipFilter[]) => {
    setNoteFilters(noteFilters as AdvisorNoteChipFilter[]);
  };

  const handleClickFilters = () => {
    setIsOpenFiltersDialog(true);
  };

  const handleConfirmFiltersDialog = (noteFilters: AdvisorNoteChipFilter[]) => {
    setNoteFilters(noteFilters);
    setIsOpenFiltersDialog(false);
  };

  const handleCloseFiltersDialog = () => {
    setIsOpenFiltersDialog(false);
  };

  return (
    <ScreenContentLayout>
      <LoadingWithDelay isVisible={isFetching} />
      <TestBanner />
      <AlertSnackbar
        open={!!snackbarMsg}
        snackbarMsg={snackbarMsg}
        onClose={() => setSnackbarMsg(null)}
      />
      <FormAlertDialog
        id="backAlertDialog"
        title={i18n.t("notes.backAlertTitle")}
        contentText={i18n.t("notes.backAlertDescription")}
        open={isOpenBackAlertDialog}
        formAction={formMode}
        onCancel={cancelBackAlertDialog}
        onConfirm={handleConfirmBackAlertDialog}
      />
      <FormAlertDialog
        id="confirmDeleteAlertDialog"
        title={i18n.t("notes.confirmDeleteAlertDialogTitle")}
        contentText={i18n.t("notes.confirmDeleteAlertDialogDescription")}
        open={isOpenConfirmDeleteAlertDialog}
        formAction={formMode}
        isLoading={advisorNoteDeleteMutation.isLoading}
        onCancel={cancelConfirmDeleteAlertDialog}
        onConfirm={handleConfirmDeleteAlertDialog}
      />
      <DialogAdvisorNotesFilters
        open={isOpenFiltersDialog}
        noteFilters={noteFilters}
        onConfirm={handleConfirmFiltersDialog}
        onCancel={handleCloseFiltersDialog}
      />
      <TableBrioCard
        title={i18n.t("notes.tableTitle")}
        colums={advisorNoteColumns}
        rows={advisorNotes || []}
        selectedRows={selectedRowIds}
        defaultOrderFieldName="timestamp"
        emptyTableIcon={
          <StickyNote2 sx={{ fontSize: EMPTY_TABLE_ICON_SIZE }} />
        }
        emptyTableTitle={i18n.t("notes.emptyTableTitle")}
        emptyTableSubtitle={i18n.t("notes.emptyTableSubtitle")}
        emptyTableBtnText={i18n.t("notes.emptyTableBtnText")}
        isLoading={isFetching}
        addBtnVariant="contained"
        filtersChildren={
          <ChipFilters
            chipFilters={noteFilters}
            onChange={handleChangeNoteFilters}
            onClick={handleClickFilters}
          />
        }
        filtersButton={selectedRowIds.length === 0}
        activeFilters={noteFilters?.length > 0}
        onChangeSelectedRows={setSelectedRowIds}
        onClickAdd={handleClickAdd}
        onClickEdit={handleClickEdit}
        onClickDelete={handleClickDelete}
        onClickFilters={handleClickFilters}
      />
      <RightDrawer
        title={drawerTitle}
        titleBtn={drawerBtnText}
        isOpen={isOpenDrawer}
        isLoading={
          advisorNoteCreateMutation.isLoading ||
          advisorNoteEditMutation.isLoading
        }
        onClose={handleClickCloseDrawer}
        onConfirm={handleClickSave}
      >
        <AdvisorNotesForm
          formik={formik}
          selectedEditRow={
            formMode === FormMode.EDIT && selectedRowIds.length === 1
              ? advisorNotes?.find(
                  (advisorNote) => advisorNote.id === selectedRowIds[0]
                )
              : undefined
          }
          onError={handleOnFormError}
        />
      </RightDrawer>
    </ScreenContentLayout>
  );
};

export default NotesScreen;

interface AdvisorNoteFormProps {
  formik: FormikProps<AdvisorNote>;
  selectedEditRow?: AdvisorNote;
  onError?: (snackBarError: SnackbarInterface) => void;
}

const AdvisorNotesForm = (props: AdvisorNoteFormProps) => {
  const { formik, selectedEditRow, onError } = props;

  const queryClient = useQueryClient();

  const { isFetching: isUpdating } = useFetch<AdvisorNote>({
    queryKey: ["advisorNote", selectedEditRow?.id],
    selected: selectedEditRow,
    onSuccess: (data: AdvisorNote) => {
      formik.setValues(data);
      // Update list
      queryClient.refetchQueries({
        queryKey: ["advisorNotes"],
        type: "active",
      });
    },
    enabled: !!selectedEditRow?.id,
    onError,
  });

  return (
    <>
      <LoadingWithDelay isVisible={isUpdating} />
      <DefinitionForm {...props} />
    </>
  );
};

const DefinitionForm = (props: AdvisorNoteFormProps) => {
  const { formik } = props;

  const { selectedCueAccount } = useSession();

  const { data: agroBusinessAccounts, isFetching } = useFetch<
    AgroBusinessAccount[]
  >({
    queryKey: ["agroBusinessAccounts", selectedCueAccount?.cueAccount?.id],
    enabled: !!selectedCueAccount,
  });

  return (
    <BrioCard
      title={i18n.t("notes.definitionFormTitle")}
      disableAccordion
      defaultExpanded
    >
      <FormGroup className="form-group">
        <FormControl variant="outlined" className="form-control">
          <FormikTextField
            formik={formik}
            name="description"
            label={i18n.t("notes.descriptionLabel")}
            multiline
            required
          />
        </FormControl>
        <FormControl variant="outlined" className="form-control">
          <FormikAutocomplete
            formik={formik}
            name="agroBusinessAccount"
            label={i18n.t("notes.agroBusinessAccountLabel")}
            helperText={i18n.t("notes.agroBusinessAccountHelperText")}
            options={agroBusinessAccounts || []}
            optionLabelFieldName="name"
            loading={isFetching}
          />
        </FormControl>
      </FormGroup>
    </BrioCard>
  );
};
