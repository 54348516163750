import FBVadeCrop from "../_api/vademecum/FBVadeCrop";

class VadeCrop extends FBVadeCrop {
  constructor(obj?: any) {
    super(obj);
  }

  mapToApiModel(): any {
    const obj: any = super.mapBaseToApiModel();
    return obj;
  }
}

export default VadeCrop;
