import { LanguageInterface } from "./interfaces";

export const LANGUAGE_LIST: LanguageInterface[] = [
  { id: 1, name: "Español", code: "es" },
  { id: 2, name: "Català", code: "ca" },
  { id: 3, name: "Euskara", code: "eu" },
  { id: 4, name: "Galego", code: "gl" },
  { id: 5, name: "English", code: "en" },
  { id: 6, name: "Français", code: "fr" },
  { id: 7, name: "Italiano", code: "it" },
  { id: 8, name: "Português", code: "pt" },
  { id: 9, name: "Deutsch", code: "de" },
];
