import moment, { Moment } from "moment";
import i18n from "../config/configI18n";

type T = any;

/**
 * Get API response data if the body exits or an empty object
 */
export const getResponseData = async (response: Response) => {
  try {
    const data = await response.json();
    return data;
  } catch (error) {
    return undefined;
  }
};

export const includedInRoutes = (
  inputString: string,
  routes: any
): string | null => {
  const routeKeys = Object.keys(routes);

  for (const key of routeKeys) {
    if (inputString.includes(routes[key])) {
      return routes[key];
    }
  }
  return null;
};

/**
 * Get object field value given dot string notation
 * @param field: field name with notation
 * @param obj: object
 * @returns: value of the field
 */
export const getFieldValue = (obj: any, field?: string) => {
  return field?.split(".")?.reduce((o: any, i: string) => o?.[i], obj);
};

/**
 * first parameter: field name with notation
 * second parameter: object
 * return: value of the field
 * example: getFieldValue("a.b.c", {a: {b: {c: 1}}}) => 1
 */
export const getFieldValueByColumnNotation = (
  field: string,
  obj: any,
  valueLabel?: string,
  arrayFieldName?: string,
  unitFieldName?: string,
  unitValue?: string,
  field2?: string
): any => {
  switch (valueLabel) {
    // If value label is count, return the field array length
    case "count":
      return obj?.[field]?.length ?? 0;
    case "boolean":
      return obj?.[field] ? i18n.t("words.yes") : i18n.t("words.no");
    case "array": {
      const array = obj?.[field];
      if (!array) return "";
      if (array.length === 1 && arrayFieldName)
        return getFieldValue(array[0], arrayFieldName) || "";
      else return obj?.[field]?.length ?? 0;
    }
    case "unit":
      return obj?.[field] && unitFieldName
        ? `${obj[field]} ${getFieldValue(obj, unitFieldName) || ""}`
        : "";
    case "unitValue":
      return obj?.[field] && unitValue
        ? `${obj[field]} ${unitValue || ""}`
        : "";
    case "or":
      return getFieldValue(obj, field) || getFieldValue(obj, field2);
    case "isNew":
    default:
      return getFieldValue(obj, field);
  }
};

/**
 * Delete list items, where items is an array of list field values
 */
export const deleteItemsOfListByField = (
  list: any[] | undefined,
  items: any[],
  field: string
) => {
  return list?.filter(
    (obj) => !items.includes(getFieldValueByColumnNotation(field, obj))
  );
};

/**
 * If a field value is duplicated in a list, remove all items with that value
 * Example: [{id: 1, name: "a"}, {id: 2, name: "b"}, {id: 3, name: "a"}] => [{id: 2, name: "b"}]
 */
export const removeDuplicatedItemsOfListByField = (
  list: any[] | undefined,
  field: string
) => {
  const values = list?.map((obj) => getFieldValueByColumnNotation(field, obj));
  const duplicatedValues = values?.filter(
    (value, index) => values.indexOf(value) !== index
  );
  return deleteItemsOfListByField(list, duplicatedValues || [], field);
};

/**
 * Date converter
 * Converts a date to a string with the format "DD MMM" if the year is the current year, otherwise "DD MMM YY"
 */
export const dateConverter = ({
  date,
  dateFormat = "YYYY-MM-DD",
}: {
  date: string | undefined;
  dateFormat?: string;
}): string => {
  if (!date) return "";

  const dateMoment = moment(date, dateFormat);
  if (moment().year() !== dateMoment.year())
    return dateMoment.format("DD MMM YYYY");
  else return dateMoment.format("DD MMM");
};

/**
 * Find the max value of a field in a list or 0 if the list is empty
 * @param list: list to search
 * @param field: field to search
 * @returns: max value
 * Example: [{id: 1, value: 10}, {id: 2, value: 20}, {id: 3, value: 5}] => 20
 */
export const getMaxValueOfList = (list: any[] | undefined, field: string) => {
  return list?.reduce((acc, obj) => {
    const value = getFieldValueByColumnNotation(field, obj);
    return value > acc ? value : acc;
  }, 0);
};

/**
 * Find selecter row register
 */
export const getSelectedRowById = (
  rowId: number | undefined,
  list: T[] | undefined
): T[] | undefined => {
  return list?.find((row) => row.id === rowId);
};

/**
 * Update item of a list
 * @param list: list to update
 * @param item: item to update
 * @param field: field to update
 * @returns: new list
 */
export const updateItemOfList = (
  list: any[] | undefined,
  item: any,
  field: string
) => {
  return list?.map((obj) => {
    if (
      getFieldValueByColumnNotation(field, obj) ===
      getFieldValueByColumnNotation(field, item)
    ) {
      return item;
    }
    return obj;
  });
};

/**
 * Chat message date humanizer
 * @param date: date to humanize
 * @param todayText: if true, return "Hoy" instead of the time
 * @returns: humanized date
 */
export const chatMsgDateHumanizer = (
  date?: Moment,
  todayText?: boolean
): string => {
  if (!date) return "";
  const fromDays = moment().diff(date, "days");
  const isToday = moment().isSame(date, "day");
  const isYesterday = moment().subtract(1, "days").isSame(date, "day");
  if (isToday) return todayText ? i18n.t("words.today") : date.format("HH:mm");
  else if (isYesterday) return i18n.t("words.yesterday");
  else if (fromDays < 6)
    return `${date.format("dddd").substring(0, 1).toUpperCase()}${date
      .format("dddd")
      .substring(1)}`;
  else return date.format("DD/MM/YY");
};

/**
 * Find substring in a string replacing accents and strange characters
 * @param searchWord: string to search
 * @param str: substring to search
 * @returns: boolean
 */
export const findWord = (
  str: string | undefined,
  searchWord: string
): boolean => {
  if (!str) return false;

  const strNormalized = str
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .trim();
  return strNormalized.toLowerCase().includes(searchWord.trim().toLowerCase());
};

/**
 * Update array with new objects by given field and add if not exits
 * @param array: array to update
 * @param newArray: new array to add
 * @param field: field to compare
 * @returns: new array
 */
export const updateList = (array: any[], newArray: any[], field: string) => {
  const oldArray = array.map(
    (oldItem) =>
      newArray.find((newItem) => newItem[field] === oldItem[field]) || oldItem
  );
  const oldArrayIds = oldArray.map((obj) => obj[field]);
  const newItems = newArray.filter((obj) => !oldArrayIds.includes(obj[field]));
  return [...oldArray, ...newItems];
};

/**
 * Find string in other string replacing accents and strange characters
 * @param str1: string 1
 * @param str2: string 2
 * @returns: boolean
 */
export const stringMatching = (str1: string, str2: string): boolean => {
  const strNormalized_1 = str1
    .normalize("NFD")
    .toLowerCase()
    .replace(/[\u0300-\u036f]/g, "")
    .trim();
  const strNormalized_2 = str2
    .normalize("NFD")
    .toLowerCase()
    .replace(/[\u0300-\u036f]/g, "")
    .trim();
  return (
    strNormalized_1.includes(strNormalized_2) ||
    strNormalized_2.includes(strNormalized_1)
  );
};

export function uniqueById(objects: any[]): any[] {
  const uniqueObjects: Map<number, any> = new Map();

  for (const obj of objects) {
    uniqueObjects.set(obj.id, obj);
  }

  const uniqueArray: any[] = Array.from(uniqueObjects.values());
  return uniqueArray;
}

/**
 * Given a string, return the string with the first letter in uppercase
 */
export const capitalize = (str?: string) => {
  if (!str) return "";
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};
