import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { FormikProps, FormikTouched, useFormik } from "formik";
import { useQueryClient } from "@tanstack/react-query";
import i18n from "../config/configI18n";
import * as Yup from "yup";

import { FormControl, FormGroup } from "@mui/material";

import ScreenContentLayout from "../components/ScreenContentLayout";
import LoadingWithDelay from "../components/elements/LoadingWithDelay";
import TestBanner from "../components/banners/TestBanner";
import AlertSnackbar from "../components/elements/AlertSnackbar";
import FormAlertDialog from "../components/dialogs/FormAlertDialog";
import { BrioCard, TableBrioCard } from "../components/BrioCard";
import { CorporateFare } from "@mui/icons-material";
import { EMPTY_TABLE_ICON_SIZE } from "../constants/constants";
import RightDrawer from "../components/RightDrawer";
import FormikTextField from "../components/formik/FormikTextField";
import FormikSelect from "../components/formik/FormikSelect";
import FormikAutocomplete from "../components/formik/FormikAutocomplete";

import { useSession } from "../hooks/useSession";
import useFetch from "../hooks/useFetch";
import useCrud from "../hooks/useCrud";

import AgroBusinessAccount from "../models/account/AgroBusinessAccount";
import RegisterRegion from "../models/RegisterRegion";
import LegalContext from "../models/LegalContext";

import { updateItemOfList } from "../helpers/utils";
import { Column, SnackbarInterface } from "../constants/interfaces";
import { FormMode } from "../constants/enums";

const ExploitationsScreen = () => {
  const ExploitationValidatorSchema = Yup.object().shape({
    name: Yup.string().required(),
    registerRegion: Yup.object({
      code: Yup.string().required(),
    }).required(),
    context: Yup.object({
      name: Yup.string().required(),
    }).required(),
  });

  const exploitationsColumns: Column[] = [
    {
      id: "name",
      label: i18n.t("exploitations.column.name"),
      numeric: false,
      valueLabel: "isNew",
      isNewFieldName: "recentlyTransferred",
    },
    {
      id: "registerRegion.name",
      label: i18n.t("exploitations.column.registerRegion"),
      numeric: false,
    },
    {
      id: "context.description",
      label: i18n.t("exploitations.column.legalContext"),
      numeric: false,
    },
  ];

  const location = useLocation();
  const navigate = useNavigate();
  const { selectedCueAccount } = useSession();

  const [isOpenDrawer, setIsOpenDrawer] = useState<boolean>(false);
  const [snackbarMsg, setSnackbarMsg] = useState<SnackbarInterface | null>(
    null
  );
  const [isOpenBackAlertDialog, setIsOpenBackAlertDialog] =
    useState<boolean>(false);
  const [isOpenConfirmDeleteAlertDialog, setIsOpenConfirmDeleteAlertDialog] =
    useState<boolean>(false);
  const [formMode, setFormMode] = useState<FormMode | undefined>(undefined);
  const [selectedRowIds, setSelectedRowIds] = useState<number[]>([]);

  const { data: agroBusinessAccounts, isFetching } = useFetch<
    AgroBusinessAccount[]
  >({
    queryKey: ["agroBusinessAccounts", selectedCueAccount?.cueAccount?.id],
    enabled: !!selectedCueAccount,
  });

  const resetRecentlyTransferedFlagMutation = useCrud<void>({
    key: "resetRecentlyTransferedFlagForCueAccountId",
  });

  // Update recentlyTransferred fields if needed when unmount
  useEffect(() => {
    return () => {
      if (agroBusinessAccounts?.some((aba) => aba.recentlyTransferred)) {
        resetRecentlyTransferedFlagMutation.mutate({});
      }
    };
  }, []);

  const handleSubmit = (values: AgroBusinessAccount) => {
    switch (formMode) {
      case FormMode.CREATE:
        agroBusinessAccountCreateMutation.mutate(values);
        break;
      case FormMode.EDIT:
        agroBusinessAccountEditMutation.mutate(values);
        break;
      default:
        break;
    }
  };

  const formik = useFormik({
    initialValues: new AgroBusinessAccount(),
    validationSchema: ExploitationValidatorSchema,
    onSubmit: handleSubmit,
  });

  const drawerTitle =
    formik.status === FormMode.CREATE
      ? i18n.t("exploitations.drawerTitleCreate")
      : i18n.t("exploitations.drawerTitleEdit");
  const drawerBtnText =
    formik.status === FormMode.CREATE
      ? i18n.t("words.create")
      : i18n.t("words.update");

  // Open drawer of files form if url contains respective path
  useEffect(() => {
    setIsOpenDrawer(location.pathname.includes("/edit"));
  }, [location.pathname]);

  // Handle browser back button
  useEffect(() => {
    const handleBackButton = (event: any) => {
      event.preventDefault();
      if (isOpenDrawer && formik.dirty && formik.status === FormMode.CREATE) {
        openBackAlertDialog();
        navigate(`${location.pathname}`);
      } else setSelectedRowIds([]);
    };

    window.addEventListener("popstate", handleBackButton);

    return () => {
      window.removeEventListener("popstate", handleBackButton);
    };
  }, [isOpenDrawer, formik.values]);

  const clearForm = () => {
    setFormMode(FormMode.CREATE);
    formik.resetForm();
    formik.setErrors({});
    formik.setStatus(FormMode.CREATE);
  };
  const openDrawer = (formMode: FormMode) => {
    if (formMode === FormMode.CREATE) clearForm();
    setFormMode(formMode);
    navigate(`${location.pathname}/edit`);
  };
  const closeDrawer = () => {
    setSelectedRowIds([]);
    setIsOpenDrawer(false);
    navigate(-1);
  };
  const openBackAlertDialog = () => setIsOpenBackAlertDialog(true);
  const cancelBackAlertDialog = () => setIsOpenBackAlertDialog(false);
  const openConfirmDeleteAlertDialog = () =>
    setIsOpenConfirmDeleteAlertDialog(true);
  const cancelConfirmDeleteAlertDialog = () =>
    setIsOpenConfirmDeleteAlertDialog(false);

  const closeDialogAndUnselectedRows = () => {
    closeDrawer();
    cancelConfirmDeleteAlertDialog();
  };

  const manageCrudError = (snackBarError: SnackbarInterface) => {
    if (snackBarError?.hasDocError) closeDialogAndUnselectedRows();
    setSnackbarMsg(snackBarError);
  };

  const agroBusinessAccountCreateMutation = useCrud<AgroBusinessAccount>({
    key: "postAgroBusinessAccount",
    values: formik.values,
    onSuccess: () => {
      setSnackbarMsg({
        severity: "success",
        message: i18n.t("apiResponses.createSuccess"),
      });
      closeDialogAndUnselectedRows();
    },
    onError: manageCrudError,
  });

  const agroBusinessAccountEditMutation = useCrud<AgroBusinessAccount>({
    key: "putAgroBusinessAccount",
    values: formik.values,
    onSuccess: () => {
      setSnackbarMsg({
        severity: "success",
        message: i18n.t("apiResponses.updateSuccess"),
      });
      closeDialogAndUnselectedRows();
    },
    onError: manageCrudError,
  });

  const agroBusinessAccountDeleteMutation = useCrud({
    key: "deleteAgroBusinessAccounts",
    values: agroBusinessAccounts?.filter((aba: AgroBusinessAccount) =>
      selectedRowIds.includes(aba?.id || 0)
    ),
    onSuccess: () => {
      setSnackbarMsg({
        severity: "success",
        message: i18n.t("apiResponses.deleteSuccess"),
      });
      setSelectedRowIds([]);
      cancelConfirmDeleteAlertDialog();
    },
    onError: (error: SnackbarInterface) => {
      setSnackbarMsg(error);
      cancelConfirmDeleteAlertDialog();
    },
  });

  const handleClickCloseDrawer = () => {
    formik.dirty && formik.status === FormMode.CREATE
      ? openBackAlertDialog()
      : closeDrawer();
  };

  const handleClickAdd = () => {
    formik.setStatus(FormMode.CREATE);
    setFormMode(FormMode.CREATE);
    openDrawer(FormMode.CREATE);
  };

  const handleClickSave = async () => {
    const errors = await formik.validateForm();
    if (Object.keys(errors).length > 0) {
      setSnackbarMsg({
        severity: "warning",
        message: i18n.t("formErrors.requiredFields"),
      });
      // Mark all fields as touched to show errors
      formik.setTouched(errors as FormikTouched<any>);
    } else formik.submitForm();
  };

  const handleClickEdit = (id: number) => {
    setSelectedRowIds([id]);
    formik.setStatus(FormMode.EDIT);
    setFormMode(FormMode.EDIT);
    openDrawer(FormMode.EDIT);
  };

  const handleClickDelete = (ids: number[]) => {
    if (ids.length > 0) {
      formik.setStatus(FormMode.DELETE);
      setFormMode(FormMode.DELETE);
      openConfirmDeleteAlertDialog();
    }
  };

  const handleOnFormError = (snackBarError: SnackbarInterface) => {
    setSnackbarMsg(snackBarError);
  };

  const handleConfirmBackAlertDialog = () => {
    cancelBackAlertDialog();
    closeDrawer();
  };

  const handleConfirmDeleteAlertDialog = () => {
    agroBusinessAccountDeleteMutation.mutate(selectedRowIds);
  };

  return (
    <ScreenContentLayout>
      <LoadingWithDelay isVisible={isFetching} />
      <TestBanner />
      <AlertSnackbar
        open={!!snackbarMsg}
        snackbarMsg={snackbarMsg}
        onClose={() => setSnackbarMsg(null)}
      />
      <FormAlertDialog
        id="backAlertDialog"
        title={i18n.t("exploitations.backAlertTitle")}
        contentText={i18n.t("exploitations.backAlertDescription")}
        open={isOpenBackAlertDialog}
        formAction={formMode}
        onCancel={cancelBackAlertDialog}
        onConfirm={handleConfirmBackAlertDialog}
      />
      <FormAlertDialog
        id="confirmDeleteAlertDialog"
        title={i18n.t("exploitations.confirmDeleteAlertDialogTitle")}
        contentText={i18n.t(
          "exploitations.confirmDeleteAlertDialogDescription"
        )}
        open={isOpenConfirmDeleteAlertDialog}
        formAction={formMode}
        isLoading={agroBusinessAccountDeleteMutation.isLoading}
        onCancel={cancelConfirmDeleteAlertDialog}
        onConfirm={handleConfirmDeleteAlertDialog}
      />
      <TableBrioCard
        title={i18n.t("exploitations.tableTitle")}
        headerText={i18n.t("exploitations.headerText")}
        colums={exploitationsColumns}
        rows={agroBusinessAccounts}
        selectedRows={selectedRowIds}
        emptyTableIcon={
          <CorporateFare sx={{ fontSize: EMPTY_TABLE_ICON_SIZE }} />
        }
        emptyTableTitle={i18n.t("exploitations.emptyTableTitle")}
        emptyTableSubtitle={i18n.t("exploitations.emptyTableSubtitle")}
        emptyTableBtnText={i18n.t("exploitations.emptyTableBtnText")}
        isLoading={isFetching}
        addBtnVariant="contained"
        onChangeSelectedRows={setSelectedRowIds}
        onClickAdd={handleClickAdd}
        onClickEdit={handleClickEdit}
        onClickDelete={handleClickDelete}
      />
      <RightDrawer
        title={drawerTitle}
        titleBtn={drawerBtnText}
        isOpen={isOpenDrawer}
        isLoading={
          agroBusinessAccountCreateMutation.isLoading ||
          agroBusinessAccountEditMutation.isLoading
        }
        onClose={handleClickCloseDrawer}
        onConfirm={handleClickSave}
      >
        <AgroBusinessAccountForm
          formik={formik}
          selectedEditRow={
            formMode === FormMode.EDIT && selectedRowIds.length === 1
              ? agroBusinessAccounts?.find(
                  (aba: AgroBusinessAccount) => aba.id === selectedRowIds[0]
                )
              : undefined
          }
          onError={handleOnFormError}
        />
      </RightDrawer>
    </ScreenContentLayout>
  );
};

export default ExploitationsScreen;

interface AgroBusinessAccountFormProps {
  formik: FormikProps<AgroBusinessAccount>;
  selectedEditRow?: AgroBusinessAccount;
  onError?: (snackBarError: SnackbarInterface) => void;
}

const AgroBusinessAccountForm = (props: AgroBusinessAccountFormProps) => {
  const { formik, selectedEditRow, onError } = props;

  const { selectedCueAccount } = useSession();
  const queryClient = useQueryClient();

  const { isFetching: isUpdating } = useFetch<AgroBusinessAccount>({
    queryKey: ["agroBusinessAccount", selectedEditRow?.id],
    selected: selectedEditRow,
    onSuccess: (data: AgroBusinessAccount) => {
      formik.setValues(data);
      // Update in array without refetch
      queryClient.setQueryData<AgroBusinessAccount[]>(
        ["agroBusinessAccounts", selectedCueAccount?.cueAccount?.id],
        (oldData) => updateItemOfList(oldData, data, "id")
      );
    },
    enabled: !!selectedEditRow?.id,
    onError,
  });

  return (
    <>
      <LoadingWithDelay isVisible={isUpdating} />
      <DefinitionForm {...props} />
      <LegalContextForm {...props} />
    </>
  );
};

const DefinitionForm = (props: AgroBusinessAccountFormProps) => {
  const { formik } = props;

  return (
    <BrioCard
      title={i18n.t("exploitations.definitionForm.title")}
      disableAccordion
      defaultExpanded
    >
      <FormGroup className="form-group">
        <FormControl variant="outlined" className="form-control">
          <FormikTextField
            formik={formik}
            name="name"
            label={i18n.t("exploitations.definitionForm.nameLabel")}
            required
          />
        </FormControl>
        <FormControl variant="outlined" className="form-control">
          <FormikTextField
            formik={formik}
            name="ownerId"
            label={i18n.t("exploitations.definitionForm.ownerIdLabel")}
          />
        </FormControl>
      </FormGroup>
    </BrioCard>
  );
};

const LegalContextForm = (props: AgroBusinessAccountFormProps) => {
  const { formik, onError } = props;

  const { selectedCueAccount } = useSession();

  const setDefaultLegalContext = (data: LegalContext[]) => {
    formik.setFieldValue("context", data?.[0] || null);
  };

  const { data: legalContexts, isFetching: isFetchingContexts } = useFetch<
    RegisterRegion[]
  >({
    queryKey: ["legalContexts", selectedCueAccount?.cueAccount?.id],
    enabled: !!selectedCueAccount,
    onSuccess: setDefaultLegalContext,
    onError,
  });

  const { data: registerRegions, isFetching: isFetchingRegions } = useFetch<
    RegisterRegion[]
  >({
    queryKey: ["registerRegions", formik.values?.context?.id],
    selected: formik.values?.context,
    enabled: !!formik.values?.context?.id,
    onError,
  });

  return (
    <BrioCard
      title={i18n.t("exploitations.legalContextForm.title")}
      disableAccordion
      defaultExpanded
    >
      <LoadingWithDelay isVisible={isFetchingContexts} />
      <FormGroup className="form-group">
        <FormControl variant="outlined" className="form-control">
          <FormikSelect
            formik={formik}
            required
            name="context"
            label={i18n.t("exploitations.legalContextForm.contextLabel")}
            optionLabelFieldName="description"
            options={legalContexts || []}
          />
        </FormControl>
        <FormControl variant="outlined" className="form-control">
          <FormikAutocomplete
            formik={formik}
            name="registerRegion"
            label={i18n.t("exploitations.legalContextForm.registerRegionLabel")}
            optionLabelFieldName="name"
            required
            options={registerRegions || []}
            loading={isFetchingRegions}
            disabled={!formik.values?.context?.id}
          />
        </FormControl>
      </FormGroup>
    </BrioCard>
  );
};
