import { useEffect } from "react";
import i18n from "../config/configI18n";
import { setLocale } from "yup";

import {
  EntityClassNameInterface,
  EntitySexInterface,
  EntityTypeInterface,
  PersonPermissionRoleInterface,
  PhytoRecipeTypeInterface,
  PhytosanitaryTrainingLevelInterface,
  PlantProblemTypeInterface,
} from "../constants/interfaces";
import {
  EntitySex,
  EntityType,
  FBEntitiesTypes,
  FBPersonRole,
  PhytoRecipeType,
  PhytosanitaryTrainingLevel,
  PlantProblemType,
} from "../constants/enums";

export let PERSON_PERMISSION_ROLES_LIST: PersonPermissionRoleInterface[] = [];
export let ENTITY_TYPES: EntityTypeInterface[] = [];
export let ENTITY_SEX_TYPES: EntitySexInterface[] = [];
export let PHYTOSANITARY_TRAINING_LEVELS: PhytosanitaryTrainingLevelInterface[] =
  [];
export let PHYTO_RECIPE_TYPES: PhytoRecipeTypeInterface[] = [];
export let PLANT_PROBLEM_TYPES: PlantProblemTypeInterface[] = [];
export let ENTITY_CLASS_NAMES: EntityClassNameInterface[] = [];

const updateTranslationLists = () => {
  PERSON_PERMISSION_ROLES_LIST = [
    { id: 1, name: i18n.t("personRoles.admin"), role: FBPersonRole.ADMIN },
    {
      id: 2,
      name: i18n.t("personRoles.writer"),
      role: FBPersonRole.WRITER,
    },
    {
      id: 3,
      name: i18n.t("personRoles.reader"),
      role: FBPersonRole.READER,
    },
    { id: 4, name: i18n.t("personRoles.none"), role: FBPersonRole.NONE },
  ];
  ENTITY_TYPES = [
    {
      id: 1,
      name: i18n.t("entityTypes.physical"),
      code: EntityType.PHYSICAL,
    },
    { id: 2, name: i18n.t("entityTypes.legal"), code: EntityType.LEGAL },
  ];
  ENTITY_SEX_TYPES = [
    { id: 1, name: i18n.t("entitySexTypes.male"), code: EntitySex.MALE },
    { id: 2, name: i18n.t("entitySexTypes.female"), code: EntitySex.FEMALE },
    {
      id: 3,
      name: i18n.t("entitySexTypes.intersex"),
      code: EntitySex.INTERSEX,
    },
  ];
  PHYTOSANITARY_TRAINING_LEVELS = [
    {
      id: 1,
      name: i18n.t("phytosanitaryTrainningLevels.basic"),
      code: PhytosanitaryTrainingLevel.BASIC,
    },
    {
      id: 2,
      name: i18n.t("phytosanitaryTrainningLevels.skilled"),
      code: PhytosanitaryTrainingLevel.SKILLED,
    },
    {
      id: 3,
      name: i18n.t("phytosanitaryTrainningLevels.fumigator"),
      code: PhytosanitaryTrainingLevel.FUMIGATOR,
    },
    {
      id: 4,
      name: i18n.t("phytosanitaryTrainningLevels.pilot"),
      code: PhytosanitaryTrainingLevel.PILOT,
    },
  ];
  PHYTO_RECIPE_TYPES = [
    {
      id: 1,
      name: i18n.t("recipes.recipeTypeCropLabel"),
      code: PhytoRecipeType.CROP,
    },
    {
      id: 2,
      name: i18n.t("recipes.recipeTypePostharvestLabel"),
      code: PhytoRecipeType.POSTHARVEST,
    },
    {
      id: 3,
      name: i18n.t("recipes.recipeTypeFacility"),
      code: PhytoRecipeType.FACILITY,
    },
  ];
  PLANT_PROBLEM_TYPES = [
    {
      id: 1,
      name: i18n.t("plantProblemTypes.plagues"),
      code: PlantProblemType.PLAGUE,
    },
    {
      id: 2,
      name: i18n.t("plantProblemTypes.diseases"),
      code: PlantProblemType.DISEASE,
    },
    {
      id: 3,
      name: i18n.t("plantProblemTypes.weeds"),
      code: PlantProblemType.WEED,
    },
    {
      id: 4,
      name: i18n.t("plantProblemTypes.regulators"),
      code: PlantProblemType.REGULATORS,
    },
  ];
  ENTITY_CLASS_NAMES = [
    {
      id: 1,
      name: FBEntitiesTypes.ANALYSYS,
      label: i18n.t("entityClassNamesTypes.analysis"),
    },
    {
      id: 2,
      name: FBEntitiesTypes.CROP_IRRIGATION,
      label: i18n.t("entityClassNamesTypes.cropIrrigation"),
    },
    {
      id: 3,
      name: FBEntitiesTypes.FERTILIZATION,
      label: i18n.t("entityClassNamesTypes.fertilization"),
    },
    {
      id: 4,
      name: FBEntitiesTypes.CROP_PHYTO_TREATMENT,
      label: i18n.t("entityClassNamesTypes.cropPhytosanitaryTreatment"),
    },
    {
      id: 5,
      name: FBEntitiesTypes.POSTHARVEST_PHYTO_TREATMENT,
      label: i18n.t("entityClassNamesTypes.postHarvestPhytosanitaryTreatment"),
    },
    {
      id: 6,
      name: FBEntitiesTypes.FACILITY_PHYTO_TREATMENT,
      label: i18n.t("entityClassNamesTypes.facilityPhytosanitaryTreatment"),
    },
    {
      id: 7,
      name: FBEntitiesTypes.TREATED_SEED,
      label: i18n.t("entityClassNamesTypes.treatedSeed"),
    },
  ];
};

interface Props {
  children: React.ReactNode;
}
const YupProvider = (props: Props) => {
  // Neccessary to translate the yup errors
  useEffect(() => {
    i18n.on("languageChanged", (lng) => {
      setLocale({
        mixed: {
          required: i18n.t("formErrors.requiredField"),
        },
        string: {
          email: i18n.t("formErrors.invalidEmail"),
        },
      });
      updateTranslationLists();
    });
  }, []);

  return <>{props.children}</>;
};

export default YupProvider;
