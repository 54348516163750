import React, { useState, useEffect } from "react";
import i18n from "../config/configI18n";

import {
  TableHead,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableSortLabel,
  TablePagination,
  Checkbox,
  Tooltip,
  IconButton,
  Button,
  Typography,
  Box,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Collapse,
  CircularProgress,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  AccordionProps,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { visuallyHidden } from "@mui/utils";
import {
  Add,
  Delete,
  Settings,
  Image,
  KeyboardArrowDown,
  KeyboardArrowUp,
  ExpandMore,
  FilterList,
  NewReleases,
} from "@mui/icons-material";

import EmptyTable from "./EmptyTable";

import { Column } from "../constants/interfaces";

import { getFieldValue, getFieldValueByColumnNotation } from "../helpers/utils";

// Hide collapsed top border on large screens
const AccordionStyle = {
  "&:before": {
    backgroundColor: "transparent !important",
  },
};

/**
 * BrioCard is a component that renders a card with a title and a header text
 * @param title: title of the card
 * @param headerText: text of the header
 * @param children: children of the card
 */
interface BrioCardProps {
  title?: string;
  headerText?: string;
  children?: React.ReactNode;
  actionBtn?: boolean;
  rightBtnDisabled?: boolean;
  rightBtnTitle?: string;
  rightIcon?: React.ReactNode;
  disableAccordion?: boolean;
  forceExpanded?: boolean;
  required?: boolean;
  optional?: boolean;
  onClickAdd?: () => void;
}

export const BrioCard = (props: AccordionProps & BrioCardProps) => {
  const {
    title = "",
    headerText = "",
    children,
    onClickAdd,
    actionBtn = false,
    rightBtnDisabled = false,
    rightBtnTitle = "Añadir",
    rightIcon = <Add />,
    disableAccordion = false,
    forceExpanded = false,
    required = false,
    optional = false,
  } = props;
  const isLargeScreen = useMediaQuery("(min-width:840px)");

  const [isExpanded, setIsExpanded] = useState<boolean>(
    props.defaultExpanded || false
  );

  useEffect(() => {
    if (forceExpanded && !isExpanded) setIsExpanded(true);
  }, [forceExpanded]);

  return (
    <Accordion
      className="briocard"
      sx={isLargeScreen ? AccordionStyle : {}}
      square
      elevation={0}
      disableGutters={isLargeScreen}
      expanded={isExpanded}
      onChange={() => !disableAccordion && setIsExpanded((state) => !state)}
    >
      <AccordionSummary
        className="header"
        expandIcon={!disableAccordion && <ExpandMore />}
        style={{
          borderBottomRightRadius:
            isExpanded || !isLargeScreen ? "0px" : "16px",
          borderBottomLeftRadius: isExpanded || !isLargeScreen ? "0px" : "16px",
          transition: "border-radius 0.2s ease-in-out",
        }}
      >
        <div className="header-left">
          <h1>{`${title}${required ? "*" : ""}`}</h1>
          {optional && <span> (opcional)</span>}
        </div>
        {actionBtn && (
          <div className="toolbar-actions">
            <Button
              variant="outlined"
              startIcon={rightIcon}
              onClick={onClickAdd}
              disabled={rightBtnDisabled}
            >
              <Typography noWrap>{rightBtnTitle}</Typography>
            </Button>
          </div>
        )}
      </AccordionSummary>
      <AccordionDetails className="content">
        {headerText.length > 0 && <p>{headerText}</p>}
        {children}
      </AccordionDetails>
    </Accordion>
  );
};

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = "asc" | "desc";
function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort<T>(
  array: readonly T[],
  comparator: (a: T, b: T) => number
) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

interface BrioCardTableToolbarProps {
  numSelected: number;
  title?: string;
  btnText?: string;
  btnIcon?: React.ReactNode;
  auxBtn?: boolean;
  auxBtnText?: string;
  auxBtnIcon?: React.ReactNode;
  settings?: boolean;
  disableDelete?: boolean;
  disableBtn?: boolean;
  disableIcon?: React.ReactNode;
  disableTooltip?: string;
  hideBtn?: boolean;
  hideBtnIcon?: boolean;
  isLoading?: boolean;
  required?: boolean;
  optional?: boolean;
  addBtnVariant?: "outlined" | "contained";
  filtersButton?: boolean;
  onClickAdd?: () => void;
  onClickSettings?: () => void;
  onClickDelete?: () => void;
  onClickFilters?: () => void;
  onClickAux?: () => void;
}

const BrioCardTableToolbar = (props: BrioCardTableToolbarProps) => {
  const {
    numSelected,
    title = "",
    btnText = i18n.t("words.add"),
    auxBtnIcon,
    auxBtn = false,
    auxBtnText = "",
    settings = false,
    disableDelete = false,
    disableBtn = false,
    disableIcon = <Delete />,
    disableTooltip = i18n.t("words.delete"),
    hideBtn = false,
    hideBtnIcon = false,
    isLoading = false,
    btnIcon,
    required = false,
    optional = false,
    addBtnVariant = "outlined",
    filtersButton = false,
    onClickAdd,
    onClickSettings,
    onClickDelete,
    onClickFilters,
    onClickAux,
  } = props;
  const theme = useTheme();
  const addBtnIconColor =
    addBtnVariant === "outlined"
      ? theme.palette.primary.main
      : theme.palette.background.default;

  return (
    <div className={`header-table ${numSelected > 0 ? "header-selected" : ""}`}>
      <div className="header-left">
        <h1>
          {numSelected > 0
            ? `${numSelected} ${i18n.t("words.selected")}`
            : `${title}${required ? "*" : ""}`}
        </h1>
        {optional && <span> (opcional)</span>}
        {filtersButton && (
          <Tooltip title={i18n.t("words.filters")}>
            <IconButton onClick={onClickFilters}>
              <FilterList />
            </IconButton>
          </Tooltip>
        )}
        {settings && (
          <IconButton onClick={onClickSettings}>
            <Settings />
          </IconButton>
        )}
      </div>
      <div className="toolbar-actions">
        {!hideBtn && numSelected === 0 && (
          <Button
            id="add-btn"
            variant={addBtnVariant}
            startIcon={
              !hideBtnIcon &&
              (isLoading ? (
                <CircularProgress
                  size={20}
                  style={{ color: addBtnIconColor }}
                />
              ) : (
                btnIcon || <Add style={{ color: addBtnIconColor }} />
              ))
            }
            onClick={onClickAdd}
            disabled={disableBtn}
          >
            {btnText}
          </Button>
        )}
        {auxBtn && (
          <Button
            id="aux-btn"
            variant={addBtnVariant}
            startIcon={
              isLoading ? (
                <CircularProgress
                  size={20}
                  style={{ color: addBtnIconColor }}
                />
              ) : (
                auxBtnIcon || <Add style={{ color: addBtnIconColor }} />
              )
            }
            onClick={onClickAux}
          >
            {auxBtnText}
          </Button>
        )}
        {!disableDelete && numSelected > 0 && (
          <Tooltip title={disableTooltip}>
            <IconButton id="delete-btn" onClick={onClickDelete}>
              {disableIcon}
            </IconButton>
          </Tooltip>
        )}
      </div>
    </div>
  );
};

interface BrioCardTableHeadProps {
  numSelected: number;
  rowCount?: number;
  columns?: Column[];
  order: Order;
  orderBy: string;
  isGroupedTable?: boolean;
  hideCheckbox?: boolean;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onRequestSort: (event: React.MouseEvent<unknown>, property: string) => void;
}
const BrioCardTableHead = (props: BrioCardTableHeadProps) => {
  const {
    numSelected,
    rowCount = 0,
    columns = [],
    order,
    orderBy,
    isGroupedTable,
    hideCheckbox = false,
    onSelectAllClick,
    onRequestSort,
  } = props;

  const createSortHandler =
    (property: any) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow className="table-row-head">
        {!hideCheckbox && (
          <TableCell className="table-cell-head" padding="checkbox">
            <Checkbox
              className="checkbox"
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
            />
          </TableCell>
        )}
        {columns.map((column) => (
          <TableCell
            key={column.id}
            className="table-cell-head"
            align={column.align || (column.numeric ? "right" : "left")}
            sortDirection={orderBy === column.id ? order : false}
          >
            {column.notSortable ? (
              column.label
            ) : (
              <TableSortLabel
                active={orderBy === column.id}
                direction={orderBy === column.id ? order : "asc"}
                onClick={createSortHandler(column.id)}
              >
                {column.label}
                {orderBy === column.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            )}
          </TableCell>
        ))}
        {isGroupedTable && (
          <TableCell className="table-cell-head" align="center" />
        )}
      </TableRow>
    </TableHead>
  );
};

/**
 * TableBrioCard is a component that renders a card with a title and a header and a table
 * @param title: title of the card
 * @param headerText: text of the header
 * @param colums: array of colums
 * @param rows: array of rows
 */
interface TableBrioCardProps {
  idx?: string; // Alternative field name as unique key (id by default)
  title?: string;
  btnText?: string;
  btnIcon?: React.ReactNode;
  auxBtn?: boolean;
  auxBtnText?: string;
  auxBtnIcon?: React.ReactNode;
  headerText?: string;
  colums?: Column[];
  rows?: any[];
  childColumns?: Column[];
  selectedRows?: number[];
  settings?: boolean;
  disableRows?: number[];
  disableDelete?: boolean;
  disableBtn?: boolean;
  disableIcon?: React.ReactNode;
  disableTooltip?: string;
  hideBtn?: boolean;
  hideBtnIcon?: boolean;
  hideCheckbox?: boolean;
  defaultOrderFieldName?: string;
  isLoading?: boolean;
  children?: React.ReactNode;
  filtersChildren?: React.ReactNode;
  filtersButton?: boolean;
  activeFilters?: boolean;
  emptyTableCard?: boolean;
  emptyTableIcon?: React.ReactNode;
  emptyTableTitle?: string;
  emptyTableSubtitle?: string;
  emptyTableBtnText?: string;
  required?: boolean;
  optional?: boolean;
  addBtnVariant?: "outlined" | "contained";
  onChangeSelectedRows?: (ids: number[]) => void;
  onClickAdd?: () => void;
  onClickSettings?: () => void;
  onClickEdit?: (id: number) => void;
  onClickDelete?: (ids: number[]) => void;
  onClickCell?: (e: React.MouseEvent, columnId: string, row: any) => void;
  onClickFilters?: () => void;
  onClickAux?: () => void;
}

export const TableBrioCard = (props: TableBrioCardProps) => {
  const {
    idx,
    title = "",
    btnText = i18n.t("words.add"),
    btnIcon,
    auxBtn = false,
    auxBtnText = "",
    auxBtnIcon,
    headerText = "",
    colums = [],
    rows,
    childColumns = [],
    selectedRows = [],
    settings = false,
    disableRows = [],
    disableDelete = false,
    disableBtn = false,
    disableIcon = <Delete />,
    disableTooltip = i18n.t("words.delete"),
    hideBtn = false,
    hideCheckbox = false,
    hideBtnIcon = false,
    defaultOrderFieldName = "date",
    children,
    filtersChildren,
    filtersButton = false,
    activeFilters,
    isLoading,
    emptyTableCard = true,
    emptyTableIcon = <Image />,
    emptyTableTitle = i18n.t("words.noData"),
    emptyTableSubtitle = "",
    emptyTableBtnText,
    required = false,
    optional = false,
    addBtnVariant = "outlined",
    onChangeSelectedRows,
    onClickAdd,
    onClickSettings,
    onClickEdit,
    onClickDelete,
    onClickFilters,
  } = props;
  const isGroupedTable = childColumns.length > 0;

  const [order, setOrder] = useState<Order>("desc");
  const [orderBy, setOrderBy] = useState<string>(defaultOrderFieldName);
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(5);
  const [allRowsSelected, setAllRowsSelected] = useState<boolean>(false);

  const handleOnClickRow = (id: number) => {
    onClickEdit && onClickEdit(id);
  };

  const handleOnClick = (id: number) => {
    const selectedIndex = selectedRows.indexOf(id);
    let newSelected: number[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedRows, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedRows.slice(1));
    } else if (selectedIndex === selectedRows.length - 1) {
      newSelected = newSelected.concat(selectedRows.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedRows.slice(0, selectedIndex),
        selectedRows.slice(selectedIndex + 1)
      );
    }
    onChangeSelectedRows && onChangeSelectedRows(newSelected);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isSelectedAll = !allRowsSelected;
    if (isSelectedAll) {
      // Filter disabled rows and get indexes
      const newSelected = rows
        ?.filter(
          (row) =>
            !disableRows.includes(idx ? row[idx] : row.id) && !row.editDisabled
        )
        ?.map((row) => (idx ? row[idx] : row.id));
      onChangeSelectedRows && newSelected && onChangeSelectedRows(newSelected);
    } else onChangeSelectedRows && onChangeSelectedRows([]);
    setAllRowsSelected(isSelectedAll);
  };

  const isSelected = (id: number) => selectedRows.indexOf(id) !== -1;

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: string
  ) => {
    // Replace dateFormatted by date to sort by date
    let prop = property;
    if (property === "dateFormatted") prop = "date";
    else if (property === "dateRange") prop = "startDate";
    else if (property === "creationDate") prop = "creationInstant";
    const isAsc = orderBy === prop && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(prop);
  };

  const handleClickDelete = () => {
    if (selectedRows.length > 0) {
      onClickDelete && onClickDelete(selectedRows);
    }
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const visibleRows: any[] = React.useMemo(
    () =>
      rows
        ? stableSort(rows, getComparator(order, orderBy)).slice(
            page * rowsPerPage,
            page * rowsPerPage + rowsPerPage
          )
        : [],
    [rows, order, orderBy, page, rowsPerPage]
  );

  return (
    <div className="briocard">
      <BrioCardTableToolbar
        title={title}
        btnText={btnText}
        btnIcon={btnIcon}
        auxBtn={auxBtn}
        auxBtnText={auxBtnText}
        auxBtnIcon={auxBtnIcon}
        settings={settings}
        numSelected={selectedRows.length}
        disableDelete={disableDelete}
        disableBtn={disableBtn}
        disableIcon={disableIcon}
        disableTooltip={disableTooltip}
        hideBtn={hideBtn}
        hideBtnIcon={hideBtnIcon}
        isLoading={isLoading}
        required={required}
        optional={optional}
        addBtnVariant={addBtnVariant}
        filtersButton={filtersButton}
        onClickAdd={onClickAdd}
        onClickSettings={onClickSettings}
        onClickDelete={handleClickDelete}
        onClickFilters={onClickFilters}
        onClickAux={props.onClickAux}
      />
      <div className="content-table">
        {headerText.length > 0 && <p>{headerText}</p>}
        {filtersChildren}
        {!isLoading &&
          (!rows || rows.length === 0) &&
          (activeFilters ? (
            <EmptyTable
              icon={emptyTableIcon}
              title={i18n.t("formErrors.notFoundFilterResults")}
            />
          ) : emptyTableCard ? (
            <EmptyTable
              icon={emptyTableIcon}
              title={emptyTableTitle}
              subtitle={emptyTableSubtitle}
              btnText={emptyTableBtnText}
              onClick={onClickAdd}
            />
          ) : null)}
        {rows && rows.length > 0 && (
          <div className="table">
            <TableContainer className="table-container">
              <Table stickyHeader>
                <BrioCardTableHead
                  isGroupedTable={isGroupedTable}
                  numSelected={selectedRows.length}
                  rowCount={rows?.length}
                  columns={colums}
                  order={order}
                  orderBy={orderBy}
                  hideCheckbox={hideCheckbox}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                />
                <TableBody>
                  {visibleRows?.map((row) => {
                    const id = idx ? row[idx] : row.id;
                    return (
                      <TableRowItem
                        key={id}
                        idx={idx}
                        row={row}
                        colums={colums}
                        childColumns={childColumns}
                        isSelected={isSelected(idx ? row[idx] : row.id)}
                        isDisabled={disableRows.includes(id)}
                        isGroupedTable={isGroupedTable}
                        hideCheckbox={hideCheckbox}
                        onClickRow={
                          selectedRows.length > 0
                            ? handleOnClick
                            : handleOnClickRow
                        }
                        onClickCheckbox={handleOnClick}
                        onClickCell={props.onClickCell}
                      />
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              className="table-pagination"
              component="div"
              count={rows?.length || 0}
              rowsPerPage={rowsPerPage}
              page={page}
              labelRowsPerPage={i18n.t("words.rowsByPage")}
              labelDisplayedRows={({ from, to, count }) =>
                `${from}-${to} de ${count}`
              }
              rowsPerPageOptions={[5, 10, 25]}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </div>
        )}
      </div>
      {children && <div className="content"> {children}</div>}
    </div>
  );
};

interface TableRowProps {
  idx?: string;
  row: any;
  colums?: Column[];
  childColumns?: Column[];
  isSelected?: boolean;
  isDisabled?: boolean;
  isGroupedTable?: boolean;
  hideCheckbox?: boolean;
  onClickRow?: (id: number) => void;
  onClickCheckbox?: (id: number) => void;
  onClickCell?: (e: React.MouseEvent, columnId: string, row: any) => void;
}
const TableRowItem = (props: TableRowProps) => {
  const {
    idx,
    row,
    colums,
    childColumns,
    isSelected = false,
    isDisabled = false,
    isGroupedTable = false,
    hideCheckbox = false,
    onClickRow,
    onClickCheckbox,
  } = props;

  const rowId = idx ? row[idx] : row.id;
  const disabled = isDisabled || row?.editDisabled;
  const isCollapsibleRow = row?.childRows && row.childRows.length > 0;
  const collapseRowId = "collapseRow";

  const [isRowExpanded, setIsRowExpanded] = useState<boolean>(false);

  const handleClickRow = (e: React.MouseEvent) => {
    e.stopPropagation();
    !disabled && onClickRow && onClickRow(rowId);
  };

  const handleClickCheckbox = (e: React.MouseEvent) => {
    e.stopPropagation();
    !disabled && onClickCheckbox && onClickCheckbox(rowId);
  };

  const handleClickCollapseCell = (e: React.MouseEvent) => {
    e.stopPropagation();
    setIsRowExpanded(!isRowExpanded);
  };

  const collapseCellColumn: Column = {
    id: "collapse",
    label: "",
    icon: isRowExpanded ? KeyboardArrowUp : KeyboardArrowDown,
    align: "center",
    notSortable: true,
  };

  return (
    <>
      <TableRow
        className={`table-row${isSelected ? "-selected" : ""}`}
        role="checkbox"
        selected={isSelected}
        onClick={isCollapsibleRow ? handleClickCollapseCell : handleClickRow}
      >
        {!hideCheckbox && (
          <TableCell
            className="table-cell"
            padding="checkbox"
            onClick={handleClickCheckbox}
          >
            <Checkbox
              className="checkbox"
              checked={isSelected && !disabled}
              disabled={disabled}
            />
          </TableCell>
        )}
        {colums?.map((column) => (
          <TableCellItem
            key={column.id}
            idx={idx}
            column={column}
            row={row}
            onClick={props.onClickCell}
          />
        ))}
        {isGroupedTable &&
          (row?.childRows ? (
            <TableCellIconItem
              column={collapseCellColumn}
              row={{ id: collapseRowId }}
            />
          ) : (
            <TableCell className="table-cell" align="center" />
          ))}
      </TableRow>
      {row?.childRows && (
        <TableRowGrouped
          columns={childColumns}
          rows={row.childRows}
          isSelected={isSelected}
          isCollapsed={isRowExpanded}
          onClickChildRow={() => onClickRow && onClickRow(row.id)}
        />
      )}
    </>
  );
};

interface TableCellItemProps {
  idx?: string;
  column: Column;
  row: any;
  onClick?: (e: React.MouseEvent, columnId: string, row: any) => void;
}
const TableCellItem = (props: TableCellItemProps) => {
  const { column } = props;

  if (column.valueLabel === "listItem") return <TableCellListItem {...props} />;
  if (column.valueLabel === "icon") return <TableCellIconItem {...props} />;

  return <TableCellTextItem {...props} />;
};

const TableCellListItem = (props: TableCellItemProps) => {
  const { column, row, onClick } = props;

  const primaryText = getFieldValue(row, column.primaryTextFieldName);
  const secondaryText = getFieldValue(row, column.secondaryTextFieldName);
  const avatarSrc = getFieldValue(row, column.avatarSrcFieldName);

  const handleClickAvatar = (e: React.MouseEvent) => {
    onClick && onClick(e, column.id, row);
  };

  return (
    <TableCell
      className="table-cell"
      align={column.align || (column.numeric ? "right" : "left")}
    >
      <ListItem className="list-item">
        <ListItemAvatar className="list-item-avatar">
          <Avatar
            className="avatar"
            variant="rounded"
            src={avatarSrc}
            imgProps={{ className: "avatar-image" }}
            onClick={handleClickAvatar}
          >
            <Image />
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          className="list-item-text"
          primary={primaryText}
          secondary={secondaryText}
          primaryTypographyProps={{
            variant: "subtitle1",
            className: "table-cell",
          }}
          secondaryTypographyProps={{
            variant: "subtitle2",
            className: "list-item-field-secondary-text",
          }}
        />
      </ListItem>
    </TableCell>
  );
};

const TableCellTextItem = (props: TableCellItemProps) => {
  const { column, row } = props;

  const getJustifyContent = (column: Column) => {
    const align = column.align || (column.numeric ? "right" : "left");
    switch (align) {
      case "left":
        return "flex-start";
      case "right":
        return "flex-end";
      default:
        return "center";
    }
  };

  const value = getFieldValueByColumnNotation(
    column.id,
    row,
    column.valueLabel,
    column.arrayFieldName,
    column.unitFieldName,
    column.unitValue,
    column.id2
  );
  const align = column.align || (column.numeric ? "right" : "left");
  const justifyContent = getJustifyContent(column);

  return (
    <TableCell className="table-cell" align={align}>
      <div className="table-cell-new" style={{ justifyContent }}>
        {column.valueLabel === "isNew" &&
          getFieldValue(row, column.isNewFieldName) && (
            <Tooltip title={i18n.t("words.new")}>
              <NewReleases color="primary" sx={{ mr: 1 }} />
            </Tooltip>
          )}
        <span>{value}</span>
      </div>
    </TableCell>
  );
};

const TableCellIconItem = (props: TableCellItemProps) => {
  const { column, row, idx, onClick } = props;
  const { icon: Icon } = column;
  const rowId = idx ? row[idx] : row.id;

  const handleClick = (e: React.MouseEvent) => {
    onClick && onClick(e, column.id, rowId);
  };

  return (
    <TableCell
      className="table-cell"
      align={column.align || (column.numeric ? "right" : "left")}
      onClick={handleClick}
    >
      {Icon && <Icon />}
    </TableCell>
  );
};

interface TableRowGroupedProps {
  rows: any[];
  columns?: Column[];
  isSelected?: boolean;
  isCollapsed?: boolean;
  onClickChildRow?: (id: number) => void;
}
const TableRowGrouped = (props: TableRowGroupedProps) => {
  const { rows, columns, isSelected, isCollapsed, onClickChildRow } = props;

  const handleClick = (id: number) => {
    onClickChildRow && onClickChildRow(id);
  };

  return (
    <TableRow>
      <TableCell style={{ padding: 0 }} colSpan={6}>
        <Collapse in={isCollapsed}>
          <Table size="small">
            <TableBody>
              {rows.map((row: any) => {
                return (
                  <TableRow
                    key={row.id}
                    className={isSelected ? "table-row-selected" : ""}
                    onClick={() => handleClick(row.id)}
                  >
                    <TableCell className="table-cell" padding="checkbox" />
                    {columns?.map((column) => (
                      <TableCellItem
                        key={column.id}
                        column={column}
                        row={row}
                      />
                    ))}
                    <TableCell
                      style={{ padding: 12 }}
                      className="table-cell"
                      align="center"
                    />
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </Collapse>
      </TableCell>
    </TableRow>
  );
};
