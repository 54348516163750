import CropProduct from "../../catalogue/CropProduct";

class FBVariety {
  id?: number;
  code?: string;
  name?: string;
  productType?: CropProduct;

  constructor(obj?: any) {
    this.id = obj?.id;
    this.code = obj?.code;
    this.name = obj?.name;
    this.productType = new CropProduct(obj?.productType);
  }

  mapBaseToApiModel(): any {
    const obj: any = { ...this };
    obj.productType = this.productType?.mapToApiModel();
    return obj;
  }
}

export default FBVariety;
