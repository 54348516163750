import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import i18n from "../../config/configI18n";

import {
  useTheme,
  useMediaQuery,
  InputAdornment,
  TextField,
  IconButton,
  Drawer,
  LinearProgress,
  Button,
} from "@mui/material";
import { Search, Close } from "@mui/icons-material";

import ChatNavbar from "./ChatNavbar";
import ChatListItem from "./ChatListItem";

import AdvisorChat from "../../models/advisor/AdvisorChat";

import { useSession } from "../../hooks/useSession";
import { useChat } from "../../hooks/useChat";

import { findWord, updateList } from "../../helpers/utils";
import { PROTECTED_ROUTES } from "../../routes/routeNames";

let lastEndpointUrl: string;

interface Props {
  selectedChat?: AdvisorChat | null;
  onChangeSelectedChat?: (advisorChat: AdvisorChat | null) => void;
}
const ChatList = (props: Props) => {
  const { selectedChat, onChangeSelectedChat } = props;

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("medium"));

  const [chatSearchWord, setChatSearchWord] = useState<string>("");
  const [chats, setChats] = useState<AdvisorChat[]>([]);
  const [filteredChats, setFilteredChats] = useState<AdvisorChat[]>([]);
  const [isSubscribing, setIsSubscribing] = useState<boolean>(true);

  const { selectedCueAccount } = useSession();
  const { chatClient, isConnected } = useChat();

  const updateAndSortChats = (
    oldChats: AdvisorChat[],
    newChats: AdvisorChat[]
  ): AdvisorChat[] => {
    const ul = updateList(oldChats, newChats, "id") as AdvisorChat[];
    return ul.sort(
      (a, b) =>
        (b.lastMessage?.messageTimestamp || 0) -
        (a.lastMessage?.messageTimestamp || 0)
    );
  };

  const subscripeToChatListUpdates = (url: string) => {
    setIsSubscribing(true);
    setChats([]);
    onChangeSelectedChat?.(null);
    chatClient?.subscribe(url, (message) => {
      const data = JSON.parse(message.body);
      const newChats = data?.map((message: any) => new AdvisorChat(message));
      // Set chats and order them by last message timestamp (last at the top)
      setChats((chats) => updateAndSortChats(chats, newChats));
      setIsSubscribing(false);
    });
    lastEndpointUrl = url;
  };

  // Reset lastEndpointUrl when chatClient is disconnected to force resubscribe when connected again
  useEffect(() => {
    if (!isConnected) lastEndpointUrl = "";
  }, [isConnected]);

  // Subscribe to chat list updates
  useEffect(() => {
    const selectedCueAccountId = selectedCueAccount?.cueAccount?.id;
    const newEndpointUrl = `/advisor/${selectedCueAccountId}/updates`;

    if (isConnected && lastEndpointUrl !== newEndpointUrl) {
      if (chatClient?.connected && lastEndpointUrl)
        chatClient.unsubscribe(lastEndpointUrl);
      if (chatClient?.connected && selectedCueAccountId) {
        subscripeToChatListUpdates(newEndpointUrl);
      }
    }

    return () => {
      if (chatClient?.connected && lastEndpointUrl)
        chatClient.unsubscribe(lastEndpointUrl);
    };
  }, [isConnected, selectedCueAccount]);

  // Filter chats by search word
  useEffect(() => {
    setFilteredChats(
      chats.filter(
        (advisorChat) =>
          findWord(advisorChat?.profileName, chatSearchWord) ||
          findWord(
            advisorChat?.defaultAgroBusinessAccount?.name,
            chatSearchWord
          )
      )
    );
  }, [chats, chatSearchWord]);

  // Reset unread messages count of selected chat
  useEffect(() => {
    setChats((chats) =>
      chats.map(
        (chat) =>
          (chat.id === selectedChat?.id
            ? { ...chat, unreadMessagesCount: 0 }
            : chat) as AdvisorChat
      )
    );
  }, [selectedChat]);

  const handleChangeSearchWord = (sw: string) => {
    setChatSearchWord(sw);
  };

  const handleClearSearch = () => {
    setChatSearchWord("");
    setFilteredChats(chats);
  };

  return (
    <div className="chat-list">
      <div className="chat-list-fixed">
        <ChatListContent
          isSubscribed={isConnected && !isSubscribing}
          chats={filteredChats}
          selectedChat={selectedChat}
          chatSearchWord={chatSearchWord}
          onChangeSearchWord={handleChangeSearchWord}
          onClearSearch={handleClearSearch}
          onChangeSelectedChat={onChangeSelectedChat}
        />
      </div>

      <Drawer
        className="chat-list-temp-none"
        variant="persistent"
        anchor="left"
        ModalProps={{ keepMounted: true }}
        open={!selectedChat && isSmallScreen}
        PaperProps={{ className: "chat-list-temp" }}
      >
        <ChatListContent
          isSubscribed={isConnected && !isSubscribing}
          chats={filteredChats}
          selectedChat={selectedChat}
          chatSearchWord={chatSearchWord}
          onChangeSearchWord={handleChangeSearchWord}
          onClearSearch={handleClearSearch}
          onChangeSelectedChat={onChangeSelectedChat}
        />
      </Drawer>
    </div>
  );
};

export default ChatList;

interface ContentProps {
  isSubscribed?: boolean;
  chats?: AdvisorChat[];
  selectedChat?: AdvisorChat | null;
  chatSearchWord?: string;
  onChangeSearchWord?: (sw: string) => void;
  onClearSearch?: () => void;
  onChangeSelectedChat?: (advisorChat: AdvisorChat) => void;
}
const ChatListContent = (props: ContentProps) => {
  const {
    isSubscribed,
    chats = [],
    selectedChat,
    chatSearchWord = "",
    onChangeSearchWord,
    onClearSearch,
    onChangeSelectedChat,
  } = props;

  const navigate = useNavigate();

  const handleChangeSearchWord = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (onChangeSearchWord) onChangeSearchWord(e.target.value);
  };

  const handleClickGoToPermissions = () => {
    navigate(`/${PROTECTED_ROUTES.EXPLOITATIONS_PERMISSIONS}`, {
      replace: true,
    });
  };

  return (
    <div className="container">
      <ChatNavbar />
      <div className="search-bar">
        <TextField
          className="search-bar-input"
          variant="outlined"
          size="small"
          placeholder={i18n.t("chat.searchBarPlaceholder")}
          fullWidth
          value={chatSearchWord}
          onChange={handleChangeSearchWord}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
            endAdornment: chatSearchWord.length > 0 && (
              <InputAdornment position="end">
                <IconButton onClick={onClearSearch}>
                  <Close />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </div>
      {!isSubscribed && <LinearProgress sx={{ width: "100%" }} />}
      {isSubscribed && chats.length === 0 ? (
        <div className="empty-chats-container">
          <p className="empty-chat-text">{i18n.t("chat.emptyChatText")}</p>
          <Button variant="contained" onClick={handleClickGoToPermissions}>
            {i18n.t("chat.emptyChatBtnText")}
          </Button>
        </div>
      ) : (
        <div className="scrollable-content">
          {chats.map((advisorChat) => (
            <ChatListItem
              key={advisorChat.id}
              advisorChat={advisorChat}
              selected={advisorChat?.id === selectedChat?.id}
              onClick={onChangeSelectedChat}
            />
          ))}
        </div>
      )}
    </div>
  );
};
