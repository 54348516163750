export const PUBLIC_ROUTES = {
  HOME: "/",
  LOGIN: "/login",
  REGISTER: "/register",
  EMAIL_VERIFICATION: "/email-verification",
  WHATSAPP_VERIFICATION: "/whatsapp-verification",
  POLICIES: "/policies",
};

export const PROTECTED_ROUTES = {
  ONBOARDING: "onboarding",
  DASHBOARD: "dashboard",
  EXPLOITATIONS: "exploitations/list",
  EXPLOITATIONS_PERMISSIONS: "exploitations/permissions",
  STAFF: "staff",
  NOTES: "notes",
  CHAT: "chat",
  SETTINGS: "settings",
  VADEMECUM_PHYTO: "vademecum/phyto",
  VADEMECUM_FERTILIZER: "vademecum/fertilizer",
  ACCOUNT_SYNC: "account-sync",
  EXPLOITATION_RECIPES: "exploitation/recipes",
  SIEX_PENDING: "siex/pending",
  SIEX_ERRORS: "siex/errors",
};

export const SAVED_ROUTES = {
  DASHBOARD: "dashboard",
  EXPLOITATIONS: "exploitations/list",
  NOTES: "notes",
  SETTINGS: "settings",
  VADEMECUM_PHYTO: "vademecum/phyto",
  VADEMECUM_FERTILIZER: "vademecum/fertilizer",
  EXPLOITATION_RECIPES: "exploitation/recipes",
};

export const FARMER_SIEX_ROUTE = "siex/redirect";
