import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import { Alert, AlertTitle, Button, Collapse } from "@mui/material";
import i18n from "../../config/configI18n";

import { useSession } from "../../hooks/useSession";
import useFetch from "../../hooks/useFetch";

import AgroBusinessAccount from "../../models/account/AgroBusinessAccount";

import { PROTECTED_ROUTES } from "../../routes/routeNames";

const NewExploitationsBanner = () => {
  const { selectedCueAccount } = useSession();
  const navigate = useNavigate();
  const location = useLocation();

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const { data: agroBusinessAccounts, isFetching } = useFetch<
    AgroBusinessAccount[]
  >({
    queryKey: ["agroBusinessAccounts", selectedCueAccount?.cueAccount?.id],
    enabled: !!selectedCueAccount,
  });

  useEffect(() => {
    const newExploitations =
      agroBusinessAccounts?.filter((aba) => aba.recentlyTransferred)?.length ||
      0;
    setIsOpen(newExploitations > 0);
  }, [agroBusinessAccounts]);

  const goToExploitationsScreen = () => {
    navigate(PROTECTED_ROUTES.EXPLOITATIONS);
  };

  if (isFetching || location.pathname.includes(PROTECTED_ROUTES.EXPLOITATIONS))
    return null;

  return (
    <Collapse in={isOpen}>
      <Alert
        severity="info"
        onClose={() => setIsOpen(false)}
        action={
          <Button
            size="small"
            color="inherit"
            onClick={goToExploitationsScreen}
          >
            {i18n.t("components.newExploitationsBanner.btnText")}
          </Button>
        }
      >
        <AlertTitle>
          {i18n.t("components.newExploitationsBanner.title")}
        </AlertTitle>
      </Alert>
    </Collapse>
  );
};

export default NewExploitationsBanner;
