import FBAgroBusiness from "../_api/agrobusiness/FBAgroBusiness";

class AgroBusiness extends FBAgroBusiness {
  constructor(obj?: any) {
    super(obj);
  }

  mapToApiModel(): any {
    const obj: any = super.mapBaseToApiModel();
    return obj;
  }
}

export default AgroBusiness;
