import { useState, useMemo } from "react";
import { useQueryClient } from "@tanstack/react-query";
import i18n from "../config/configI18n";

import { Autocomplete, TextField } from "@mui/material";
import { Search, SyncProblem } from "@mui/icons-material";

import ScreenContentLayout from "../components/ScreenContentLayout";
import { TableBrioCard } from "../components/BrioCard";
import TestBanner from "../components/banners/TestBanner";

import { useSession } from "../hooks/useSession";
import useFetch from "../hooks/useFetch";

import AgroBusinessAccount from "../models/account/AgroBusinessAccount";

import { Column } from "../constants/interfaces";
import { EMPTY_TABLE_ICON_SIZE } from "../constants/constants";
import { FARMER_SIEX_ROUTE } from "../routes/routeNames";

const SiexErrorsScreen = () => {
  const { selectedCueAccount, siexErrorRegisters } = useSession();
  const queryClient = useQueryClient();

  const [selectedABAccount, setSelectedABAccount] =
    useState<AgroBusinessAccount | null>(null);

  const { data: agroBusinessAccounts, isFetching: isFetchingABAs } = useFetch<
    AgroBusinessAccount[]
  >({
    queryKey: ["agroBusinessAccounts", selectedCueAccount?.cueAccount?.id],
    enabled: !!selectedCueAccount,
  });

  const filteredSiexErrors = useMemo(() => {
    if (selectedABAccount === null)
      return siexErrorRegisters.map((errorRegister) => ({
        ...errorRegister,
        agrobusinessAccountName: agroBusinessAccounts?.find(
          (aba) =>
            aba.agroBusiness?.id === errorRegister.request?.agroBusiness?.id
        )?.name,
      }));
    return siexErrorRegisters
      .filter(
        (errorRegister) =>
          errorRegister.request?.agroBusiness?.id ===
          selectedABAccount.agroBusiness?.id
      )
      .map((errorRegister) => ({
        ...errorRegister,
        agrobusinessAccountName: selectedABAccount.name,
      }));
  }, [selectedABAccount, siexErrorRegisters, agroBusinessAccounts]);

  const emptyTableTitle = useMemo(
    () =>
      selectedABAccount === null
        ? i18n.t("siexErrors.emptyTableTitle")
        : selectedABAccount?.agroBusiness?.siexEntity
        ? i18n.t("siexErrors.emptyTableTitleByExploitation")
        : i18n.t("siexErrors.emptyTableTitleNoSiexExploitation"),
    [selectedABAccount]
  );

  const siexErrorsColumns: Column[] = [
    {
      id: "section.label",
      label: i18n.t("siexErrors.column.sectionName"),
      numeric: false,
    },
    {
      id: "request.requestDateFormatted",
      label: i18n.t("siexErrors.column.dateFormatted"),
      numeric: false,
    },
    {
      id: "agrobusinessAccountName",
      label: i18n.t("siexErrors.column.agrobusinessAccountName"),
      numeric: false,
    },
    {
      id: "errorMessage",
      label: i18n.t("siexErrors.column.errorMessage"),
      numeric: false,
    },
  ];

  const handleChangeSelectedAgroBusinessAccount = (
    event: React.SyntheticEvent,
    newValue: AgroBusinessAccount | null
  ) => {
    setSelectedABAccount(newValue);
    queryClient.invalidateQueries(["siexErrorRegisters"]);
  };

  const onClick = (id: number) => {
    const errorRegister = siexErrorRegisters.find((s) => s.id === id);
    const agroBusinessId = errorRegister?.request?.agroBusiness?.id;
    if (errorRegister && agroBusinessId) {
      const url = `${process.env.REACT_APP_CUSTOMER_WEB_URL}/${FARMER_SIEX_ROUTE}/${agroBusinessId}/${errorRegister.entityClassId}/${errorRegister.entityClassName}`;
      window.open(url, "_blank", "noopener,noreferrer");
    }
  };

  const TableFiltersMemo = useMemo(() => {
    return (
      <div className="content-table-filters">
        <Autocomplete
          id="agroBusinessAccounts-filter"
          options={agroBusinessAccounts || []}
          getOptionLabel={(option) => option?.name || ""}
          noOptionsText={i18n.t("formErrors.notFoundResults")}
          loading={isFetchingABAs}
          value={selectedABAccount}
          onChange={handleChangeSelectedAgroBusinessAccount}
          renderInput={(params) => (
            <TextField
              {...params}
              size="small"
              sx={{ minWidth: 250, maxWidth: 300 }}
              label={i18n.t("siexErrors.agrobusinessAccountFilterLabel")}
              placeholder={i18n.t(
                "siexErrors.agrobusinessAccountFilterPlaceholder"
              )}
              InputProps={{ ...params.InputProps, startAdornment: <Search /> }}
            />
          )}
        />
      </div>
    );
  }, [selectedABAccount, isFetchingABAs]);

  return (
    <ScreenContentLayout>
      <TestBanner />
      <TableBrioCard
        title={i18n.t("siexErrors.tableTitle")}
        headerText={i18n.t("siexErrors.headerText")}
        colums={siexErrorsColumns}
        rows={filteredSiexErrors}
        filtersChildren={TableFiltersMemo}
        defaultOrderFieldName="request.responseTimestamp"
        addBtnVariant="contained"
        hideBtn
        hideCheckbox
        emptyTableIcon={
          <SyncProblem sx={{ fontSize: EMPTY_TABLE_ICON_SIZE }} />
        }
        emptyTableTitle={emptyTableTitle}
        onClickEdit={onClick}
      />
    </ScreenContentLayout>
  );
};

export default SiexErrorsScreen;
