import i18n from "../../config/configI18n";

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { Sync } from "@mui/icons-material";

interface Props {
  isOpen: boolean;
  isLoading?: boolean;
  onConfirm?: () => void;
  onCancel?: () => void;
}
const DialogConfirmSiexSync = (props: Props) => {
  const { isOpen, isLoading = false, onConfirm, onCancel } = props;

  return (
    <Dialog className="dialog" open={isOpen} onClose={onCancel}>
      <DialogTitle className="title">
        {i18n.t("components.dialogConfirmSiexSync.title")}
      </DialogTitle>
      <DialogContent className="content">
        <DialogContentText>
          {i18n.t("components.dialogConfirmSiexSync.description")}
        </DialogContentText>
        <DialogActions>
          <Button onClick={onCancel} color="inherit">
            {i18n.t("words.cancel")}
          </Button>
          <LoadingButton
            variant="contained"
            color="primary"
            startIcon={<Sync sx={{ color: "white" }} />}
            loading={isLoading}
            disabled={isLoading}
            onClick={onConfirm}
          >
            {i18n.t("components.dialogConfirmSiexSync.confirmBtnText")}
          </LoadingButton>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

export default DialogConfirmSiexSync;
