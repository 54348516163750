class FBActiveSubstance {
  id?: number;
  name?: string;
  code?: string;

  constructor(obj?: any) {
    this.id = obj?.id;
    this.name = obj?.name;
    this.code = obj?.code;
  }

  mapBaseToApiModel(): any {
    const obj: any = { ...this };
    return obj;
  }
}

export default FBActiveSubstance;
