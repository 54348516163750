import { createContext, useContext, useMemo, useState, useEffect } from "react";
import { Cookies } from "react-cookie";

import CueAccountWithRole from "../models/account/CueAccountWithRole";
import SiexRequestItem from "../models/siex/SiexRequestItem";

interface Session {
  advisorCueAccounts: CueAccountWithRole[];
  selectedCueAccount: CueAccountWithRole | null;
  siexPendingRegisters: SiexRequestItem[];
  setSiexPendingRegisters: (value: SiexRequestItem[]) => void;
  siexErrorRegisters: SiexRequestItem[];
  setSiexErrorRegisters: (value: SiexRequestItem[]) => void;
  changeSelectedCueAccount: (cueAccount: CueAccountWithRole) => void;
}

const SessionContext = createContext<Session>({} as Session);

interface Props {
  children: React.ReactNode;
  advisorCueAccounts?: CueAccountWithRole[] | null;
}
export const SessionProvider = (props: Props) => {
  const { children, advisorCueAccounts = [] } = props;
  const cookies = new Cookies();

  const [selectedCueAccount, setSelectedCueAccount] =
    useState<CueAccountWithRole | null>(null);
  const [siexPendingRegisters, setSiexPendingRegisters] = useState<
    SiexRequestItem[]
  >([]);
  const [siexErrorRegisters, setSiexErrorRegisters] = useState<
    SiexRequestItem[]
  >([]);

  // Get last saved from cookies if exits
  useEffect(() => {
    const lastSelectedCueAccountId = cookies.get("selectedCueAccountId");
    if (lastSelectedCueAccountId) {
      const lastSelectedCueAccount = advisorCueAccounts?.find(
        (cawr) => cawr.cueAccount?.id === lastSelectedCueAccountId
      );
      if (lastSelectedCueAccount) setSelectedCueAccount(lastSelectedCueAccount);
    } else setSelectedCueAccount(advisorCueAccounts?.[0] || null);
  }, [advisorCueAccounts]);

  const changeSelectedCueAccount = (cueAccount: CueAccountWithRole) => {
    setSelectedCueAccount(cueAccount);
    // Save last selection in cookies
    const cueAccountId = cueAccount?.cueAccount?.id;
    if (cueAccountId)
      cookies.set("selectedCueAccountId", cueAccountId, { path: "/" });
  };

  const value = useMemo(
    () => ({
      advisorCueAccounts: advisorCueAccounts || [],
      selectedCueAccount,
      changeSelectedCueAccount,
      siexPendingRegisters,
      setSiexPendingRegisters,
      siexErrorRegisters,
      setSiexErrorRegisters,
    }),
    [
      advisorCueAccounts,
      selectedCueAccount,
      changeSelectedCueAccount,
      siexPendingRegisters,
      setSiexPendingRegisters,
      siexErrorRegisters,
      setSiexErrorRegisters,
    ]
  );

  return (
    <SessionContext.Provider value={value}>{children}</SessionContext.Provider>
  );
};

export const useSession = (): Session => {
  return useContext<Session>(SessionContext);
};
