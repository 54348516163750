import Person from "../../Person";

import { FBPersonRole } from "../../../constants/enums";

class FBAgroBusinessAccountPerson {
  person?: Person;
  role?: FBPersonRole;
  whatsappEnabled?: boolean;

  constructor(obj?: any) {
    this.person = new Person(obj?.person);
    this.role = obj?.role;
    this.whatsappEnabled = obj?.whatsappEnabled;
  }

  mapBaseToApiModel(): any {
    const obj: any = { ...this };
    return obj;
  }
}

export default FBAgroBusinessAccountPerson;
