import { EntityClassNameInterface } from "../../constants/interfaces";
import { ENTITY_CLASS_NAMES } from "../../providers/YupProvider";
import FBSiexRequestItem from "../_api/siex/FBSiexRequestItem";

class SiexRequestItem extends FBSiexRequestItem {
  section?: EntityClassNameInterface;
  agrobusinessAccountName?: string;

  constructor(obj?: any) {
    super(obj);
    if (this.entityClassName)
      this.section = ENTITY_CLASS_NAMES.find(
        (s) => s.name === this.entityClassName
      );
    this.agrobusinessAccountName = obj?.agrobusinessAccountName;
  }

  mapToApiModel(): any {
    const obj: any = this.mapBaseToApiModel();

    delete obj.section;
    delete obj.agrobusinessAccountName;
    return obj;
  }
}

export default SiexRequestItem;
