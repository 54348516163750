import Person from "../../Person";
import AgroBusinessAccount from "../../account/AgroBusinessAccount";
import AdvisorChatMessage from "../../advisor/AdvisorChatMessage";

class FBAdvisorChat {
  id?: number;
  person?: Person;
  lastMessageTimestamp?: number;
  acceptanceSentOnTimestamp?: number;
  acceptedOnTimestamp?: number;
  declineAcceptanceOnTimestamp?: number;
  profileName?: string;
  defaultAgroBusinessAccount?: AgroBusinessAccount;
  advisorMessagesPending?: boolean;
  enabledDate?: string;
  disabledDate?: string;
  unreadMessagesCount?: number;
  lastMessage?: AdvisorChatMessage;

  constructor(obj?: any) {
    this.id = obj?.id;
    this.person = new Person(obj?.person);
    this.lastMessageTimestamp = obj?.lastMessageTimestamp;
    this.acceptanceSentOnTimestamp = obj?.acceptanceSentOnTimestamp;
    this.acceptedOnTimestamp = obj?.acceptedOnTimestamp;
    this.declineAcceptanceOnTimestamp = obj?.declineAcceptanceOnTimestamp;
    this.profileName = obj?.profileName;
    this.defaultAgroBusinessAccount = new AgroBusinessAccount(
      obj?.defaultAgroBusinessAccount
    );
    this.advisorMessagesPending = obj?.advisorMessagesPending;
    this.enabledDate = obj?.enabledDate;
    this.disabledDate = obj?.disabledDate;
    this.unreadMessagesCount = obj?.unreadMessagesCount;
    this.lastMessage = new AdvisorChatMessage(obj?.lastMessage);
  }

  mapBaseToApiModel(): any {
    const obj: any = { ...this };
    return obj;
  }
}

export default FBAdvisorChat;
