import ActiveSubstance from "../../catalogue/ActiveSubstance";
import MeasurementUnit from "../../catalogue/MeasurementUnit";
import PhytosanitaryProductType from "../../catalogue/PhytosanitaryProductType";
import RegisteredPhytosanitaryProduct from "../../vademecum/RegisteredPhytosanitaryProduct";

class FBPhytosanitaryProduct {
  id?: number;
  type?: PhytosanitaryProductType;
  productId?: number;
  registerId?: string;
  name?: string; //*
  owner?: string;
  formulation?: string;
  activeSubstances?: ActiveSubstance[];
  expirationDate?: string;
  measurementUnit?: MeasurementUnit; //*
  registeredPhytosanitaryProduct?: RegisteredPhytosanitaryProduct;
  enabledDate?: string;
  disabledDate?: string;

  constructor(obj?: any) {
    this.id = obj?.id;
    this.type = new PhytosanitaryProductType(obj?.type);
    this.productId = obj?.productId;
    this.registerId = obj?.registerId;
    this.name = obj?.name;
    this.owner = obj?.owner;
    this.formulation = obj?.formulation;
    this.activeSubstances = obj?.activeSubstances?.map(
      (activeSubstance: any) => new ActiveSubstance(activeSubstance)
    );
    this.expirationDate = obj?.expirationDate;
    this.measurementUnit = new MeasurementUnit(obj?.measurementUnit);
    if (obj?.registeredPhytosanitaryProduct)
      this.registeredPhytosanitaryProduct = new RegisteredPhytosanitaryProduct(
        obj.registeredPhytosanitaryProduct
      );
    this.enabledDate = obj?.enabledDate;
    this.disabledDate = obj?.disabledDate;
  }

  mapBaseToApiModel(): any {
    const obj: any = { ...this };
    obj.type = this.type?.mapBaseToApiModel();
    obj.activeSubstances = this.activeSubstances?.map((activeSubstance) =>
      activeSubstance.mapToApiModel()
    );
    obj.measurementUnit = this.measurementUnit?.mapBaseToApiModel();
    obj.registeredPhytosanitaryProduct =
      this.registeredPhytosanitaryProduct?.mapToApiModel();
    return obj;
  }
}

export default FBPhytosanitaryProduct;
