import VadeFertilizerManufacturer from "../../vademecumFertiliz/VadeFertilizerManufacturer";
import VadeFertilizerProductType from "../../vademecumFertiliz/VadeFertilizerProductType";

class FBRegisteredFertilizerProduct {
  id?: number;
  url?: string;
  registerId?: string;
  name?: string; //*
  type?: VadeFertilizerProductType;
  manufacturer?: VadeFertilizerManufacturer;
  packagingFormat?: string;
  useMethod?: string;
  packaging?: string;
  nitrogenTotalPercentage?: string;
  ammoniacalNitrogenPercentage?: string;
  nitricNitrogenPercentage?: string;
  organicNitrogenPercentage?: string;
  ureicNitrogenPercentage?: string;
  P2O5Percentage?: string;
  waterP2O5Percentage?: string;
  K2OPercentage?: string;
  SO3Percentage?: string;
  Na2OPercentage?: string;
  CaOPercentage?: string;
  MgOPercentage?: string;
  BPercentage?: string;
  CoPercentage?: string;
  CuPercentage?: string;
  FePercentage?: string;
  MnPercentage?: string;
  MbPercentage?: string;
  ZnPercentage?: string;
  minPH?: string;
  maxPH?: string;
  organicMaterialPercentage?: string;
  organicCarbonPercentage?: string;
  humicExtractTotalPercentage?: string;
  humicAcidsTotalPercentage?: string;
  fulvicAcidsTotalPercentage?: string;
  clurideContent?: string;
  maxHumidity?: string;
  electricalConductivity?: string;
  density?: string;
  ph?: string;
  CNRelation?: string;
  solubilityDegreeTotalPercentage?: string;
  polyphenolsPercentage?: string;
  waterSolubleProduct?: boolean;
  cadmium?: string;
  copper?: string;
  nickel?: string;
  lead?: string;
  zinc?: string;
  mercury?: string;
  chromeTotal?: string;
  chromeVI?: string;
  productClassification?: string;
  enabledDate?: string;
  disabledDate?: string;

  constructor(obj?: any) {
    this.id = obj?.id;
    this.url = obj?.url;
    this.registerId = obj?.registerId;
    this.name = obj?.name;
    this.type = new VadeFertilizerProductType(obj?.type);
    this.manufacturer = new VadeFertilizerManufacturer(obj?.manufacturer);
    this.packagingFormat = obj?.packagingFormat;
    this.useMethod = obj?.useMethod;
    this.packaging = obj?.packaging;
    this.nitrogenTotalPercentage = obj?.nitrogenTotalPercentage;
    this.ammoniacalNitrogenPercentage = obj?.ammoniacalNitrogenPercentage;
    this.nitricNitrogenPercentage = obj?.nitricNitrogenPercentage;
    this.organicNitrogenPercentage = obj?.organicNitrogenPercentage;
    this.ureicNitrogenPercentage = obj?.ureicNitrogenPercentage;
    this.P2O5Percentage = obj?.P2O5Percentage;
    this.waterP2O5Percentage = obj?.waterP2O5Percentage;
    this.K2OPercentage = obj?.K2OPercentage;
    this.SO3Percentage = obj?.SO3Percentage;
    this.Na2OPercentage = obj?.Na2OPercentage;
    this.CaOPercentage = obj?.CaOPercentage;
    this.MgOPercentage = obj?.MgOPercentage;
    this.BPercentage = obj?.BPercentage;
    this.CoPercentage = obj?.CoPercentage;
    this.CuPercentage = obj?.CuPercentage;
    this.FePercentage = obj?.FePercentage;
    this.MnPercentage = obj?.MnPercentage;
    this.MbPercentage = obj?.MbPercentage;
    this.ZnPercentage = obj?.ZnPercentage;
    this.minPH = obj?.minPH;
    this.maxPH = obj?.maxPH;
    this.organicMaterialPercentage = obj?.organicMaterialPercentage;
    this.organicCarbonPercentage = obj?.organicCarbonPercentage;
    this.humicExtractTotalPercentage = obj?.humicExtractTotalPercentage;
    this.humicAcidsTotalPercentage = obj?.humicAcidsTotalPercentage;
    this.fulvicAcidsTotalPercentage = obj?.fulvicAcidsTotalPercentage;
    this.clurideContent = obj?.clurideContent;
    this.maxHumidity = obj?.maxHumidity;
    this.electricalConductivity = obj?.electricalConductivity;
  }

  mapBaseToApiModel(): any {
    const obj: any = { ...this };
    obj.type = this.type?.mapToApiModel();
    obj.manufacturer = this.manufacturer?.mapToApiModel();
    return obj;
  }
}

export default FBRegisteredFertilizerProduct;
