import {
  EntitySexInterface,
  EntityTypeInterface,
} from "../../constants/interfaces";
import { ENTITY_SEX_TYPES, ENTITY_TYPES } from "../../providers/YupProvider";
import FBEntity from "../_api/agrobusiness/FBEntity";

class Entity extends FBEntity {
  sexType?: EntitySexInterface;
  entityType?: EntityTypeInterface;
  nameAndSurname?: string;

  constructor(obj?: any) {
    super(obj);
    if (obj?.sex)
      this.sexType = ENTITY_SEX_TYPES.find(
        (sexType) => sexType.code === obj.sex
      );
    this.entityType =
      ENTITY_TYPES.find((entityType) => entityType.code === obj?.type) ||
      ENTITY_TYPES[0];
    this.nameAndSurname = this.getNameAndSurname();
  }

  mapToApiModel(): any {
    const obj: any = super.mapBaseToApiModel();
    obj.sex = this.sexType?.code;
    obj.type = this.entityType?.code;
    // Remove custom variables
    delete obj.sexType;
    delete obj.entityType;
    delete obj.nameAndSurname;
    return obj;
  }

  getNameAndSurname(): string {
    return `${this.name} ${this.firstSurname || ""}`;
  }
}

export default Entity;
